
// doc: https://docs.google.com/spreadsheets/d/1oYUZFBXLkR_4O5WQ-N6jCRdavzE3-AtWZ2H95DdAShk/edit#gid=795223779

export default {
    methods: {
        /**
         *  El siguiente dataLayer va al momento de seleccionar el icono de comparar desde cualquier sitio del Recomendador CAT
         *  
         *  Datalayer que se envia al darle clic al botón comparar de un producto 
         * 
         * @param {String} section - Sección donde se encuentra el usuario
         * @param {String} state - Nombre del estado
         * @param {String} city - Numero de la ciudad
         * @param {Object} product - Producto que se envió al comparador
         */
        dataLaterClickAddToCompare(section, state, city, product) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2: section, // '<<>>',
                nd3:'Agregar el producto al comparador',
                nd4:'',
                estadoNombre: state,  //'<<>>',
                ciudadNombre: city,//'<<>>',
                sku: product.SKU, // '<<SKU del producto>>',
                productoNombre: product.Nombre, // '<<Nombre de producto>>',
                precioNormal: product.Precio_Venta, // '<<Precio del producto sin descuento>>',
                precioDescuento: product.Precio_Promocion == 0 ? product.Precio_Venta : product.Precio_Promocion, // <<Precio del producto con descuento>>', //cuando el producto no cuente con descuento mandar el precio normal del producto
                event: 'recomendacionesCatGeneral'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar el icono de ir al comparador desde cualquier sitio del Recomendador CAT
         * 
         * @param {String} section - Sección donde se encuentra el usuario
         * @param {String} state - Nombre del estad
         * @param {String} city - Numero de la ciudad
         */
        dayaLayerClickComparador(section, state, city) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2: section, // '<<Sección donde se encuentra el usuario>>',
                nd3:'Ir al comparador',
                nd4:'',
                estadoNombre: state, // '<<Nombre del estado>>',
                ciudadNombre: city, // '<<Numero de la ciudad>>',
                event:'recomendadorCatGeneral'
            });
        },

        /**
         * El siguiente dataLayer va al momento de mostrarse la sección de COMPARADOR DE PRODUCTOS
         * 
         * @param {String} state - Nombre del estado
         * @param {String} city - Nombre de la ciudad
         */
        dataLayerLoadComparadorProductos(state, city) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Comparador de productos',
                nd3:'Llegada',
                nd4:'',
                estadoNombre: state, //'<<Nombre del estado>>',
                ciudadNombre: city, // '<<Numero de la ciudad>>',
                event:'recomendadorCatGeneral'
            });
        },

        /**
         * Datalayer que envia los productos que estan en el comparado
         * 
         * @param {String} state 
         * @param {String} city 
         * @param {Array} items - items arrojados por el endpoint
         */
        dataLayerProductsComparador(state, city, items) {
            let lotes  = []
            let loteSize = 12
            // crear lotes de 12 elementos
            for (let i = 0; i < items.length; i += loteSize ) {
                lotes.push(items.slice(i, i + loteSize))
            }
            let itemPosition = 0;
            for (let i = 0; i < lotes.length; i++) {
                //Impresiones de producto
                let impressions = lotes[i].map((item) => {
                    ++itemPosition
                    return {
                        'name': item.Nombre,
                        'id': item.SKU, 
                        'price': item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta, //solo numeros, sin comas ni signos, ni puntos decimales, número entero 
                        'brand': item.caracteristicas && typeof item.caracteristicas === "object" ? item.caracteristicas.Marca : '', //si no lo puedes obtener, que vaya en blanco
                        'category': '', // es el id de la categoría. si no lo puedes obtener, que vaya en blanco
                        'variant': '', //si no lo puedes obtener, que vaya en blanco
                        'position': itemPosition ,//se especifica el número del producto del carrusel
                        'list':'Comparador de productos MO_PRO_BLO-'+(i+1)+'|TRK-'+(i+1)
                    }
                })
                // Mandar lotes de doce elemenotos
                dataLayer.push({
                    ecommerce: {
                        impressions: impressions
                    },
                    nd1:'EEC - Promos - Impresion',
                    nd2:'Comparador de productos',
                    nd3:'MO_PRO_BLO-'+(i+1)+'|TRK-'+(i+1),
                    nd4:'1', //es un valor constante
                    estadoNombre: state,
                    ciudadNombre: city,
                    event:'promosImpresionPack'
                });

            }
        },

        /**
         * El siguiente dataLayer va siempre que en una página existan listas de productos y va al clic a cada producto dentro de la lista de productos. 
         * 
         * @param {Object} product 
         * @param {String} state - Nombre del estado 
         * @param {String} city - Numero de la ciudad
         * 
         */
        dataLayerClickVisitProduct(product, state, city) {
            var numBlock = product.index !== undefined ? product.index + 1 : 1
            numBlock = Math.ceil(numBlock / 12); // calcular en que bloque esta el item. cada 12 elementos es un bloque,  del 1 al 12 es bloque 1, del 13 al 24 bloque dos etc.
            dataLayer.push({
                ecommerce: {
                   click: {
                       actionField: { 'list':'Comparador de productos|MO_PRO_BLO-'+numBlock+'|TRK-'+numBlock}, 
                    },
                    products: [{
                        name: product.Nombre,
                        id: product.SKU,
                        price: product.Precio_Promocion > 0 ? product.Precio_Promocion : product.Precio_Venta,  //cuando el producto cuente con descuento entonces es el precio con descuento
                        brand: product.caracteristicas && typeof product.caracteristicas === "object" ? product.caracteristicas.Marca : '',
                        category: '', //si no lo puedes obtener, que vaya en blanco
                        variant: '', //si no lo puedes obtener, que vaya en blanco
                        position:product.index !== undefined ? product.index + 1 : '' //Se especifica el número del producto del carrusel
                    }]
                },
                nd1:'EEC - Promos - Seleccion - Lista de productos',
                nd2:'Comparador de productos',
                nd3:'MO_PRO_BLO-'+numBlock+'|TRK-'+numBlock,
                nd4: product.index !== undefined ? product.index + 1 : '', //'<<Posición del producto en la lista>>',
                promoTexto: product.Nombre, 
                promoCreativo: product.URL_imagen.split("/").length > 1 ? product.URL_imagen.split("/").reverse()[0] : '', // nombre de la imágen
                estadoNombre: state,
                ciudadNombre: city,
                sku: product.SKU,
                productoNombre: product.Nombre,
                precioNormal: product.Precio_Venta,
                precioDescuento: product.Precio_Promocion > 0 ? product.Precio_Promocion : product.Precio_Venta, //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'promosSeleccionLp'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar el icono de eliminar producto del comparador
         * 
         * @param {String} state - Nombre del estado
         * @param {String} city - Numero de la ciudad
         * @param {Object} product - Producto que se envió al comparador
         */
        dataLayerRemoveProductFromComparador(state, city, product) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Comparador de productos',
                nd3:'Eliminar producto',
                nd4:'',
                estadoNombre: state, //'<<Nombre del estado>>',
                ciudadNombre: city, // '<<Numero de la ciudad>>',
                sku: product.SKU, //'<<SKU del producto>>',
                productoNombre: product.Nombre, // '<<Nombre de producto>>',
                precioNormal: product.Precio_Venta, // '<<Precio del producto sin descuento>>',
                precioDescuento: product.Precio_Promocion == 0 ? product.Precio_Venta : product.Precio_Promocion, // '<<Precio del producto con descuento>>', //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'recomendacionesCatGeneral'
            });
        },

        /**
         * El siguiente dataLayer va al momento de dar clic en el botón de Características|Descripción dentro de un producto
         * 
         * @param {String} action - Botón que recibio el click, valores:  Características | Descripción
         * @param {String} state 
         * @param {String} city 
         * @param {Object} product 
         */
        dataLayerClickCaracDescProduct(action, state, city, product) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Comparador de productos',
                nd3: action, // '<<Características|Descripción>>',
                nd4:'',
                estadoNombre: state, // '<<Nombre del estado>>',
                ciudadNombre: city, // '<<Numero de la ciudad>>',
                sku: product.SKU, // '<<SKU del producto>>', 
                productoNombre: product.Nombre, // '<<Nombre del producto>>',
                precioNormal: product.Precio_Venta, // '<<Precio del producto sin descuento>>',
                precioDescuento: product.Precio_Promocion > 0 ? product.Precio_Promocion : product.Precio_Venta, // '<<Precio del producto con descuento>>', //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'recomendacionesCatGeneral'
            });
        },

        /**
         * El siguiente dataLayer va al momento de dar clic en el botón de Regresar
         * 
         * @param {String} state - Nombre del estado
         * @param {String} city - Numero de la ciudad
         */
        dataLayerClickRegresarAComparador(state, city) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Comparador de productos',
                nd3:'Regresar',
                nd4:'',
                estadoNombre: state, // '<<Nombre del estado>>',
                ciudadNombre: city, //'<<Numero de la ciudad>>',
                event:'recomendadorCatGeneral'
            });
        }
	}
}