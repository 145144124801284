<template  lang="pug">
    v-row(class="seleccionador")
        v-col(cols="12")
            h1( class="text-center text-h6 blue-500--text") Seleccionador: Motocicletas
        v-col(cols="12")
            div(class="d-flex justify-space-between")
                v-btn(color="default" outlined x-small class="mr-5" @click="$emit('onClickReturn')")
                    v-icon(dark)  mdi-chevron-left
                    | Regresar a seleccionadores
                //- template(v-if="isComparadorVisible")
                //-     v-btn(x-small @click="switchComparador" color="primary") Regresar a la lista de productos
                //- template(v-else)
                //-     v-tooltip(top)
                //-         template(v-slot:activator="{ on, attrs }")
                //-             v-btn(x-small @click.prevent="switchComparador" color="primary" v-bind="attrs" v-on="on") Ir a comparar productos seleccionados
                //-         span Solo es posible comparar hasta 3 artículos
        template(v-if="!isComparadorVisible")
            v-col(cols="12" md="3")
                v-card(elevation="0" outlined)
                    v-card-text
                        div(class="text-left")
                            p( class="text-title font-weight-bold blue-500--text") Características del producto
                        div(class="d-flex justify-space-between mb-6")
                            v-btn(@click="handleCleanForm" x-small color="primary") Nueva busqueda
                        p(class="mb-2 mt-4 text-body-2") ¿Qué estilo de motocicleta buscas?*
                        v-expansion-panels(multiple v-model='panel')
                            v-expansion-panel  
                                v-expansion-panel-header(class="")
                                    div(class="d-flex justify-space-between align-center")
                                        span Tipo de estilo
                                        v-tooltip( top class="d-inline" style="opacity:1;" color="rgba(0, 0, 0, 1)")
                                            template(v-slot:activator='{ on, attrs }')
                                                v-btn(icon color='primary' v-bind='attrs' v-on='on')
                                                    v-icon mdi-help-circle
                                            div( class="text-caption text-justify") 
                                                v-img(width="500px" src="@/assets/img/estilo-moto.jpg")
                                                <br/>
                                                p Motocarro - Vehículo de carga. <br/> Cuatrimoto - Cualquier terreno excepto pavimento. 
                                v-expansion-panel-content
                                    v-radio-group(v-model="form.des_Familia" @change="handleChangeDesFamilia")
                                        v-radio(
                                            v-for="item in des_Familia"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                            class="mt-0 mb-0 pt-0 pb-0"
                                            :disabled="disableInput")
                                        
            v-col(cols="12" md="9")
                v-row(class="align-center")
                    v-col( offset="6" sm="6" class="pt-0")
                        v-card(outlined elevation="0")
                            v-card-text(class="pt-0 pb-0 d-flex align-center")
                                v-row()
                                    v-col(class="pt-0") 
                                        p(class="mt-0 mb-0 text-captions") Precio máximo ${{price_format(form.precioMaximo)}}
                                        p(class="mt-0 mb-0 text-caption") ${{price_format(form.precioMaximo)}}
                                        v-slider(
                                            min="0" 
                                            :max="precioMaximo" 
                                            hint="precio" 
                                            hide-details 
                                            v-model="form.precioMaximo" 
                                            :loading="productsListLoading" 
                                            :disabled="productsListLoading"
                                            @mousedown="handleMousedownPrecio"
                                            @change="handleChangePrecio")
                                    v-col
                                        v-select(
                                            label="Ordenar por:"
                                            :disabled="productsList.length == 0"
                                            v-model="form.orden"
                                            :items="orderByItems"
                                            @click="handleClickOrderBy"
                                            @change="handleChangeOrderBy"
                                            clearable
                                        )

                v-card(outlined elevation="0")
                    v-card-text 
                        div(class="d-flex justify-center" v-if="productsListLoading")
                                v-progress-circular(indeterminate color="primary")
                        template( v-else-if="!productsListLoading && productsList.length > 0")
                            div(class="d-flex flex-wrap")
                                CardItem( 
                                    v-for="item, index in itemsTransformedList" 
                                    :item="item" 
                                    :key="item.SKU+''+index" 
                                    @clickSKU="handleClickSKU"
                                    enableShortenLink
                                    @clickAction="(data) => handleClickActionCardItem('Seleccionador: Motocicletas', data)"
                                    activateComparator
                                    class="d-inline-block")
                            //- div(class="mt-8") 
                            //-     v-pagination(v-model="pageNumber" :length="paginationLength" @input="handleInputPaginator" :total-visible="10")
                        p(class="text-center" v-else) Lo sentimos, tu búsqueda no arrojó resultados, <br> puedes modificar la disponibilidad por estado o ciudad o probar con otros filtros
        v-col(sm="12")
            TablaComparativaDeProductos(
                class="w-100"
                v-if="isComparadorVisible" 
                :items="productsComparadorList"
                :state="state"
                :city="city"
                @onRemoveItem="removeProductFromCompareList"
            )
        //- 
        v-snackbar(v-model="snackbar" :timeout="timeout") {{snackbarText}}
            template(v-slot:action="{ attrs }")
                v-btn(
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                ) Cerrar

        v-btn( color="primary" small dark fixed bottom right v-if="isComparadorVisible" @click="handleClickRegresarAComparador") <v-icon size="15" class="mr-1">mdi-keyboard-return</v-icon> Regresar
        v-btn( color="purple" small dark fixed bottom right v-else @click.prevent="handleClickComparador") <v-icon size="15" class="mr-1">mdi-select-compare</v-icon> comparador

        //- dialog acortador links
        v-dialog(
            v-model="dialogFlag"
            max-width="300"
        )
            v-card
                v-card-title(class="text-h6") Copia el link
                v-card-text {{ dialogText }}
                v-card-actions
                    v-spacer
                    v-btn(text @click="dialogFlag = false") Cerrar

</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import CardItem from './CardItem';
import TablaComparativaDeProductos from './TablaComparativaDeProductos';
import DataLayersSeleccionadoresMixin from '@/mixins/DataLayersSeleccionadoresMixin'
import DataLayersComparadorMixin from '@/mixins/DataLayersComparadorMixin'

export default {
    name: 'SeleccionadorProductosMotocicletas',
    props: ['city', 'state'],
    mixins: [DataLayersSeleccionadoresMixin, DataLayersComparadorMixin],
    components: {
        CardItem,
        TablaComparativaDeProductos
    },
    data() {
        return {
            panel: [0],
            orderByItems: [
                {text:"menor precio", value:"asc"},
                {text:"mayor precio", value:"des"},
            ],
            isComparadorVisible: false,
            snackbarText: '',
            snackbar: false,
            timeout: 2000,
            // dialog
            dialogFlag: false,
            // dialogTitle: '',
            dialogText: '',
            precioMaximoPrevio: 0,
            delayTimerPrice: null,
            ordenarPorPrev: ''
        }
    },
    watch: {
        city(value) {
            this.form.ciudad = value
            if(this.form.estado && this.form.ciudad) {
                // refrescar inputs
                this.fetchSeleccionadorInputs()
                // enviar formulario
                this.sendForm()
            }
        },
        state(value) {
            this.form.estado = value
            if(this.form.estado && this.form.ciudad) {
                // refrescar inputs
                this.fetchSeleccionadorInputs()
                // enviar formulario
                this.sendForm()
            }
        }
    },
    computed: {
        ...mapState({
            form: state => state.seleccionadorMotocicletas.form,
            des_Familia: state => state.seleccionadorMotocicletas.des_Familia,
            inputsDataLoading: state => state.seleccionadorMotocicletas.inputsDataLoading,
            precioMaximo: state => state.seleccionadorMotocicletas.precioMaximo,
            productsList: state => state.seleccionadorMotocicletas.productsList,
            productsListLoading: state => state.seleccionadorMotocicletas.productsListLoading,
            productsComparadorList: state => state.seleccionadorMotocicletas.productsComparadorList,
        }),
        ...mapGetters('session', [
            'userNoEmpleado',
            'userTipoVenta',
        ]),
        itemsTransformedList() {
            return this.productsList.map(this.transform_data) || [];
        },
        disableInput() {
            return this.productsListLoading || this.inputsDataLoading || !this.city || !this.state
        },
        loading() {
            return this.productsListLoading || this.inputsDataLoading
        },
    },
    methods: {
        ...mapActions('seleccionadorMotocicletas', [
            'fetchSeleccionadorInputs',
            'submitForm',
            'removeProductFromCompareList',
            'addIProductToCompareList',
            'cleanCompareList',
            'cleanForm',
            'resetSeleccionador',
            'shortenLink'
        ]),
        handleClickComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
            // datalayer
            this.dayaLayerClickComparador("Seleccionador Motocicletas", this.state, this.city)
        },
        handleClickRegresarAComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
            //datalayer
            this.dataLayerClickRegresarAComparador(this.state, this.city)
        },
        appendUTM(link) {
            if (this.userTipoVenta == null || this.userTipoVenta == '' || this.userNoEmpleado == null || this.userNoEmpleado == ''  ) {
                return link
            }
            let utm_source = 'cat';
            let utm_medium = 'vt';
            if (this.userTipoVenta == 'ASISTIDA') {
                utm_source = 'ven-asist-wpp';
                utm_medium = 'referido';
            }
            return `${link}?utm_campaign=interfaz-cat-ad&utm_medium=${utm_medium}&utm_source=${utm_source}&utm_content=${this.userNoEmpleado}`
        },
        transform_data(item, index) {
            // fecha
            let fec_FechaFinal = item.fec_FechaFinal != 'NaT' && item.fec_FechaFinal != null ? item.fec_FechaFinal.split('T')[0] : null;
            if (fec_FechaFinal) {
                fec_FechaFinal = fec_FechaFinal.split('-')
                if(Array.isArray(fec_FechaFinal) && fec_FechaFinal.length == 3) {
                    fec_FechaFinal = fec_FechaFinal[2] +'/' + fec_FechaFinal[1] + '/' + fec_FechaFinal[0];
                }   
            }
            // leyenda precio
            var price_text = '';
            if(item.price_coppel && typeof item.price_coppel == 'object') {
                price_text = `Cómpralo con crédito Coppel desde $${this.price_format(item.price_coppel.pago_quincenal)} quincenal ($${this.price_format(item.price_coppel.precio_contado)} en ${this.price_format(item.price_coppel.qty_quincenas)} quincenas*)`
            } else {
                let price_coppel = item.price_coppel && item.price_coppel != '' && item.price_coppel !== null ? item.price_coppel.split('/') : [];
                if(price_coppel.length == 3) {
                    price_text = `Cómpralo con crédito Coppel desde $${this.price_format(price_coppel[0])} quincenal ($${this.price_format(price_coppel[1])} en ${this.price_format(price_coppel[2])} quincenas*)`
                }
            }
            
            let Precio_Promocion_format = this.price_format(item.Precio_Promocion);
            let Precio_Venta_format = this.price_format(item.Precio_Venta);
            let descuento = item.Precio_Promocion == 0 || item.Precio_Venta == 0 ? 0 : Math.floor((1 - item.Precio_Promocion / item.Precio_Venta) * 100);
            let sku = item.SKU.toString();
            let SKU_RAW = sku.substr(0,sku.length -1 )
            let MP_flag = item.MP_flag && item.MP_flag == 1 ? 1 : 0;
            // link
            item.URL_producto = this.appendUTM(item.URL_producto)
            // salida
            return {...item, fec_FechaFinal, price_text, Precio_Venta_format, Precio_Promocion_format, descuento, SKU_RAW, MP_flag, index};
        },
        price_format(numb) {
            var str = numb.toString().split(".");
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");
        },
        sendForm() {
            this.submitForm(() =>{
                // send datalayer
                this.dataLayerSeleccionadorItems('Motocicletas', this.state, this.city, this.productsList)
                if (this.productsList.length == 0) {
                    let orden = this.form.orden == 'Ninguno' ? '' : this.form.orden;
                    if (orden == 'asc') orden = 'menor precio';
                    if (orden == 'des') orden = 'mayor precio';
                    let filtersToString = this.getFiltersToString();
                    this.dataLayerSeleccionadorNoItems('Motocicletas', this.state, this.city, filtersToString, this.form.precioMaximo, orden)
                }
            });
        },
        handleClickOrderBy() {
            // datalayer
            let orden = this.form.orden == 'Ninguno' ? '' : this.form.orden;
            if (orden == 'asc') orden = 'menor precio';
            if (orden == 'des') orden = 'mayor precio';
            let filtersToString = this.getFiltersToString();
            this.dataLayerClickOrdenarPor('Motocicletas', this.state, this.city, filtersToString, this.form.precioMaximo, orden)
            this.ordenarPorPrev = orden
        },
        handleChangeOrderBy() {
            if(this.form.orden == undefined) {
                this.form.orden = 'Ninguno'
            }
            //enviar formulario
            this.sendForm()
            // datalayer
            let orden = this.form.orden;
            if (orden == 'Ninguno') orden = '';
            if (orden == 'asc') orden = 'menor precio';
            if (orden == 'des') orden = 'mayor precio';
            let filtersToString = this.getFiltersToString();
            // this.sendDataLayerChangeFilter('Ordenar Por', orden)
            this.dataLayerChangeOrdenarPor('Motocicletas', this.state, this.city, orden, this.ordenarPorPrev, this.form.precioMaximo, filtersToString);
        },
        handleCompararItem(item) {
            if(item && item.isSelectedForComparison) {
                this.removeProductFromCompareList(item)
            } else {
                this.addIProductToCompareList(item)
            }
        },
        handleShortenLink(item) {
            const url = item.URL_producto
            const payload = {
                data: {
                    target: url
                },
                item: item,
                callback: (link) => {
                    // this.snackbarText = `Link acortado copiado en el porta papeles`
                    // this.snackbar = true;
                    // navigator.clipboard.writeText(link);
                    // dialog
                    this.dialogFlag = true;
                    this.dialogText = link
                }
            }
            this.dialogText = ''
            this.shortenLink(payload)
        },
        handleClickActionCardItem(model, data) {
            const {action, item} = data
            switch(action) {
                case 'CLICK_BTN_LINK':
                    this.dataLayerSeleccionadorClickProduct('Motocicletas', item, this.state, this.city);
                    break;
                case 'CLICK_BTN_SHORTEND_LINK':
                    this.handleShortenLink(item)
                    this.dataLayerClickBtnsItemCard('Motocicletas', 'Copiar enlace', item.SKU, item.Nombre) 
                    break;
                case 'CLICK_BTN_COMPARAR':
                    this.handleCompararItem(item)
                    this.dataLaterClickAddToCompare('Seleccionador Motocicletas', this.state, this.city, item)
                    break;
                case 'CLICK_BTN_DESCRIPCION':
                    this.dataLayerClickBtnsItemCard('Motocicletas', 'Descripción', item.SKU, item.Nombre)
                    break;
                case 'CLICK_BTN_CARACTERISTICAS':
                    this.dataLayerClickBtnsItemCard('Motocicletas', 'Características', item.SKU, item.Nombre)
                    break;
                default:
            }
        },
        handleClickSKU({sku, btnName, item}) {
            this.snackbarText = `SKU ${sku} Copiado en el portapapeles`
            navigator.clipboard.writeText(sku);
            this.snackbar = true;
            // Datalayer
            this.dataLayerClickCardSKUSeleccionadores('Seleccionadores de productos', btnName, item, this.state, this.city)
        },
        switchComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
        },
        handleMousedownPrecio() {
            this.precioMaximoPrevio = this.form.precioMaximo
        },
        handleChangePrecio() {
            // Cancelar el temporizador si ya existe
            clearTimeout(this.delayTimerPrice);
            // Configurar un nuevo temporizador para ejecutar la función después de un cierto tiempo
            this.delayTimerPrice = setTimeout(() => {
                this.fetchSeleccionadorInputs()
                //enviar formulario<
                this.sendForm()
                // datalayer
                this.sendDataLayerChangePrice();
            }, 1000);
        },

        handleCleanForm() {
            // datalayer
            let filtersToString = this.getFiltersToString();
            let orden = this.form.orden;
            if (orden == 'Ninguno') orden = '';
            if (orden == 'asc') orden = 'menor precio';
            if (orden == 'des') orden = 'mayor precio';
            this.dataLayerSeleccionadorClickNuevaBusqueda('Motocicletas', this.state, this.city, filtersToString, this.form.precioMaximo, orden); // envíar datalater
            //
            this.cleanForm() ;
            this.cleanCompareList()
            this.fetchSeleccionadorInputs();
            this.sendForm();
        },
        handleChangeDesFamilia() {
            //enviar formulario
            this.sendForm();
            // datalayers
            this.handleClickInput('Tipo de estilo');
            this.sendDataLayerChangeFilter('Tipo de estilo', this.form.des_Familia)   
        },
        // datalayers methods

        /**
         *  Retorna string con filtro en formato clave:valor;...clave:valor
         */
         getFiltersToString() {
            let filtersStr = ''
            if (this.form.des_Familia && this.form.des_Familia != '') {
                filtersStr = 'Tipo de estilo:'+this.form.des_Familia
            }
            return filtersStr == '' ? 'NA' : filtersStr;
        },
        handleClickInput(filterName) {
            let filtersStr = this.getFiltersToString()
            let order = this.form.orden == 'Ninguno' ? '' : this.form.orden
            if (order == 'asc') order = 'menor precio';
            if (order == 'des') order = 'mayor precio';
            this.dataLayerSeleccionadorClickFilter('Motocicletas', filterName, this.state, this.city, this.productsList.length, filtersStr, this.form.precioMaximo, order)
        },
        sendDataLayerChangeFilter(filterKey, filterValue) {
            let filtersToString = this.getFiltersToString()
            let filter = filterKey + '|' + (filterValue || 'NA') 
            let orden = this.form.orden;
            if (orden == 'Ninguno') orden = '';
            if (orden == 'asc') orden = 'menor precio';
            if (orden == 'des') orden = 'mayor precio';
            this.dataLayerSeleccionadorChangeFilter('Motocicletas', filter, this.state, this.city, filtersToString, this.form.precioMaximo, orden)
        },
        sendDataLayerChangePrice() {
            // this.sendDataLayerChangeFilter('Precio máximo', this.form.precioMaximo)>
            let filtersToString = this.getFiltersToString();
            let orden = this.form.orden == 'Ninguno' ? '' : this.form.orden
            if (orden == 'asc') orden = 'menor precio';
            if (orden == 'des') orden = 'mayor precio';
            this.dataLayerChangePrecioMaximo('Motocicletas', this.state, this.city, this.form.precioMaximo, this.precioMaximoPrevio, filtersToString, orden)
        }
    },
    mounted() {
        this.form.estado = this.state
        this.form.ciudad = this.city
        this.cleanForm();
        this.cleanCompareList();
        this.fetchSeleccionadorInputs();
        this.sendForm();
        setTimeout(()=>{
            let filtersStr = this.getFiltersToString()
            let order = this.form.orden == 'Ninguno' ? '' : this.form.orden
            if (order == 'asc') order = 'menor precio';
            if (order == 'des') order = 'mayor precio';
            if(this.productsList.length > 0) {
                this.dataLayerLoadedSeleccionador('Motocicletas', this.state, this.city, this.productsList.length, filtersStr, this.form.precioMaximo, order)
            }
        }, 2000);
    },
    beforeDestroy() {
        this.resetSeleccionador();
    }
}
</script>
