// import {mapGetters} from 'vuex'
const environment = false;
export default {
    methods: {

        // Login

        /**
         * El siguiente dataLayer va en al llegar a la página de Inicio de Sesión 
         */
        dataLayerLoadedPageLogin() {
            if(environment) return;
            dataLayer.push({
                pagina: {
                    ruta:'/inicio-sesion', //Definido por modelo de medición
                    titulo:'Inicio de sesión' //definido por modelo de medición
                },
                // geo: {
                //    widget: {
                //       cd:'No disponible', //obtenido del componente del sitio, <<ciudad que tiene seleccionada el usuario>>
                //       edo:'No disponible' //obtenido del componente del sitio, <<estado que tiene seleccionado el usuario>>
                //     },
                //     webapi: { 
                //         ubi:'No disponible', //Se extrae de la WEB API de geo, <<Latitud>>,<<Longitud>>
                //         act:'No disponible' //configuración del browser <<Si|No>>
                //     }
                // },
                usuario:{
                    uid:this.$store.state.session.user.noEmpleado || 'no identificado', //para los clientes de contado es 90001 y para colaborades es el número de colaborador
                    // ced:'No disponible', // <<Edad del usuario>>
                    // cg:'No disponible' //m para masculino, f para femenino, ne para no especificado y o para otro, <<Genero del cliente>>
                },
               event:'pvGeneral'               
            });

            // dudas aqui
            // El siguiente dataLayer va al momento de llegar a la página de Inicio de sesión
            dataLayer.push({
                nd1:'Inicio de sesión',
                nd2:'Llegada',
                nd3:'',
                nd4:'',
                event:'inicioSesion'
            });
        },

        /**
         * Los siguiente dataLayer va al momento de dar clic en la opción de ¿olvidaste tu contraseña?
         */
        dataLayerClickBtnOlvidasteContrasena() {
            dataLayer.push({
                nd1:'Inicio de sesión',
                nd2:'¿Olvidaste tu contraseña?',
                nd3:'',
                nd4:'',
                event:'inicioSesion'
            });
        },

        /**
         * Los siguiente dataLayer va al momento de dar clic en la opción de ¿olvidaste tu contraseña?
         */
        dataLayerClickBtnIniciarSesion() {
            dataLayer.push({
                nd1:'Inicio de sesión',
                nd2:'Iniciar sesión',
                nd3:'', 
                nd4:'',
                event:'inicioSesion'
            });
        },

        /**
         * El siguiente dataLayer va al momento de la acción del LOGUEO CON ÉXITO 
         * Dudas campo userId
         */
        dataLayerEventInicioSesionExito() {
            setTimeout(()=> {
                dataLayer.push({
                    nd1:'Inicio de sesión',
                    nd2:'Logueo con éxito',
                    nd3:'',
                    nd4:'',
                    userId:'No disponible', //ID que se genera en la maestra de correos
                    clienteNumero: this.$store.state.session.user.noEmpleado || 'no identificado', //para empleado es el número de empleado 
                    logueadoUsuario:'1', //indica si el usuario está logueado o no
                    event:'iniciarSesionExito'
                });
            },2500)
        },

        /**
         * El siguiente dataLayer va al momento que aparece la página de Ingresa tu correo para buscar tu cuenta
         * 
         */
        dataLayerLoadedPageRecuperarContra() {
            if(environment) return;
            dataLayer.push({
                pagina: {
                    ruta:'/recuperacion-contrasena', //Definido por modelo de medición
                    titulo:'Recuperación de contraseña' //definido por modelo de medición
                },
                // geo: {
                //    widget: {
                //       cd:'No disponible', //obtenido del componente del sitio, <<ciudad que tiene seleccionada el usuario>>
                //       edo:'No disponible' //obtenido del componente del sitio, <<estado que tiene seleccionado el usuario>>
                //     },
                //     webapi: { 
                //         ubi:'No disponible', //Se extrae de la WEB API de geo. <<Latitud>>,<<Longitud>>
                //         act:'No disponible' //configuración del browser, <<Si|No>>
                //     }
                // },
                usuario:{
                    uid:this.$store.state.session.user.noEmpleado || 'no identificado', //para los clientes de contado es 90001 y para colaborades es el número de colaborador
                    // ced:'No disponible', // <<Edad del usuario>>
                    // cg:'No disponible' //m para masculino, f para femenino, ne para no especificado y o para otro, <<Genero del cliente>>
                },
               event:'pvGeneral'               
            });

            // duda, no se si tiene que ir aquí
            // El siguiente dataLayer va al momento de llegar a la página de Ingresa tu correo para buscar tu cuenta
            dataLayer.push({
                nd1:'Recuperación de contraseña',
                nd2:'Llegada',
                nd3:'',
                nd4:'',
                event:'recuperacionContra'
            });
        },

        /**
         * El siguiente dataLayer va al momento de llegar a la página de Ingresa tu correo para buscar tu cuenta
         * 
         * @param {string} action Valores que puede tomar Regresar | Enviar
         */
        dataLayerClickBtnsRecuperarContrasena(action) {
            dataLayer.push({
                nd1:'Recuperación de contraseña',
                nd2: action,
                nd3:'',
                nd4:'',
                event:'recuperacionContra'
            });
        },


        // Panel

        /**
         * Evento al datalayer cuando carga la pagina /panel
         * Duda
         */
		dataLayerLoadedPagePanel() {
            if(environment) return;

            setTimeout(() => {
                //El siguiente dataLayer va al momento de llegar a la página de Panel interfaz cat
                dataLayer.push({
                    pagina: {
                        ruta:'/panel', //Definido por modelo de medición
                        titulo:'Panel' //definido por modelo de medición
                    },
                    // geo: {
                    //     widget :{
                    //         cd:'No disponible.', //obtenido del componente del sitio
                    //         edo:'No disponible.' //obtenido del componente del sitio
                    //     },
                    //     webapi: { 
                    //         ubi:'No disponible.', //Se extrae de la WEB API de geo <<Latitud>>,<<Longitud>>
                    //         act:'No disponible.' //configuración del browser <<Si|No>>
                    //     }
                    // },
                    usuario: {
                        uid: this.$store.state.session.user.noEmpleado || 'no identificado', //para los clientes de contado es 90001 y para colaborades es el número de colaborador
                        // ced:'No disponibles.', // <<Edad del usuario>>
                        // cg:'ne' //m para masculino, f para femenino, ne para no especificado y o para otro <<Genero del cliente>>
                    },
                event:'pvGeneral'               
                });

                // El siguiente dataLayer va al momento de llegar a la página de Panel Recomendaciones CAT
                dataLayer.push({
                    nd1:'Panel',
                    nd2:'Llegada',
                    nd3:'',
                    nd4:'',
                    event:'panel'
                });
            }, 2500)
        },

        /**
         * El siguiente dataLayer va al momento de llegar a la página de Panel Recomendador CAT
         * Duda, es parecido al otro datalayer
         */
        dataLayerClickBtnRecomendadorCAT() {
            if(environment) return;
            dataLayer.push({ nd1:'Panel', nd2:'Recomendador CAT', nd3:'', nd4:'', event:'panel'});
        },

        dataLayerClickBtnPanel() {
            if(environment) return;
            dataLayer.push({nd1:'Panel', nd2:'Panel', nd3:'', nd4:'', event:'panel'});
        },

        /**
         * Cerrar sesion desde Panel
         */
        dataLayerClickBtnCerrarSession() {
            if(environment) return;
            dataLayer.push({nd1:'Panel',nd2:'Cerrar Sesión',nd3:'',nd4:'',event:'panel'});
        },

        /**
         * 
         * El siguiente dataLayer va al momento de dar clic en el boton buzon de mensajes
         */
        dataLayerClickBtnBuzonMensajes() {
            if(environment) return;
            dataLayer.push({nd1:'Panel',nd2:'Buzón de mensajes',nd3:'',nd4:'',event:'panel'});
        },


        // Recomendador CAT

        // Recomendaciones por artículo

        /**
         * El siguiente dataLayer va al momento de llegar a la página de Recomendador CAT
         * 
         */
        dataLayerLoadedPageRecomendadorCAT() {
            if(environment) return;
            setTimeout(()=> {
                dataLayer.push({
                    pagina: {
                        ruta:'/recomendador-cat', //Definido por modelo de medición
                        titulo:'Recomendador CAT' //definido por modelo de medición
                    },
                    // geo: {
                    //    widget: {
                    //       cd:'No disponible', //obtenido del componente del sitio, <<ciudad que tiene seleccionada el usuario>>
                    //       edo:'No disponible' //obtenido del componente del sitio, <<estado que tiene seleccionado el usuario>>
                    //     },
                    //     webapi: { 
                    //         ubi:'No disponible', //Se extrae de la WEB API de geo, <<Latitud>>,<<Longitud>>
                    //         act:'No disponible' //configuración del browser, <<Si|No>>
                    //     }
                    // },
                    usuario: {
                        uid: this.$store.state.session.user.noEmpleado || 'no identificado', //para los clientes de contado es 90001 y para colaborades es el número de colaborador
                        // ced:'No disponible', // <<Edad del usuario>>
                        // cg:'No disponible' //m para masculino, f para femenino, ne para no especificado y o para otro, <<Genero del cliente>>
                    },
                   event:'pvGeneral'               
                });
            }, 2000);
        },

        /**
         * El siguiente dataLayer va al momento de llegar a la página de Recomendador CAT
         * 
         * Al abrir la pestaña Recomendaciones por artículo
         * @param {String} state 
         * @param {String} city 
         */
        dataLayerLoadedRecomendacionesPorArticulo(state, city) {
            setTimeout(()=> {
                // El siguiente dataLayer va al momento de llegar a la página de Recomendador CAT
                dataLayer.push({
                    nd1:'Recomendador CAT',
                    nd2:'Recomendaciones por artículo',
                    nd3:'Llegada',
                    nd4:'',
                    estadoNombre: state,
                    ciudadNombre: city,
                    event:'recomendadorCatGeneral'
                });
            }, 2000);
        },

        /**
         * Datalayer cerrar sesion desde recomendador CAT
         * El siguiente dataLayer va al momento de dar clic en la opción de Panel|Cerrar Sesión
         * 
         */
        dataLayerClickCerrarSessionFromRecCAT() {
            if(environment) return;
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Menú superior',
                nd3:'Cerrar Sesión',
                nd4:'',
                event:'recomendadorCatGeneral'
            });
        },

        /**
         * Datalayer click dashboard desde recomendador CAT
         * El siguiente dataLayer va al momento de dar clic en la opción de Panel|Cerrar Sesión
         * 
         */
        dataLayerClickBtnPanelFromRecCAT() {
            if(environment) return;
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Menú superior',
                nd3:'Panel',
                nd4:'',
                event:'recomendadorCatGeneral'
            });
        },

        /**
         * Datalayer click pestañas Recomendaciones CAT
         * El siguiente dataLayer va al momento de dar clic en alguna opción de los módulos 
         * 
         * @param {String} tab 
         * @param {String} city 
         * @param {String} state 
         * 
         */
        dataLayerClickTabRecCAT(tab, city, state) {
            if(environment) return;
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2: tab, // '<<Módulo seleccionado>>' 
                nd3:'',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                event:'recomendadorCatGeneral'
            });
        },


        // Recomendaciones CAT -> Recomendaciones por artículo


        /**
         * Datalayer click pestañas Recomendaciones por artículo
         * El siguiente dataLayer va al momento de dar clic en el botón de Buscar
         */
        dataLayerBtnBusquedaRecPorArticulo(estado, ciudad, sku, producto) {
            if(environment) return;
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Recomendaciones por artículo',
                nd3:'',
                nd4:'',
                estadoNombre: estado, // <<Nombre del estado>>
                ciudadNombre:ciudad, // <<Numero de la ciudad>>
                sku: sku, //sku que ingreso el asesor de cat para buscar, '<<SKU del producto>>'
                productoNombre: producto, // '<<Nombre del producto>>'
                event:'recomendadorCatBuscar'
            });
        },

        /**
         * Envia a datalayer los items de los carruseles arrojados por los modelos dentro de recomendaciones por artículos
         * 
         * @param {string} model Nombre del carrusel 
         * @param {integer} order Orden en del carrusel dentro de Recomendaciones por articulo
         * @param {array} items Array de recomendaciones del modelo 
         *
         * 
        */
        dataLayerItemsRecPorArticuloByModel(model, order, items) {
            if(environment) return;
            let lotes  = []
            let loteSize = 12
            // crear lotes de 12 elementos
            for (let i = 0; i < items.length; i += loteSize ) {
                lotes.push(items.slice(i, i + loteSize))
            }
            
            let itemPosition = 0;
            for (let i = 0; i < lotes.length; i++) {
                //Impresiones de producto
                let impressions = lotes[i].map((item) => {
                    ++itemPosition
                    return {
                        'name': item.Nombre,
                        'id': item.SKU, 
                        'price': item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta, //solo numeros, sin comas ni signos, ni puntos decimales, número entero 
                        'brand': item.caracteristicas && typeof item.caracteristicas === "object" ? item.caracteristicas.Marca : '', //si no lo puedes obtener, que vaya en blanco
                        'category': '', // es el id de la categoría. si no lo puedes obtener, que vaya en blanco
                        'variant': '', //si no lo puedes obtener, que vaya en blanco
                        'position': itemPosition, //se especifica el número del producto del carrusel
                        'list':`Recomendaciones por artículo|MO_PRO_BLO-${order}|TRK-${order}|${model}`
                    }
                })
                // Mandar lotes de doce elemenotos
                dataLayer.push({
                    ecommerce: {
                    impressions: impressions
                    },
                    nd1:'EEC - Promos - Impresion',
                    nd2: 'Recomendaciones por artículo', // 'Recomendaciones por artículo'
                    nd3: `MO_PRO_BLO-${order}|TRK-${order}|${model}` , // MO_PRO_BLO-0|TRK-0|<<Nombre de la sección que muestra productos>>
                    nd4:'1', //es un valor constante
                    event:'promosImpresionPack'
                }); 

            }
            
        },

        /**
         * Datalayer clic item de modelo
         * El siguiente dataLayer va siempre que en una página existan listas de productos y va al clic a cada producto dentro de la lista de productos. 
         * 
         * @param {String} model Nombre del carrusel 
         * @param {Integer} order Orden en del carrusel dentro de Recomendaciones por articulo
         * @param {Array} item prodicto dentro de la lista de recomendaciones
         * @param {String} state 
         * @param {String} city 
         * 
         */
        dataLayerItemRecPorArticuloByModel(model, order, item, state, city) {
            if(environment) return;
            dataLayer.push({
                ecommerce: {
                   click: {
                       'actionField': {'list':`Recomendaciones por artículo|MO_PRO_BLO-${order}|TRK-${order}|${model}`}, 
                      },
                    products: [{
                        'name': item.Nombre,
                        'id': item.SKU,
                        'price': item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta,  //cuando el producto cuente con descuento entonces es el precio con descuento
                        'brand': item.caracteristicas && typeof item.caracteristicas === "object" ? item.caracteristicas.Marca : '',
                        'category': '', //si no lo puedes obtener, que vaya en blanco
                        'variant': '', //si no lo puedes obtener, que vaya en blanco
                        'position': item.index !== undefined ? item.index + 1 : ''//Se especifica el número del producto del carrusel
                    }]
                },
                nd1:'EEC - Promos - Seleccion - Lista de productos',
                nd2: 'Recomendaciones por artículo',
                nd3: `MO_PRO_BLO-${order}|TRK-${order}|${model}`,
                nd4: item.index !== undefined ? item.index + 1 : '', //
                promoTexto: item.Nombre, 
                promoCreativo: item.URL_imagen.split("/").length > 1 ? item.URL_imagen.split("/").reverse()[0] : '', // nombre de la imágen
                estadoNombre: state,
                ciudadNombre: city,
                sku: item.SKU,
                productoNombre: item.Nombre,
                precioNormal: item.Precio_Venta,
                precioDescuento: item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta, //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'promosSeleccionLp'
            });
        },

        /**
         *  
         * @param {String} btnName - Nombre del botón cliqueado
         * @param {Object} product - Datos del producto
         * @param {String} state - estado
         * @param {String} city - ciudad
         * 
         * El siguiente dataLayer va al momento de dar clic en el botón de Características|Descripción dentro de un item de producto
         * en la seccion recomendaciones por artículo 
         */
        dataLayerBtnCaracDescRecoPorArticulo(btnName, product, state, city) {
            if(environment) return;
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Recomendaciones por artículo',
                nd3: btnName, // <<Características|Descripción|Copiar>>
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                sku: product.SKU, // <<SKU del producto>>',
                productoNombre: product.Nombre, // <<Nombre del producto>>
                precioNormal: product.Precio_Venta,
                precioDescuento: product.Precio_Promocion > 0 ? product.Precio_Promocion : product.Precio_Venta, //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'recomendacionesCatGeneral'
            });
        },

        /**
         * El siguiente dataLayer va al momento de dar clic en el botón de Copiar Código o Copiar SKU en Recomendaciones por artículo
         * 
         * @param {String} btnName - <<Copiar código|Cópiar SKU>>
         * @param {Object} product - Nombre del estado
         * @param {String} state - Numero del estado
         * @param {String} city - Numero de la ciudad
         * 
         * reemplazado por dataLayerClickCardSKU
         */
        // dataLayerClickSKURecoPorArticulo(btnName, product, state, city) {
        //     dataLayer.push({
        //         nd1:'Recomendador CAT',
        //         nd2:'Recomendaciones por artículo',
        //         nd3: 'Copiar ' + btnName,
        //         nd4:'',
        //         estadoNombre: state,
        //         ciudadNombre: city,
        //         sku: product.SKU, 
        //         productoNombre: product.Nombre,
        //         precioNormal: product.Precio_Venta,
        //         precioDescuento:product.Precio_Promocion > 0 ? product.Precio_Promocion : product.Precio_Venta, //cuando el producto no cuente con descuento mandar el precio normal del producto
        //         event:'recomendacionesCatGeneral'
        //     });                
        // },


        // Recomendaciones CAT -> Por menos de


        /**
         * 
         * El siguiente dataLayer va al momento de mostrarse la sección de POR MENOS DE
         * Tambien se envia al mardar el formulario
         * 
         * @param {string} state estado seleccionado
         * @param {string} city ciudad seleccionada
         * @param {string} resultadosTotal Cantidad total de resultados de todas las categoría
         * @param {string} filtrosLista <<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>
         * @param {string} precio precio seleccionado 
         * 
         */
        dataLayerLoadedFormPorMenosDe(state, city, resultadosTotal, filtrosLista, precio) {
            if(environment) return;
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Por menos de',
                nd3:'Llegada',
                nd4:'',
                estadoNombre: state, // <<Nombre del estado>>
                ciudadNombre: city, // <<Numero de la ciudad>>
                resultadosTotal: resultadosTotal, // '<<Cantidad total de resultados de todas las categorías>>',
                filtrosLista: filtrosLista, //'<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: precio, 
                event:'recomendadorCatFiltro'
            });
        },


        /**
         * 
         * Envia a datalayer los items de resultado de una busqueda
         * 
         * @param {string} state estado seleccionado 
         * @param {city} order ciudad seleccionada
         * @param {array} items Array de recomendaciones del modelo 
         * 
        */
        dataLayerItemsPorMenosDe(state, city, items) {
            if(environment) return;
            let lotes  = []
            let loteSize = 12
            // crear lotes de 12 elementos
            for (let i = 0; i < items.length; i += loteSize ) {
                lotes.push(items.slice(i, i + loteSize))
            }
            let itemPosition = 0;
            for (let i = 0; i < lotes.length; i++) {
                //Impresiones de producto
                let impressions = lotes[i].map((item) => {
                    ++itemPosition
                    return {
                        'name': item.Nombre,
                        'id': item.SKU, 
                        'price': item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta, //solo numeros, sin comas ni signos, ni puntos decimales, número entero 
                        'brand': item.caracteristicas && typeof item.caracteristicas === "object" ? item.caracteristicas.Marca : '', //si no lo puedes obtener, que vaya en blanco
                        'category': '', // es el id de la categoría. si no lo puedes obtener, que vaya en blanco
                        'variant': '', //si no lo puedes obtener, que vaya en blanco
                        'position': itemPosition ,//se especifica el número del producto del carrusel
                        'list':'Por menos de|MO_PRO_BLO-'+(i+1)+'|TRK-'+(i+1)
                    }
                })
                // Mandar lotes de doce elemenotos
                dataLayer.push({
                    ecommerce: {
                        impressions: impressions
                    },
                    nd1:'EEC - Promos - Impresion',
                    nd2:'Por menos de',
                    nd3:'MO_PRO_BLO-'+(i+1)+'|TRK-'+(i+1),
                    nd4:'1', //es un valor constante
                    estadoNombre: state,
                    ciudadNombre: city,
                    event:'promosImpresionPack'
                }); 

            }
            
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar una categoría de filtro
         * Clic en filtro
         * 
         * @param {string} filterName nombre del filtro
         * @param {string} state 
         * @param {string} city 
         */
        dataLayerClickFiltroPorMenosDe(filterName, state, city, filtersList, price) {
            if(environment) return;
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Por menos de - Desplegar filtro',
                nd3:filterName, // <<Nombre de la categoría de filtro seleccionado>>
                nd4:'',
                estadoNombre: state, // <<Nombre del estado>>
                ciudadNombre: city, // <<Numero de la ciudad>>
                filtrosLista: filtersList, // '<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: price, // '<<Precio seleccionado>',
                event:'recomendadorCatFiltro'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar un filtro
         * envia lista de filtros seleccionados al evento change de un input con filtro
         * 
         * @param {string} filter CLave|Valor
         * @param {string} state 
         * @param {string} city 
         * @param {string} filtersList 
         * @param {integer} price 
         */
        dataLayerChangeFilterPorMenosDe(filter, state, city, filtersList, price) {
            if(environment) return;
            if(filtersList == '') {
                filtersList = 'NA'
            }
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Por menos de - Filtro',
                nd3: filter, // <<Nombre de la categoría del filtro>>|<<Nombre del filtro seleccionado>>
                nd4:'',
                estadoNombre: state, // <<Nombre del estado>>
                ciudadNombre: city, // <<Numero de la ciudad>>
                filtrosLista: filtersList, // '<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: price, // '<<Precio seleccionado>', 
                event:'recomendadorCatFiltro'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar el botón de nueva búsqueda
         * 
         * @param {string} state 
         * @param {string} city 
         * @param {string} filters 
         * @param {integer} price 
         */
        dataLayerClickNuevaBusquedaPorMenosDe(state, city, filtersList, price) {
            if(environment) return;
            if(filtersList == '') {
                filtersList = 'NA'
            }
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Por menos de',
                nd3:'Nueva búsqueda',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                filtrosLista: filtersList, // '<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: price,// '<<Precio seleccionado>', 
                event:'recomendadorCatFiltro'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar la opción de precio
         * 
         * @param {string} state 
         * @param {string} city 
         * @param {string} filters 
         * @param {integer} price 
         */
        dataLayerClickPricePorMenosDe(state, city, filtersList, price) {
            if(environment) return;
            if(filtersList == '') {
                filtersList = 'NA'
            }
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Por menos de',
                nd3:'Selección de precio',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                filtrosLista: filtersList, //'<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: price, 
                event:'recomendadorCatFiltro'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar el monto de precio
         * 
         * @param {string} state 
         * @param {string} city 
         * @param {string} filters 
         * @param {integer} price precio actual
         * @param {integer} previousPrice precio anterior
         * 
         */
        dataLayerChangePricePorMenosDe(state, city, filtersList, price, previousPrice) {
            if(environment) return;
            if(filtersList == '') {
                filtersList = 'NA'
            }
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Por menos de',
                nd3:'Precio',
                nd4: previousPrice, //'<<Monto seleccionado>>',
                estadoNombre: state, //'<<Nombre del estado>>',
                ciudadNombre: city, //'<<Numero de la ciudad>>',
                filtrosLista: filtersList, //'<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: price, //'<<Precio seleccionado>', 
                event:'recomendadorCatFiltro'
            });
        },

        /**
         * 
         * El siguiente dataLayer va al momento de seleccionar el producto
         * Al momento de dar click en un prducto
         * 
         * @param {object} item objeto con datos del producto
         * @param {string} state 
         * @param {str}} city 
         */
        dataLayerClickItemPorMenosDe(item, state, city) {
            if(environment) return;
            var numBlock = item.index !== undefined ? item.index + 1 : 1
            console.log("indice", numBlock)
            numBlock = Math.ceil(numBlock / 12); // calcular en que bloque esta el item. cada 12 elementos es un bloque,  del 1 al 12 es bloque 1, del 13 al 24 bloque dos etc.
            dataLayer.push({
                ecommerce: {
                   click: {
                       actionField: {'list':'Por menos de|MO_PRO_BLO-'+numBlock+'|TRK-'+numBlock}, 
                    },
                    products: [{
                        'name':  item.Nombre, //<<Nombre del producto>>,
                        'id': item.SKU, // <<ID del producto (sku)>>,
                        'price': item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta,  //cuando el producto cuente con descuento entonces es el precio con descuento
                        'brand': item.caracteristicas && typeof item.caracteristicas === "object" ? item.caracteristicas.Marca : '',
                        'category': '', //si no lo puedes obtener, que vaya en blanco
                        'variant': '', //si no lo puedes obtener, que vaya en blanco
                        'position': item.index !== undefined ? item.index + 1 : ''//Se especifica el número del producto del carrusel
                    }]
                      
                 },
                 nd1:'EEC - Promos - Seleccion - Lista de productos',
                 nd2:'Por menos de',
                 nd3:'MO_PRO_BLO-'+numBlock+'|TRK-'+numBlock,
                 nd4: item.index !== undefined ? item.index + 1 : '', // '<<Posición del producto en la lista>>',
                 promoTexto: item.Nombre,  // '<<Texto alternativo del producto>>'
                 promoCreativo: item.URL_imagen.split("/").length > 1 ? item.URL_imagen.split("/").reverse()[0] : '', // nombre de la imágen // '<<Nombre del archivo de la imagen sin ruta>>'
                 estadoNombre: state, //'<<Nombre del estado>>',
                 ciudadNombre: city, //'<<Numero de la ciudad>>',
                 sku: item.SKU ,//'<<SKU del producto>>',
                 productoNombre:  item.Nombre, // '<<Nombre de producto>>',
                 precioNormal: item.Precio_Venta, // '<<Precio del producto sin descuento>>',
                 precioDescuento: item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta, // '<<Precio del producto con descuento>>', //cuando el producto no cuente con descuento mandar el precio normal del producto
                 event:'promosSeleccionLp'
            });
        },

        /**
         * El siguiente dataLayer va al momento de dar clic en el botón de Características|Descripción dentro de un producto
         * 
         * @param {String} btnName - Características|Descripción|Copiar
         * @param {String} product - Producto
         * @param {String} state - Nombre del estado
         * @param {String} city - Numero de la ciudad
         *  
         */
        dataLayerClickCaracDescPorMenosDe(btnName, product, state, city) {
            if(environment) return;
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Por menos de',
                nd3: btnName, //'<<Características|Descripción|Copiar>>',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                sku: product.SKU, //'<<SKU del producto>>', 
                productoNombre: product.Nombre, // '<<Nombre del producto>>',
                precioNormal: product.Precio_Venta, // '<<Precio del producto sin descuento>>',
                precioDescuento: product.Precio_Promocion > 0 ? product.Precio_Promocion : product.Precio_Venta, // '<<Precio del producto con descuento>>', //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'recomendacionesCatGeneral'
            });
        },

         /**
         *  El siguiente dataLayer va al momento de dar clic en el botón de Copiar Código o Copiar SKU
         * 
         * @param {String} btnName - <<Copiar código|Cópiar SKU>>
         * @param {Object} product - Nombre del estado
         * @param {String} state - Numero del estado
         * @param {String} city - Numero de la ciudad
         */
         dataLayerClickCardSKU(section, btnName, product, state, city) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2: section,
                nd3: 'Copiar ' + btnName,
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                sku: product.SKU, 
                productoNombre: product.Nombre,
                precioNormal: product.Precio_Venta,
                precioDescuento:product.Precio_Promocion > 0 ? product.Precio_Promocion : product.Precio_Venta, //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'recomendacionesCatGeneral'
            });
        },
        
        /**
         * El siguiente dataLayer va al momento de mostrarse el mensaje de sin resultados
         * 
         * @param {string} state 
         * @param {string} city 
         * @param {string} filters
         */
        dataLayerNoItemsPorMenosDe(state, city, filters, price) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Por menos de',
                nd3:'Sin resultados',
                nd4:'',
                estadoNombre: state, //'<<Nombre del estado>>', // Si no se tiene definido mandar como NA
                ciudadNombre: city, // '<<Numero de la ciudad>>', //Si no se tiene definido mandar como NA
                filtrosLista: filters, // '<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: price, // '<<precio seleccionado>', 
                event:'recomendadorCatFiltro'
            });
        },

        /**
         * envia los metodos dataLayerItemsPorMenosDe o dataLayerNoItemsPorMenosDe dependiendo de los resultados de la busqueda
         * @param {object} data formulario con datos de la busqueda
         * @param {array} items resultado de la busqueda
         */
        dataLayerSendItemsPorMenosDe(data, items) {
            if (items.length > 0) {
                this.dataLayerItemsPorMenosDe(data.estado, data.ciudad, items) // enviar datalayer
            } else {
                let filtersStr = ''
                if (data.area && data.area != '') {
                    filtersStr = 'Área:'+ data.area
                }
                if (data.primernivel && data.primernivel != '') {
                    if(filtersStr != '') {
                        filtersStr = filtersStr+';'
                    }
                    filtersStr = filtersStr + 'Primer nivel departamental:' + data.primernivel
                }
                if (data.segundonivel && data.segundonivel != '') {
                    if(filtersStr != '') {
                        filtersStr = filtersStr+';'
                    }
                    filtersStr = filtersStr + 'Segundo nivel departamental:' + data.segundonivel
                }
                if (data.tercernivel && data.tercernivel != '') {
                    if(filtersStr != '') {
                        filtersStr = filtersStr+';'
                    }
                    filtersStr = filtersStr + 'Tercer nivel departamental:' + data.tercernivel
                }
                if (data.cuartonivel && data.cuartonivel != '') {
                    if(filtersStr != '') {
                        filtersStr = filtersStr+';'
                    }
                    filtersStr = filtersStr + 'Cuarto nivel departamental:' + data.cuartonivel
                }
                this.dataLayerNoItemsPorMenosDe(data.estado, data.ciudad, filtersStr, data.precioMaximo) 
            }
        },


        // Recomendaciones CAT -> Top de productos

        /**
         * El siguiente dataLayer va al momento de mostrarse la sección de TOP PRODUCTOS
         * Evento enciado al encviar el formulario
         * 
         * @param {string} state 
         * @param {string} city 
         */
        dataLayerLoadPageTopProductos(state, city) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Top productos',
                nd3:'Llegada',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                event:'recomendadorCatGeneral'
            });
        },

        /**
         * 
         * El siguiente dataLayer va al momento de seleccionar una categoría de filtro
         * Al momento de dar click en un filtro
         * 
         * @param {String} filterName 
         * @param {String} state 
         * @param {String} city 
         * @param {String} filtrosLista
         */
        dataLayerClickFilterTopProductos(filterName, state, city, filtersList) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Top productos - Desplegar filtro',
                nd3: filterName,//'<<Nombre de la categoría de filtro seleccionado>>',
                nd4:'',
                estadoNombre: state, //'<<Nombre del estado>>',
                ciudadNombre: city, //'<<Numero de la ciudad>>',
                filtrosLista: filtersList, // '<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                event:'recomendadorCatTop'
            });
        },

        /**
         *  El siguiente dataLayer va al momento de seleccionar un filtro
         *  Al evento onChange de un filtro de Top de Productos
         * 
         * @param {string} filterSelected filtro seleccionado con formato Filter|Value
         * @param {string} state 
         * @param {string} city 
         */
        dataLayerChangeFilterTopProductos(filterSelected, state, city, filtersList) {
            if(filtersList == '') {
                filtersList = 'NA'
            }
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Top productos - Filtro',
                nd3: filterSelected, //'<<Nombre de la categoría del filtro>>|<<Nombre del filtro seleccionado>>',
                nd4:'',
                estadoNombre: state, //'<<Nombre del estado>>',
                ciudadNombre: city, // '<<Numero de la ciudad>>',
                filtrosLista: filtersList, // '<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                event:'recomendadorCatTop'
            });
        },

        /**
         * Top de Productos
         * El siguiente dataLayer va al momento de dar clic en el botón de Buscar 
         * 
         * @param {string} filters Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N
         * @param {string} state 
         * @param {string} city 
         */
        dataLayerClickFindTopProductos(filters, state, city) {
            if(filters == '') filters = 'NA'
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Top productos',
                nd3:'Buscar',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                filtrosLista: filters, //Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N al llegar a la página mandar como NA
                event:'recomendadorCatTop'
            });
        },

        /**
         * Top de productos
         * Envia a datalayer los items de resultado de una busqueda
         * 
         * @param {String} sectionName nombre de la seccion, lo mas vendido, lo mas buscado, lo mas visto
         * @param {Array} items Array de recomendaciones del modelo 
         * @param {Number} items Numero de bloque a colocar en TRK-number 
         * 
         * 
        */
        dataLayerItemsTopProductos(sectionName, items, numBlock) {
            if(environment) return;
            let lotes  = []
            let loteSize = 12
            // crear lotes de 12 elementos
            for (let i = 0; i < items.length; i += loteSize ) {
                lotes.push(items.slice(i, i + loteSize))
            }
            let itemPosition = 0;
            for (let i = 0; i < lotes.length; i++) {
                //Impresiones de producto
                let impressions = lotes[i].map((item) => {
                    ++itemPosition
                    return {
                        'name': item.Nombre,
                        'id': item.SKU, 
                        'price': item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta, //solo numeros, sin comas ni signos, ni puntos decimales, número entero 
                        'brand': item.caracteristicas && typeof item.caracteristicas === "object" ? item.caracteristicas.Marca : '', //si no lo puedes obtener, que vaya en blanco
                        'category': '', // es el id de la categoría. si no lo puedes obtener, que vaya en blanco
                        'variant': '', //si no lo puedes obtener, que vaya en blanco
                        'position': itemPosition, //se especifica el número del producto del carrusel
                        'list':'Top productos|MO_PRO_BLO-'+numBlock+'|TRK-'+numBlock+'|'+ sectionName
                    }
                })
                // Mandar lotes de doce elemenotos
                dataLayer.push({
                    ecommerce: {
                        impressions: impressions
                    },
                    nd1:'EEC - Promos - Impresion',
                    nd2:'Top productos',
                    nd3:'MO_PRO_BLO-'+numBlock+'|TRK-'+numBlock+'|'+sectionName,
                    nd4:'1', //es un valor constante
                    event:'promosImpresionPack'
                }); 

            }
        },


        /**
         * Top de producto
         * El siguiente dataLayer va siempre que en una página existan listas de productos y va al clic a cada producto dentro de la lista de productos. 
         * Al hacer click en el link de un productio
         * 
         * @param {String} section Nombre de la seccion, Los más vendidos, Lo más buscado, Lo más visto
         * @param {Number} numBlock Numero de bloque, 1 más vendido, 2 más buscado y 3 más visto
         * @param {Object} item 
         * @param {String} state - Nombre del estado
         * @param {String} city -  Nombre de la ciudad 
         *
         */
        dataLayerClickItemTopProductos(section, numBlock, item, state, city) {
            if(environment) return;
            dataLayer.push({
                ecommerce: {
                    click: {
                        actionField: {list:'Top productos|MO_PRO_BLO-'+numBlock+'|TRK-'+numBlock+'|'+section}, 
                    },
                    products: [{
                        name:  item.Nombre, //<<Nombre del producto>>,
                        id: item.SKU, // <<ID del producto (sku)>>,
                        price: item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta,  //cuando el producto cuente con descuento entonces es el precio con descuento
                        brand: item.caracteristicas && typeof item.caracteristicas === "object" ? item.caracteristicas.Marca : '',
                        category: '', //si no lo puedes obtener, que vaya en blanco
                        variant: '', //si no lo puedes obtener, que vaya en blanco
                        position: item.index !== undefined ? item.index + 1 : ''//Se especifica el número del producto del carrusel
                    }]   
                },
                nd1:'EEC - Promos - Seleccion - Lista de productos',
                nd2:'Top productos',
                nd3:'MO_PRO_BLO-'+numBlock+'|TRK-'+numBlock+'|'+section,
                nd4: item.index !== undefined ? item.index + 1 : '', //'<<Posición del producto en la lista>>',
                promoTexto: item.Nombre,  // '<<Texto alternativo del producto>>'
                promoCreativo: item.URL_imagen.split("/").length > 1 ? item.URL_imagen.split("/").reverse()[0] : '', // nombre de la imágen // '<<Nombre del archivo de la imagen sin ruta>>'
                estadoNombre: state,
                ciudadNombre: city,
                sku: item.SKU,
                productoNombre:  item.Nombre, // '<<Nombre de producto>>',
                precioNormal: item.Precio_Venta, // '<<Precio del producto sin descuento>>',
                precioDescuento: item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta, // '<<Precio del producto con descuento>>', //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'promosSeleccionLp'
            });
        },

         /**
          * Top de producto
         * El siguiente dataLayer va al momento de dar clic en el botón de Características|Descripción dentro de un producto
         * 
         * @param {object} data 
         */
         dataLayerClickCaracDescTopProductos(btnName, product, state, city) {
            if(environment) return;
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Top productos',
                nd3: btnName, // '<<Características|Descripción|Copiar>>',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                sku: product.SKU, //'<<SKU del producto>>', 
                productoNombre: product.Nombre, //'<<Nombre del producto>>',
                precioNormal: product.Precio_Venta, // '<<Precio del producto sin descuento>>',
                precioDescuento: product.Precio_Promocion > 0 ? product.Precio_Promocion : product.Precio_Venta, // '<<Precio del producto con descuento>>', //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'recomendacionesCatGeneral'
            });
        },


	}
}