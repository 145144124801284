<template  lang="pug">
    v-container
        template(v-if="!isComparadorVisible")
            ValidationObserver(ref='observer')
                form(action="#" @submit.prevent="fetchData")
                    v-row(align="baseline" class="pb-5")
                        v-col(sm="6" offset="1" class="pt-0 pb-0")
                            
                            v-tooltip( bottom)
                                template(v-slot:activator="{ on, attrs }")
                                    v-btn(@click="handleCleanForm" x-small color="primary" v-bind="attrs" v-on="on")
                                        v-icon(size="14") mdi-reload
                                span Limpiar formulario
                                
                    v-row(align="baseline")
                        v-col(sm="11" offset="1" class="pt-0 pb-0")
                            p(class="mb-0 text-caption") Aquí puedes encontrar el top 20 de productos, de todos los canales digitales, de acuerdo a la organización de Coppel.com. 
                    v-row(align="baseline")
                        v-col(sm="3" offset="1")
                            validation-provider(v-slot="{ errors }" name="area" )
                                v-autocomplete( 
                                    :items="inputAreaItems"
                                    :loading="loading"
                                    :disable="disable"
                                    label="Área"
                                    v-model='form.area'
                                    @click="handleClickAnyInput('Área')"
                                    @change="handleChangeInputArea"
                                    clearable
                                    )

                        //- v-col(sm='1')
                        //-     v-tooltip( top class="d-inline")
                        //-         template(v-slot:activator='{ on, attrs }')
                        //-             v-btn(icon color='primary' v-bind='attrs' v-on='on')
                        //-                 v-icon mdi-help-circle
                        //-         p( class="text-caption text-justify") 
                        //-             | Selecciona el área

                        //- v-col(sm="2")
                        //-     validation-provider(v-slot="{ errors }" name="departamento" )
                        //-         v-autocomplete( 
                        //-             :items="inputDepartamentoItems"
                        //-             :loading="loading"
                        //-             :disable="disable"
                        //-             label="Departamento"
                        //-             v-model='form.departamento'
                        //-             @change="handleChangeInputDepartamento"
                        //-             clearable
                        //-             )

                        //- v-col(sm='1')
                        //-     v-tooltip( top class="d-inline")
                        //-         template(v-slot:activator='{ on, attrs }')
                        //-             v-btn(icon color='primary' v-bind='attrs' v-on='on')
                        //-                 v-icon mdi-help-circle
                        //-         p( class="text-caption text-justify") 
                        //-             | Selecciona el departamento

                        v-col(sm="3")
                            validation-provider(v-slot="{ errors }" name="primernivel" )
                                v-autocomplete( 
                                    :items="inputPrimernivelItems"
                                    :loading="loading"
                                    :disable="disable"
                                    label="Primer nivel departamental"
                                    v-model='form.primernivel'
                                    clearable
                                    @click="handleClickAnyInput('Primer nivel departamental')"
                                    @change="handleChangeInputPrimernivel"
                                    )
                        
                        v-col(sm="3")
                            validation-provider(v-slot="{ errors }" name="segundonivel" )
                                v-autocomplete( 
                                    :items="inputSegundonivelItems"
                                    :loading="loading"
                                    :disable="disable"
                                    label="Segundo nivel departamental"
                                    v-model='form.segundonivel'
                                    @click="handleClickAnyInput('Segundo nivel departamental')"
                                    @change="handleChangeInputSegundonivel"
                                    clearable
                                    )
                        
                        //- v-col(sm='1')
                        //-     v-tooltip( top class="d-inline")
                        //-         template(v-slot:activator='{ on, attrs }')
                        //-             v-btn(icon color='primary' v-bind='attrs' v-on='on')
                        //-                 v-icon mdi-help-circle
                        //-         p( class="text-caption text-justify") 
                        //-             | Selecciona el primer nivel
                    v-row(align="baseline")

                        //- v-col(sm='1')
                        //-     v-tooltip( top class="d-inline")
                        //-         template(v-slot:activator='{ on, attrs }')
                        //-             v-btn(icon color='primary' v-bind='attrs' v-on='on')
                        //-                 v-icon mdi-help-circle
                        //-         p( class="text-caption text-justify") 
                        //-             | Selecciona el segundo nivel
                        v-col(sm="3" offset="1")
                            validation-provider(v-slot="{ errors }" name="tercernivel" )
                                v-autocomplete( 
                                    :items="inputTercernivelItems"
                                    :loading="loading"
                                    :disable="disable"
                                    label="Tercer nivel departamental"
                                    v-model='form.tercernivel'
                                    @click="handleClickAnyInput('Tercer nivel departamental')"
                                    @change="handleChangeInputTercernivel"
                                    clearable
                                    )

                        //- v-col(sm='1')
                        //-     v-tooltip( top class="d-inline")
                        //-         template(v-slot:activator='{ on, attrs }')
                        //-             v-btn(icon color='primary' v-bind='attrs' v-on='on')
                        //-                 v-icon mdi-help-circle
                        //-         p( class="text-caption text-justify") 
                        //-             | Selecciona el tercer nivel
                        v-col(sm="3")
                            validation-provider(v-slot="{ errors }" name="cuartonivel" )
                                v-autocomplete( 
                                    :items="inputCuartonivelItems"
                                    :loading="loading"
                                    :disable="disable"
                                    label="Cuarto nivel departamental"
                                    v-model='form.cuartonivel'
                                    @click="handleClickAnyInput('Cuarto nivel departamental')"
                                    @change="handleChangeInputCuartonivel"
                                    clearable
                                    )

                        //- v-col(sm='1')
                        //-     v-tooltip( top class="d-inline")
                        //-         template(v-slot:activator='{ on, attrs }')
                        //-             v-btn(icon color='primary' v-bind='attrs' v-on='on')
                        //-                 v-icon mdi-help-circle
                        //-         p( class="text-caption text-justify") 
                        //-             | Selecciona el cuarto nivel

                        v-col(offset="" sm="3" class="")
                            v-btn(color="primary" :loading="formLoading" :disabled="formLoading" type="submit"  small)
                                v-icon(size="20") mdi-magnify
            
                v-row(class="mt-7 mb-7") 
                    v-col(sm="10" offset="1")
                        v-divider
                v-row()
                    //- Top compras
                    v-col(cols="12" sm="12" lg="12" class="mb-5")
                        div(class="mb-4")
                            h2(class="text-h6 blue-600--text font-weight-bold d-inline-block") 
                                | Los más vendidos
                            v-tooltip( top class="d-inline-block ml-3")
                                template(v-slot:activator='{ on, attrs }')
                                    v-btn(icon color='primary' class="vertical-align-sub" v-bind='attrs' v-on='on')
                                        v-icon mdi-help-circle
                                p( class="text-caption text-justify") 
                                    | Muestra el top 20 de los productos mas vendidos, basados en los filtros de tu selección.
                        v-card(class="pa-2 overflow-y-auto d-flex flex-wrap" style="background-color: #E8DAEF;" elevation="0" max-height="480" v-if="topComprasProductsList.length")
                            CardItem( 
                                v-for="item, index in topComprasProductsList" 
                                :item="item" 
                                :key="item.SKU+index" 
                                activateComparator
                                enableShortenLink
                                @clickAction="(data) => handleClickActionCardItem('TOP_COMPRAS', data)"
                                @clickSKU="handleClickSKU"
                            )
                        v-card(v-else elevation="0" outlined)
                            v-card-text 
                                p(class="mb-0") Sin productos para mostrar
                    //- top búsquedas
                    v-col(cols="12" sm="12" lg="12" class="mb-5")
                        div(class="mb-4")
                            h2(class="text-h6 blue-600--text font-weight-bold d-inline-block") 
                                | Lo más buscado
                            v-tooltip( top class="d-inline-block ml-3")
                                template(v-slot:activator='{ on, attrs }')
                                    v-btn(icon color='primary' class="vertical-align-sub" v-bind='attrs' v-on='on')
                                        v-icon mdi-help-circle
                                p( class="text-caption text-justify") 
                                    | Muestra el top 20 de los productos mas buscados, basados en los filtros de tu selección.
                        v-card(class="pa-2 overflow-y-auto d-flex flex-wrap" style="background-color: #D1F2EB;" elevation="0" max-height="480" v-if="topBusquedasProductsList.length")
                            CardItem( 
                                v-for="item, index in topBusquedasProductsList" 
                                :item="item" 
                                :key="item.SKU+index" 
                                activateComparator
                                enableShortenLink
                                @clickAction="(data) => handleClickActionCardItem('TOP_BUSQUEDAS', data)"
                                @clickSKU="handleClickSKU"
                            )
                        v-card(v-else elevation="0" outlined)
                            v-card-text 
                                p(class="mb-0") Sin productos para mostrar
                    //- top vistas
                    v-col(cols="12" sm="12" lg="12" class="mb-5")
                        div(class="mb-4")
                            h2(class="text-h6 blue-600--text font-weight-bold d-inline-block") 
                                | Lo más visto
                            v-tooltip( top class="d-inline-block ml-3")
                                template(v-slot:activator='{ on, attrs }')
                                    v-btn(icon color='primary' class="vertical-align-sub" v-bind='attrs' v-on='on')
                                        v-icon mdi-help-circle
                                p( class="text-caption text-justify") 
                                    | Muestra el top 20 de los productos mas vistos, basados en los filtros de tu selección.
                        v-card(class="pa-2 overflow-y-auto d-flex flex-wrap" style="background-color: #FADBD8;" elevation="0" max-height="480" v-if="topVistasProductsList.length")
                            CardItem( 
                                v-for="item, index in topVistasProductsList" 
                                :item="item" 
                                :key="item.SKU+index" 
                                activateComparator
                                enableShortenLink
                                @clickAction="(data) => handleClickActionCardItem('TOP_VISTAS', data)"
                                @clickSKU="handleClickSKU"
                            )
                        v-card(v-else elevation="0" outlined)
                            v-card-text 
                                p(class="mb-0") Sin productos para mostrar
        template(v-if="isComparadorVisible")     
            v-row 
                v-col(sm="12")
                    TablaComparativaDeProductos(
                        class="w-100"
                        :items="productsComparatorList"
                        :state="state"
                        :city="city"
                        @onRemoveItem="handleCompararItem" )

        v-snackbar(v-model="snackbar" :timeout="timeout") {{snackbarText}} 
            template(v-slot:action="{ attrs }")
                v-btn(
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                ) Cerrar

        v-btn( color="primary" small dark fixed bottom right v-if="isComparadorVisible" @click="handleClickRegresarAComparador") <v-icon size="15" class="mr-1">mdi-keyboard-return</v-icon> Regresar
        v-btn( color="purple" small dark fixed bottom right v-else @click.prevent="handleClickComparador") <v-icon size="15" class="mr-1">mdi-select-compare</v-icon> comparador

        //- dialog acortador links
        v-dialog(
            v-model="dialogFlag"
            max-width="300"
        )
            v-card
                v-card-title(class="text-h6") Copia el link
                v-card-text {{ dialogText }}
                v-card-actions
                    v-spacer
                    v-btn(text @click="dialogFlag = false") Cerrar
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import CardItem from './CardItem';
import TablaComparativaDeProductos from './TablaComparativaDeProductos2';
import RecomendadorCatEndpointsService from '@/services/RecomendadorCatEndpointsService'
import ShortenUrlsService from '@/services/ShortenUrlsService'
const interfazCatService = new RecomendadorCatEndpointsService();
const shortenUrlsService = new ShortenUrlsService()
import DataLayersMixin from '@/mixins/DataLayersMixin'
import DataLayersComparadorMixin from '@/mixins/DataLayersComparadorMixin'

export default {
    name: 'RecomendacionesTopDeProductosTab',
    props: ['city', 'state'],
    components: {
        CardItem,
        TablaComparativaDeProductos
    },
    mixins: [DataLayersMixin, DataLayersComparadorMixin],
    data() {
        return {
            form: {
                area: '',
                departamento: '',
                primernivel: '',
                segundonivel: '',
                tercernivel: '',
                cuartonivel: '',
                estado: '',
                ciudad: '',
            },
            inputAreaItems: [],
            inputDepartamentoItems: [],
            inputPrimernivelItems: [],
            inputSegundonivelItems: [],
            inputTercernivelItems: [],
            inputTercernivelItems: [],
            inputCuartonivelItems: [],
            inputEstadoItems: [],
            inputCiudadItems: [],
            inputsDataLoading: false,
            formLoading: false,

            topBusquedasData: [],
            topComprasData: [],
            topVistasData: [],
        
            snackbar: false,
            timeout: 2000,
            snackbarText: '',

            // comparador
            isComparadorVisible: false,

            // dialog
            dialogFlag: false,
            // dialogTitle: '',
            dialogText: ''
        }
    },
    watch: {
        city(value) {
            this.form.ciudad = value
            if(this.form.ciudad && this.form.estado) {
                
                // refrescar inputs
                this.fetchDataInputs()
            } else {
                this.topBusquedasData = [];
                this.topComprasData = [];
                this.topVistasData = [];
            }
        },
        state(value) {
            this.form.estado = value
            if(this.form.ciudad && this.form.estado) {
                // refrescar inputs
                this.fetchDataInputs()
            } else {
                this.topBusquedasData = [];
                this.topComprasData = [];
                this.topVistasData = [];
            }
            
        }
    },
    computed: {
        ...mapState({
            productsComparatorList: state => state.tablaComparativaProductos.productsComparatorList,
        }),
        topVistasProductsList() {
            return this.topVistasData ? 
                    this.topVistasData.map(this.transform_data) : 
                    [];
        },
        topBusquedasProductsList() {
            return this.topBusquedasData ?
                    this.topBusquedasData.map(this.transform_data) :
                    [];
        },
        topComprasProductsList() {
            return this.topComprasData  ? 
                    this.topComprasData.map(this.transform_data) : 
                    [];
        },
        ...mapGetters('session', [
            'userNoEmpleado',
            'userTipoVenta',
        ]),
        loading() {
            return this.inputsDataLoading || this.formLoading
        },
        disable() {
            return this.loading 
        }
    },
    methods: {
        ...mapActions('tablaComparativaProductos', [
            'addProductToComparator',
            'removeProductFromComparator',
            'cleanProductsComparator'
        ]),
        handleCleanForm() {
            this.form.area = '';
            this.form.departamento = ''
            this.form.primernivel = ''
            this.form.segundonivel = ''
            this.form.tercernivel = ''
            this.form.cuartonivel = ''  
            this.fetchDataInputs()
        },
        handleClickComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
            // datalayer
            this.dayaLayerClickComparador("Top de productos", this.state, this.city)
        },
        handleClickRegresarAComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
            //datalayer
            this.dataLayerClickRegresarAComparador(this.state, this.city)
        },
        appendUTM(link) {
            if (this.userTipoVenta == null || this.userTipoVenta == '' || this.userNoEmpleado == null || this.userNoEmpleado == ''  ) {
                return link
            }
            let utm_source = 'cat';
            let utm_medium = 'vt';
            if (this.userTipoVenta == 'ASISTIDA') {
                utm_source = 'ven-asist-wpp';
                utm_medium = 'referido';
            }
            return `${link}?utm_campaign=interfaz-cat-ad&utm_medium=${utm_medium}&utm_source=${utm_source}&utm_content=${this.userNoEmpleado}`
        },
        transform_data(item, index) {
            // fecha
            let fec_FechaFinal = item.fec_FechaFinal != 'NaT' && item.fec_FechaFinal != null ? item.fec_FechaFinal.split('T')[0] : null;
            if (fec_FechaFinal) {
                fec_FechaFinal = fec_FechaFinal.split('-')
                if(Array.isArray(fec_FechaFinal) && fec_FechaFinal.length == 3) {
                    fec_FechaFinal = fec_FechaFinal[2] +'/' + fec_FechaFinal[1] + '/' + fec_FechaFinal[0];
                }   
            }
            // leyenda precio
            var price_text = '';
            if(item.price_coppel && typeof item.price_coppel == 'object') {
                price_text = `Cómpralo con crédito Coppel desde $${this.price_format(item.price_coppel.pago_quincenal)} quincenal ($${this.price_format(item.price_coppel.precio_contado)} en ${this.price_format(item.price_coppel.qty_quincenas)} quincenas*)`
            } else {
                let price_coppel = item.price_coppel && item.price_coppel != '' && item.price_coppel !== null ? item.price_coppel.split('/') : [];
                if(price_coppel.length == 3) {
                    price_text = `Cómpralo con crédito Coppel desde $${this.price_format(price_coppel[0])} quincenal ($${this.price_format(price_coppel[1])} en ${this.price_format(price_coppel[2])} quincenas*)`
                }
            }
            
            let Precio_Promocion_format = this.price_format(item.Precio_Promocion);
            let Precio_Venta_format = this.price_format(item.Precio_Venta);
            let descuento = item.Precio_Promocion == 0 || item.Precio_Venta == 0 ? 0 : Math.floor((1 - item.Precio_Promocion / item.Precio_Venta) * 100);
            let sku = item.SKU.toString();
            let SKU_RAW = sku.substr(0,sku.length -1 )
            let MP_flag = item.MP_flag && item.MP_flag == 1 ? 1 : 0;
            // link
            item.URL_producto = this.appendUTM(item.URL_producto)
            // salida
            return {...item, fec_FechaFinal, price_text, Precio_Venta_format, Precio_Promocion_format, descuento, SKU_RAW, MP_flag, index};
        },
        price_format(numb) {
            var str = numb.toString().split(".");
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");
        },
        fetchDataInputs() {
            if(this.form.estado == '' || this.form.ciudad == '') {
                return
            }
            let params = {}
            params['estado'] = this.form.estado
            params['ciudad'] = this.form.ciudad
            params['precioMaximo'] = 999999
            params['area'] = this.form.area && this.form.area !== '' ? this.form.area : 'Ninguno';
            params['departamento'] = this.form.departamento && this.form.departamento !== '' ? this.form.departamento : 'Ninguno';
            params['primernivel'] = this.form.primernivel && this.form.primernivel !== '' ? this.form.primernivel : 'Ninguno';
            params['segundonivel'] = this.form.segundonivel && this.form.segundonivel !== '' ? this.form.segundonivel : 'Ninguno';
            params['tercernivel'] = this.form.tercernivel && this.form.tercernivel !== '' ? this.form.tercernivel : 'Ninguno';
            params['cuartonivel'] = this.form.cuartonivel && this.form.cuartonivel !== '' ? this.form.cuartonivel : 'Ninguno';
            this.inputsDataLoading = true
            interfazCatService
                .fetchInputsPorMenosDe(params)
                .then((response)=> {
                    const {area, departamento, primernivel, segundonivel, tercernivel, cuartonivel} = response.data.Filtros;
                    this.inputAreaItems = area
                    this.inputDepartamentoItems = departamento
                    this.inputPrimernivelItems = primernivel
                    this.inputSegundonivelItems = segundonivel
                    this.inputTercernivelItems = tercernivel
                    this.inputCuartonivelItems = cuartonivel
                })
                .catch((error)=>{
                    console.log("Error fetching inputs :(");
                })    
                .finally(() => {
                    this.inputsDataLoading = false;
                })
        },
        fetchEndpointData() {
            if(this.form.estado == '' || this.form.ciudad == '') {
                return
            }
            this.formLoading = true;
            let params = {}
            params['estado'] = this.form.estado
            params['ciudad'] = this.form.ciudad
            params['precioMaximo'] = 999999
            params['area'] = this.form.area && this.form.area !== '' ? this.form.area : 'Ninguno';
            params['departamento'] = this.form.departamento && this.form.departamento !== '' ? this.form.departamento : 'Ninguno';
            params['primernivel'] = this.form.primernivel && this.form.primernivel !== '' ? this.form.primernivel : 'Ninguno';
            params['segundonivel'] = this.form.segundonivel && this.form.segundonivel !== '' ? this.form.segundonivel : 'Ninguno';
            params['tercernivel'] = this.form.tercernivel && this.form.tercernivel !== '' ? this.form.tercernivel : 'Ninguno';
            params['cuartonivel'] = this.form.cuartonivel && this.form.cuartonivel !== '' ? this.form.cuartonivel : 'Ninguno';
            interfazCatService
                .submitFormPorMenosDe(params)
                .then((response)=>{
                    if(response && response.data.status == 500) {
                        throw new Error('Error 500')
                    }
                    let {Top_Compras = [], Top_Vistas = [], Top_Busquedas = []} = response.data.recomendaciones
                    this.topBusquedasData = Top_Busquedas.map(item => ({...item, isSelectedForComparison: false, shortenedLink: null}))
                    this.topComprasData = Top_Compras.map(item => ({...item, isSelectedForComparison: false, shortenedLink: null}))
                    this.topVistasData = Top_Vistas.map(item => ({...item, isSelectedForComparison: false, shortenedLink: null}))

                    // datalayers
                    this.dataLayerItemsTopProductos('Los más vendidos', Top_Compras || [], 1)
                    this.dataLayerItemsTopProductos('Lo más buscado', Top_Busquedas || [], 2)
                    this.dataLayerItemsTopProductos('Lo más visto', Top_Vistas || [], 3)
                })
                .catch((error)=>{
                    console.log('Error enviando la busqueda :(')
                })
                .finally(()=>{
                    this.formLoading = false;
                })
        },
        fetchData() {
            this
                .$refs
                .observer
                .validate()
                .then( async res=> {
                    if(res && this.form.estado && this.form.ciudad){
                        this.fetchEndpointData()
                    } else {
                        this.topBusquedasData = [];
                        this.topComprasData = [];
                        this.topVistasData = [];
                        this.$emit('validateProps', {city: this.city, state: this.state});
                    }
                });
            // datalayer
            let filtersStr = this.getFiltersFormatStr()
            this.dataLayerClickFindTopProductos(filtersStr, this.state, this.city)
        },
        getFiltersFormatStr() {
            let filtersStr = ''
            if (this.form.area && this.form.area != '') {
                filtersStr = 'Área:'+this.form.area
            }
            if (this.form.primernivel && this.form.primernivel != '') {
                if(filtersStr != '') {
                    filtersStr = filtersStr+';'
                }
                filtersStr = filtersStr + 'Primer nivel departamental:' + this.form.primernivel
            }
            if (this.form.segundonivel && this.form.segundonivel != '') {
                if(filtersStr != '') {
                    filtersStr = filtersStr+';'
                }
                filtersStr = filtersStr + 'Segundo nivel departamental:' + this.form.segundonivel
            }
            if (this.form.tercernivel && this.form.tercernivel != '') {
                if(filtersStr != '') {
                    filtersStr = filtersStr+';'
                }
                filtersStr = filtersStr + 'Tercer nivel departamental:' + this.form.tercernivel
            }
            if (this.form.cuartonivel && this.form.cuartonivel != '') {
                if(filtersStr != '') {
                    filtersStr = filtersStr+';'
                }
                filtersStr = filtersStr + 'Cuarto nivel departamental:' + this.form.cuartonivel
            }
            return filtersStr;
        },
        updateFieldIsSelectedForComparison(productsList) {
            const skusComparatorList = this.productsComparatorList.map(item => item.SKU);
            return productsList.map(item => {
                let status = skusComparatorList.includes(item.SKU)
                return {...item, isSelectedForComparison: status};
            });
        },
        handleCompararItem(item) {
            if(item && item.isSelectedForComparison) {
                this.removeProductFromComparator(item)
            } else {
                this.addProductToComparator(item)
            }
            this.topBusquedasData = this.updateFieldIsSelectedForComparison(this.topBusquedasData)
            this.topComprasData = this.updateFieldIsSelectedForComparison(this.topComprasData)
            this.topVistasData = this.updateFieldIsSelectedForComparison(this.topVistasData)         
        },
        handleShortenLink(model, item) {
            const url = item.URL_producto
            const data = {
                data: {
                    target: url
                },
                item: item,
                model: model,
                callback: (link) => {
                    // this.snackbarText = `Link acortado copiado en el porta papeles`
                    // this.snackbar = true;
                    // navigator.clipboard.writeText(link);

                    // dialog
                    this.dialogFlag = true;
                    this.dialogText = link
                }
            }
            this.shortenLink(data)
        },
        handleClickActionCardItem(model, data) {
            const {action, item} = data
            switch(action) {
                case 'CLICK_BTN_LINK':
                    let sectionName = 'Los más vendidos'
                    let numBlock = 1;
                    if (model == 'TOP_BUSQUEDAS') {
                        numBlock = 2;
                        sectionName = 'Lo más buscado'
                    } else if (model == 'TOP_VISTAS') {
                        sectionName = 'Lo más visto'
                        numBlock = 3;
                    }
                    this.dataLayerClickItemTopProductos(sectionName, numBlock, item, this.state, this.city)
                    break;
                case 'CLICK_BTN_DESCRIPCION':
                    this.dataLayerClickCaracDescTopProductos('Descripción', item, this.state, this.city)

                    break;
                case 'CLICK_BTN_CARACTERISTICAS':
                    this.dataLayerClickCaracDescTopProductos('Características', item, this.state, this.city)
                    break;
                case 'CLICK_BTN_SHORTEND_LINK':
                    this.handleShortenLink(model, item)
                    this.dataLayerClickCaracDescTopProductos('Copiar', item, this.state, this.city)
                    break;
                case 'CLICK_BTN_COMPARAR':
                    this.handleCompararItem(item)
                    this.dataLaterClickAddToCompare('Top de productos', this.state, this.city, item)
                    break;
                default:
            }
        },
        handleClickSKU({sku, btnName, item}) {
            this.snackbarText = `SKU ${sku} Copiado en el portapapeles`
            navigator.clipboard.writeText(sku);
            this.snackbar = true;
            // datalayer
            this.dataLayerClickCardSKU('Top productos', btnName, item, this.state, this.city)
        },
        handleChangeInputArea() {
            this.form.departamento = ''
            this.form.primernivel = ''
            this.form.segundonivel = ''
            this.form.tercernivel = ''
            this.form.cuartonivel = ''
            this.fetchDataInputs()
            //datalayer
            this.sendDataLayerChangeFilter('Área', this.form.area)
        },
        handleChangeInputDepartamento() {
            this.form.primernivel = ''
            this.form.segundonivel = ''
            this.form.tercernivel = ''
            this.form.cuartonivel = ''
            this.fetchDataInputs()
        },
        handleChangeInputPrimernivel() {
            this.form.segundonivel = ''
            this.form.tercernivel = ''
            this.form.cuartonivel = ''
            this.fetchDataInputs()
            //datalayer
            this.sendDataLayerChangeFilter('Primer nivel departamental', this.form.primernivel)
        },
        handleChangeInputSegundonivel() {
            this.form.tercernivel = ''
            this.form.cuartonivel = ''
            this.fetchDataInputs()
            //datalayer
            this.sendDataLayerChangeFilter('Segundo nivel departamental', this.form.segundonivel)
        },
        handleChangeInputTercernivel() {
            this.form.cuartonivel = ''
            this.fetchDataInputs()
            //datalayer
            this.sendDataLayerChangeFilter('Tercer nivel departamental', this.form.tercernivel)
        },
        handleChangeInputCuartonivel() {
            this.fetchDataInputs()
            //datalayer
            this.sendDataLayerChangeFilter('Cuarto nivel departamental', this.form.cuartonivel)
        },
        handleClickAnyInput(filterName) {
            let filtersStr = this.getFiltersFormatStr()
            this.dataLayerClickFilterTopProductos(filterName, this.state, this.city, filtersStr)
        },
        sendDataLayerChangeFilter(filterKey, filterValue) {
            let filter = filterKey + '|' + (filterValue || 'NA')
            let filtersStr = this.getFiltersFormatStr()
            this.dataLayerChangeFilterTopProductos(filter, this.state, this.city, filtersStr)
        },
        //  recortador de links
        shortenLink(payload) {
            const model = payload.model
            let items = []
            let index = -1
            if(model == 'TOP_COMPRAS') {
                items = this.topComprasData
            } else if(model == 'TOP_BUSQUEDAS') {
                items = this.topBusquedasData
            } else {
                items = this.topVistasData
            }
            index = items.findIndex(i => payload.item.SKU == i.SKU)
            if (index !== undefined && index > -1 && items[index].shortenedLink) {
                payload.callback(items[index].shortenedLink);
                return
            }
            shortenUrlsService
                .create(payload.data)
                .then((response) => {
                    const data = response.data
                    if (items[index]) {
                        items[index].shortenedLink = data.link
                        if(model == 'TOP_COMPRAS') {
                            this.topComprasData = items
                        } else if(model == 'TOP_BUSQUEDAS') {
                            this.topBusquedasData = items
                        } else if(model == 'TOP_VISTAS') {
                            this.topVistasData = items
                        }
                    }
                })
                .finally(()=> {
                    if (typeof payload.callback === 'function') {
                        payload.callback(items[index].shortenedLink);
                    }
                })
        }
    },
    mounted() {
        this.form.estado = this.state
        this.form.ciudad = this.city
        // this.cleanCompareList();
        if(this.form.estado && this.form.ciudad && this.form.estado){
            this.fetchDataInputs();
        }

        this.cleanProductsComparator()
        // trigger datalayers mounted
        this.dataLayerLoadPageTopProductos(this.city, this.state)
       
    }
}
</script>


<style scoped>
.vertical-align-sub {
    vertical-align: sub !important;
}
.vertical-separator  {
    border-left: 1px solid #CCC;
}
</style>