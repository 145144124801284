var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.isComparadorVisible)?[_c('ValidationObserver',{ref:"observer"},[_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.fetchData($event)}}},[_c('v-row',{staticClass:"pb-5",attrs:{"align":"baseline"}},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"sm":"6","offset":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","color":"primary"},on:{"click":_vm.handleCleanForm}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-reload")])],1)]}}],null,false,3435548930)},[_c('span',[_vm._v("Limpiar formulario")])])],1)],1),_c('v-row',{attrs:{"align":"baseline"}},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"sm":"11","offset":"1"}},[_c('p',{staticClass:"mb-0 text-caption"},[_vm._v("Aquí puedes encontrar el top 20 de productos, de todos los canales digitales, de acuerdo a la organización de Coppel.com. ")])])],1),_c('v-row',{attrs:{"align":"baseline"}},[_c('v-col',{attrs:{"sm":"3","offset":"1"}},[_c('validation-provider',{attrs:{"name":"area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.inputAreaItems,"loading":_vm.loading,"disable":_vm.disable,"label":"Área","clearable":""},on:{"click":function($event){return _vm.handleClickAnyInput('Área')},"change":_vm.handleChangeInputArea},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}})]}}],null,false,2266720362)})],1),_c('v-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":"primernivel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.inputPrimernivelItems,"loading":_vm.loading,"disable":_vm.disable,"label":"Primer nivel departamental","clearable":""},on:{"click":function($event){return _vm.handleClickAnyInput('Primer nivel departamental')},"change":_vm.handleChangeInputPrimernivel},model:{value:(_vm.form.primernivel),callback:function ($$v) {_vm.$set(_vm.form, "primernivel", $$v)},expression:"form.primernivel"}})]}}],null,false,2511639348)})],1),_c('v-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":"segundonivel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.inputSegundonivelItems,"loading":_vm.loading,"disable":_vm.disable,"label":"Segundo nivel departamental","clearable":""},on:{"click":function($event){return _vm.handleClickAnyInput('Segundo nivel departamental')},"change":_vm.handleChangeInputSegundonivel},model:{value:(_vm.form.segundonivel),callback:function ($$v) {_vm.$set(_vm.form, "segundonivel", $$v)},expression:"form.segundonivel"}})]}}],null,false,3031514628)})],1)],1),_c('v-row',{attrs:{"align":"baseline"}},[_c('v-col',{attrs:{"sm":"3","offset":"1"}},[_c('validation-provider',{attrs:{"name":"tercernivel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.inputTercernivelItems,"loading":_vm.loading,"disable":_vm.disable,"label":"Tercer nivel departamental","clearable":""},on:{"click":function($event){return _vm.handleClickAnyInput('Tercer nivel departamental')},"change":_vm.handleChangeInputTercernivel},model:{value:(_vm.form.tercernivel),callback:function ($$v) {_vm.$set(_vm.form, "tercernivel", $$v)},expression:"form.tercernivel"}})]}}],null,false,1235667154)})],1),_c('v-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":"cuartonivel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.inputCuartonivelItems,"loading":_vm.loading,"disable":_vm.disable,"label":"Cuarto nivel departamental","clearable":""},on:{"click":function($event){return _vm.handleClickAnyInput('Cuarto nivel departamental')},"change":_vm.handleChangeInputCuartonivel},model:{value:(_vm.form.cuartonivel),callback:function ($$v) {_vm.$set(_vm.form, "cuartonivel", $$v)},expression:"form.cuartonivel"}})]}}],null,false,2869779739)})],1),_c('v-col',{attrs:{"offset":"","sm":"3"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.formLoading,"disabled":_vm.formLoading,"type":"submit","small":""}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-magnify")])],1)],1)],1)],1),_c('v-row',{staticClass:"mt-7 mb-7"},[_c('v-col',{attrs:{"sm":"10","offset":"1"}},[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('div',{staticClass:"mb-4"},[_c('h2',{staticClass:"text-h6 blue-600--text font-weight-bold d-inline-block"},[_vm._v(" Los más vendidos")]),_c('v-tooltip',{staticClass:"d-inline-block ml-3",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"vertical-align-sub",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle")])],1)]}}],null,false,1864903223)},[_c('p',{staticClass:"text-caption text-justify"},[_vm._v(" Muestra el top 20 de los productos mas vendidos, basados en los filtros de tu selección.")])])],1),(_vm.topComprasProductsList.length)?_c('v-card',{staticClass:"pa-2 overflow-y-auto d-flex flex-wrap",staticStyle:{"background-color":"#E8DAEF"},attrs:{"elevation":"0","max-height":"480"}},_vm._l((_vm.topComprasProductsList),function(item,index){return _c('CardItem',{key:item.SKU+index,attrs:{"item":item,"activateComparator":"","enableShortenLink":""},on:{"clickAction":function (data) { return _vm.handleClickActionCardItem('TOP_COMPRAS', data); },"clickSKU":_vm.handleClickSKU}})}),1):_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',[_c('p',{staticClass:"mb-0"},[_vm._v("Sin productos para mostrar")])])],1)],1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('div',{staticClass:"mb-4"},[_c('h2',{staticClass:"text-h6 blue-600--text font-weight-bold d-inline-block"},[_vm._v(" Lo más buscado")]),_c('v-tooltip',{staticClass:"d-inline-block ml-3",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"vertical-align-sub",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle")])],1)]}}],null,false,1864903223)},[_c('p',{staticClass:"text-caption text-justify"},[_vm._v(" Muestra el top 20 de los productos mas buscados, basados en los filtros de tu selección.")])])],1),(_vm.topBusquedasProductsList.length)?_c('v-card',{staticClass:"pa-2 overflow-y-auto d-flex flex-wrap",staticStyle:{"background-color":"#D1F2EB"},attrs:{"elevation":"0","max-height":"480"}},_vm._l((_vm.topBusquedasProductsList),function(item,index){return _c('CardItem',{key:item.SKU+index,attrs:{"item":item,"activateComparator":"","enableShortenLink":""},on:{"clickAction":function (data) { return _vm.handleClickActionCardItem('TOP_BUSQUEDAS', data); },"clickSKU":_vm.handleClickSKU}})}),1):_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',[_c('p',{staticClass:"mb-0"},[_vm._v("Sin productos para mostrar")])])],1)],1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('div',{staticClass:"mb-4"},[_c('h2',{staticClass:"text-h6 blue-600--text font-weight-bold d-inline-block"},[_vm._v(" Lo más visto")]),_c('v-tooltip',{staticClass:"d-inline-block ml-3",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"vertical-align-sub",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help-circle")])],1)]}}],null,false,1864903223)},[_c('p',{staticClass:"text-caption text-justify"},[_vm._v(" Muestra el top 20 de los productos mas vistos, basados en los filtros de tu selección.")])])],1),(_vm.topVistasProductsList.length)?_c('v-card',{staticClass:"pa-2 overflow-y-auto d-flex flex-wrap",staticStyle:{"background-color":"#FADBD8"},attrs:{"elevation":"0","max-height":"480"}},_vm._l((_vm.topVistasProductsList),function(item,index){return _c('CardItem',{key:item.SKU+index,attrs:{"item":item,"activateComparator":"","enableShortenLink":""},on:{"clickAction":function (data) { return _vm.handleClickActionCardItem('TOP_VISTAS', data); },"clickSKU":_vm.handleClickSKU}})}),1):_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',[_c('p',{staticClass:"mb-0"},[_vm._v("Sin productos para mostrar")])])],1)],1)],1)],1)]:_vm._e(),(_vm.isComparadorVisible)?[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('TablaComparativaDeProductos',{staticClass:"w-100",attrs:{"items":_vm.productsComparatorList,"state":_vm.state,"city":_vm.city},on:{"onRemoveItem":_vm.handleCompararItem}})],1)],1)]:_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Cerrar")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText)+" ")]),(_vm.isComparadorVisible)?_c('v-btn',{attrs:{"color":"primary","small":"","dark":"","fixed":"","bottom":"","right":""},on:{"click":_vm.handleClickRegresarAComparador}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-keyboard-return")]),_vm._v(" Regresar")],1):_c('v-btn',{attrs:{"color":"purple","small":"","dark":"","fixed":"","bottom":"","right":""},on:{"click":function($event){$event.preventDefault();return _vm.handleClickComparador($event)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15"}},[_vm._v("mdi-select-compare")]),_vm._v(" comparador")],1),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.dialogFlag),callback:function ($$v) {_vm.dialogFlag=$$v},expression:"dialogFlag"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Copia el link")]),_c('v-card-text',[_vm._v(_vm._s(_vm.dialogText))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogFlag = false}}},[_vm._v("Cerrar")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }