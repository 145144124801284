<template  lang="pug">
    v-card(outlined elevation="0")
        v-card-text 
            v-simple-table(class="my-tabla-comparativa text-center")
                template( v-slot:default )
                    tbody()
                        tr 
                            td(width="170px")
                            td(class="text-center" v-if="items[0]") 
                                div(style="width:220px;" class="d-inline-block")
                                    v-btn( color="red" fab class="mt-2" x-small @click="removeItem(items[0])"  ) 
                                        v-icon(color="white") mdi-close-circle
                                    CardItem(
                                        :item="items[0]" 
                                        @clickAction="(data) => handleClickActionCardItem(data)"
                                        class="d-inline-block")
                            td(class="text-center" v-if="items[1]")
                                div(style="width:220px;" class="d-inline-block")
                                    v-btn( color="red" fab class="mt-2" x-small  @click="removeItem(items[1])") 
                                        v-icon(color="white") mdi-close-circle
                                    CardItem(
                                        :item="items[1]" 
                                        @clickAction="(data) => handleClickActionCardItem(data)"
                                        class="d-inline-block")
                            td(class="text-center" v-if="items[2]")
                                div(style="width:220px;" class="d-inline-block")
                                    v-btn( color="red" fab class="mt-2" x-small  @click="removeItem(items[2])") 
                                        v-icon(color="white") mdi-close-circle
                                    CardItem(
                                        :item="items[2]"
                                        @clickAction="(data) => handleClickActionCardItem(data)")


                        tr(v-for="item in productFeatures")
                            td(class="text-left") 
                                b {{item[0].name}}
                            td(v-if="item[0]") {{ item[0].value }}
                            td(v-if="item[1] != undefined") {{ item[1].value }}
                            td(v-if="item[2] != undefined") {{ item[2].value }}
                         

</template>

<script>
import {mapState} from 'vuex'
import CardItem from './CardItem';
import DataLayersComparadorMixin from '@/mixins/DataLayersComparadorMixin'

export default {
    name: 'FeaturesProductsTable2',
    props: {
        // items: { type: Array, required: true},
        state: { type: String, required: false},
        city: { type: String, required: false},
    },
    mixins: [DataLayersComparadorMixin],
    components: {
        CardItem
    },
    computed: {
        ...mapState({
            items: state => state.tablaComparativaProductos.productsComparatorList,
        }),
        productFeatures() {
            /*
            Ejemplo de caracteristicas
            {
                "ColorAgrupador": " Negro (#000)",
                "Versión del sistema operativo": " 11 home",
                "Unidad de Estado Solido": " 512 GB",
                "Color": " negro",
                "Procesador": " intel core i5",
            }*/
            var _rows = []
            var _featureList = this.getFeatures()
            for( var feature of _featureList ) {
                var _cells = []
                for (var item of this.items) {
                    if (item.caracteristicas) {
                        var caracteristica = Object.keys(item.caracteristicas).find(item => item == feature)
                        if (caracteristica) {
                            _cells.push({name: feature, value: item.caracteristicas[feature], sku: item.SKU})
                        }else{
                            _cells.push({name: feature, value: '', sku: item.SKU})
                        }
                    }
                }
                _rows.push(_cells)
            }
            return _rows
        },
    },
    methods: {
        removeItem(item) {
            this.$emit('onRemoveItem', item);
            // datalayer
            this.dataLayerRemoveProductFromComparador(this.state, this.city, item)
        },
        // Obtener lista de caracteristicas a comparar
        getFeatures() {
            var _featureList = [];
            for(let item of this.items) {                
                if (item.caracteristicas != undefined) {
                    var keys = Object.keys(item.caracteristicas)
                    _featureList = _featureList.concat(keys)
                }
            }
            return [...new Set(_featureList)].sort()
        },
        handleClickActionCardItem(data) {
            const {action, item} = data
            switch(action) {
                case 'CLICK_BTN_LINK':
                    this.dataLayerClickVisitProduct(item, this.state, this.city)
                    break;
                case 'CLICK_BTN_SHORTEND_LINK':
                    break;
                case 'CLICK_BTN_COMPARAR':
                    break;
                case 'CLICK_BTN_DESCRIPCION':
                    this.dataLayerClickCaracDescProduct('Descripción', this.state, this.city, item);
                    break;
                case 'CLICK_BTN_CARACTERISTICAS':
                    this.dataLayerClickCaracDescProduct('Características', this.state, this.city, item);
                    break;
                default:
            }
        },
    },
    mounted() {
        // datalayeerts
        this.dataLayerLoadComparadorProductos(this.state, this.city);
        if(this.items.length > 0) {
            this.dataLayerProductsComparador(this.state, this.city, this.items);
        }
    }
}
</script>

<style scoped>
    .my-tabla-comparativa td {
        color: #6E7272;
        font-size: 0.8rem !important;
    }
</style>