var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('ValidationObserver',{ref:"observer"},[_c('form',{ref:"myForm",attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.fetchData($event)}}},[_c('v-container',{staticClass:"pb-0 pt-7"},[_c('v-row',{attrs:{"align":"end","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"end"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"default","small":""},on:{"click":function($event){return _vm.$router.push({name: 'buzon_mensajes'})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-message-text")])],1)]}}])},[_c('span',[_vm._v("Buzón de comentarios")])])],1),_c('v-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":"estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"mb-0 text-caption"},[_vm._v("Selecciona el Estado y la Ciudad para mostrarte artículos con disponibilidad")]),_c('v-autocomplete',{ref:"estadoSelect",attrs:{"error-messages":errors,"required":"","x-small":"","items":_vm.statesList,"label":"Estado","disabled":_vm.loadingRegions,"loading":_vm.loadingRegions,"clearable":""},on:{"change":_vm.handleChangeState},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})]}}])})],1),_c('v-col',{attrs:{"sm":"3"}},[_c('validation-provider',{attrs:{"name":"ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"required":"","items":_vm.citiesList,"label":"Ciudad","small":"","disabled":_vm.loadingRegions,"loading":_vm.loadingRegions,"clearable":""},on:{"change":_vm.handleChangeCity},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}])})],1),_c('v-col',{attrs:{"sm":"1"}},[_c('v-btn',{staticClass:"mb-6 ml-6",attrs:{"color":"primary","small":""},on:{"click":_vm.handleResetForm}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-reload")])],1)],1)],1)],1)],1)]),_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{on:{"click":function (){_vm.dataLayerClickTabRecCAT('Recomendaciones por artículo', _vm.state, _vm.city); this$1.dataLayerLoadedRecomendacionesPorArticulo(_vm.state, _vm.city)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Recomendaciones por artículo ")])]}}])},[_c('span',[_vm._v("Podrás buscar productos similares y complementarios al articulo de interes. ")])])],1),_c('v-tab',{on:{"click":function($event){return _vm.dataLayerClickTabRecCAT('Por menos de', _vm.state, _vm.city)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Por menos de ")])]}}])},[_c('span',[_vm._v("Podrás seleccionar un límite de precio entre $100 - $500 y encontrar productos de igual o menor costo, además de filtrar por los niveles departamentales de tu interés. ")])])],1),_c('v-tab',{on:{"click":function($event){return _vm.dataLayerClickTabRecCAT('Seleccionadores de producto', _vm.state, _vm.city)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Seleccionadores de producto")])]}}])},[_c('span',[_vm._v("Encontrarás herramientas para seleccionar el producto adecuado para el cliente. ")])])],1),_c('v-tab',{on:{"click":function($event){return _vm.dataLayerClickTabRecCAT('Top de productos', _vm.state, _vm.city)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Top de productos")])]}}])},[_c('span',[_vm._v("Top de los 20 productos más vendidos, vistos y buscados de los canales digitales.")])])],1)],1),_c('br'),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('RecomendacionesPorArticuloTab',{attrs:{"state":_vm.state,"city":_vm.city},on:{"validateProps":_vm.onValidateForm}})],1),_c('v-tab-item',{staticStyle:{"background":"rgb(0 57 96 / 3%)"}},[_c('RecomendacionesPorMenosDeTab',{attrs:{"state":_vm.state,"city":_vm.city}})],1),_c('v-tab-item',{staticStyle:{"background":"rgb(0 57 96 / 3%)"}},[_c('SeleccionadorProductosTab',{attrs:{"state":_vm.state,"city":_vm.city}})],1),_c('v-tab-item',[_c('RecomendacionesTopDeProductosTab',{attrs:{"state":_vm.state,"city":_vm.city},on:{"validateProps":_vm.onValidateForm}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }