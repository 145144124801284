<template  lang="pug">
    v-container()
        v-row()
            v-col( cols="12")
                v-card(outlined)
                    ValidationObserver(ref='observer')
                        form(action="#" @submit.prevent="fetchData" ref='myForm')
                            v-container(class="pb-0 pt-7")
                                v-row(align="end" justify="center")
                                    v-col(cols="12" align="end")
                                        v-tooltip(top)
                                            template(v-slot:activator="{ on, attrs }")
                                                v-btn(color="default" small  @click="$router.push({name: 'buzon_mensajes'})" v-bind="attrs" v-on="on") 
                                                    v-icon(size="20") mdi-message-text
                                            span Buzón de comentarios
                                    v-col(sm="3")
                                        validation-provider(v-slot="{ errors }" name="estado" rules="required")
                                            p(class="mb-0 text-caption") Selecciona el Estado y la Ciudad para mostrarte artículos con disponibilidad
                                            v-autocomplete(
                                                :error-messages="errors"
                                                required
                                                x-small
                                                :items="statesList"
                                                v-model='state'
                                                ref="estadoSelect"
                                                label='Estado'
                                                :disabled="loadingRegions"
                                                :loading="loadingRegions"
                                                @change="handleChangeState"
                                                clearable
                                            )
                                    v-col(sm="3")
                                        validation-provider(v-slot="{ errors }" name="ciudad" rules="required")
                                            v-autocomplete(
                                                :error-messages="errors"
                                                required
                                                :items="citiesList"
                                                v-model='city'
                                                label='Ciudad'
                                                small
                                                :disabled="loadingRegions"
                                                :loading="loadingRegions"
                                                @change="handleChangeCity"
                                                clearable
                                            )
                                    v-col(sm="1")
                                        v-btn(color="primary" small  class="mb-6 ml-6" @click="handleResetForm") 
                                            v-icon(size="20") mdi-reload
                                    //- v-col(sm="1")
                                        //- v-btn(color="primary" small :loading="loading" :disabled="loading" type="submit" class="mb-6")
                                        //-     v-icon(size="20") mdi-magnify
                    v-card-text
                        v-tabs(v-model="tab")
                            v-tab(@click="()=>{dataLayerClickTabRecCAT('Recomendaciones por artículo', state, city); this.dataLayerLoadedRecomendacionesPorArticulo(state, city)}") 
                                v-tooltip(top)
                                    template(v-slot:activator="{ on, attrs }")
                                        span(v-bind="attrs" v-on="on") Recomendaciones por artículo 
                                    span Podrás buscar productos similares y complementarios al articulo de interes. 
                            v-tab(@click="dataLayerClickTabRecCAT('Por menos de', state, city)") 
                                v-tooltip(top)
                                    template(v-slot:activator="{ on, attrs }")
                                        span(v-bind="attrs" v-on="on") Por menos de 
                                    span Podrás seleccionar un límite de precio entre $100 - $500 y encontrar productos de igual o menor costo, además de filtrar por los niveles departamentales de tu interés. 
                            v-tab(@click="dataLayerClickTabRecCAT('Seleccionadores de producto', state, city)") 
                                v-tooltip(top)
                                    template(v-slot:activator="{ on, attrs }")
                                        span(v-bind="attrs" v-on="on") Seleccionadores de producto
                                    span Encontrarás herramientas para seleccionar el producto adecuado para el cliente. 
                            v-tab(@click="dataLayerClickTabRecCAT('Top de productos', state, city)") 
                                v-tooltip(top)
                                    template(v-slot:activator="{ on, attrs }")
                                        span(v-bind="attrs" v-on="on") Top de productos
                                    span Top de los 20 productos más vendidos, vistos y buscados de los canales digitales.
                            //- v-tab(@click="dataLayerClickTabRecCAT('Recomendaciones Personalizadas')")
                            //-     v-tooltip(top)
                            //-         template(v-slot:activator="{ on, attrs }")
                            //-             span(v-bind="attrs" v-on="on") Recomendaciones Personalizadas 
                            //-         span Muestra un conjunto de 5 carruseles de productos basados en la interacción del cliente, siempre y cuando el cliente haya iniciado sesión con su usuario o cuenta en canales digitales. 
                        br
                        v-tabs-items(v-model="tab")
                            v-tab-item()
                                RecomendacionesPorArticuloTab(:state="state" :city="city"  @validateProps="onValidateForm")
                            v-tab-item(style="background: rgb(0 57 96 / 3%);")
                                RecomendacionesPorMenosDeTab(:state="state" :city="city")
                            v-tab-item(style="background: rgb(0 57 96 / 3%);")
                                SeleccionadorProductosTab(:state="state", :city="city")
                            v-tab-item()
                                RecomendacionesTopDeProductosTab(:state="state" :city="city" @validateProps="onValidateForm")
                            //- v-tab-item()
                            //-     RecomendacionesPersonalizadasTab(:state="state" :city="city" @validateProps="onValidateForm")
</template>
<script>
import { mapActions } from 'vuex'
import DataLayersMixin from '@/mixins/DataLayersMixin'
import RecomendacionesPorArticuloTab from './components/RecomendacionesPorArticuloTab'
import SeleccionadorProductosTab from './components/SeleccionadorProductosTab';
import RecomendacionesPorMenosDeTab from './components/RecomendacionesPorMenosDeTab';
import RecomendacionesTopDeProductosTab from './components/RecomendacionesTopDeProductosTab';
// import RecomendacionesPersonalizadasTab from './components/RecomendacionesPersonalizadasTab.vue';
// import InterfazCatService from '@/services/InterfazCatService'
// const interfazCatService = new InterfazCatService();
import RecomendadorCatEndpointsService from '@/services/RecomendadorCatEndpointsService'
const interfazCatService = new RecomendadorCatEndpointsService();
export default {
    name: 'InterfazCAT2',
    data() {
        return {
            tab: null,
            // form
            loading: false,
            loadingRegions: false,
            state: '',
            city: '',
            statesCitiesList: []
        }
    },
    mixins: [DataLayersMixin],
    components: {
        RecomendacionesPorArticuloTab,
        SeleccionadorProductosTab,
        RecomendacionesPorMenosDeTab,
        RecomendacionesTopDeProductosTab,
        // RecomendacionesPersonalizadasTab
    },
    computed: {
        // ...mapGetters({
        //     email: 'email'
        // }),
        // canSeeSection() {
        //     return [
        //         'alberto.valencia@coppel.com',
        //         'rebeca.renedo@coppel.com',
        //         'marco.salgado@coppel.com',
        //         'luis.matus@coppel.com',
        //         'paola.leon@coppel.com',
        //         'antonio.alvarado@coppel.com'
        //     ].includes(this.email)
        // },
        statesList() {
            return this.statesCitiesList.map((item)=>item['estado']).sort()
        },
        citiesList() {
            return this.statesCitiesList.flatMap((item)=>item['ciudad']).sort()
        }
    },
    watch: {
        state(newState, oldState) {
            let city = this.city ? this.city : 'Ninguno';
            let state = newState ? newState : 'Ninguno';
            this.getStateWithCities(state, city);
        },
        city (newVal) {
            let city = newVal ? newVal : 'Ninguno';
            let state = this.state ? this.state : 'Ninguno';
            this.getStateWithCities(state, city);
        }
    },
    methods: {
        ...mapActions('tablaComparativaProductos', [
            'cleanProductsComparator'
        ]),
        fetchData() {

        },
        onValidateForm() {
            this.$refs.observer.validate()
                .then( async res=> {
                    if(res){
                    } else {

                    }
                });
        },
        getStateWithCities(state, city) {
            this.loadingRegions = true;
            const stateSelected = state == 'CDMX' ? 'DISTRITO FEDERAL' : state;
            interfazCatService
                .fetchStateCities(stateSelected, city)
                .then((data)=> {
                    this.statesCitiesList = data.data.estados_ciudad.map(item => {
                        if(item.estado == 'DISTRITO FEDERAL') {
                            item.estado = 'CDMX';
                        }
                        return item
                    });
                })
                .finally(() => {
                    this.loadingRegions = false;
                })
        },
        handleChangeState() {
            this.cleanProductsComparator()
        },
        handleChangeCity() {
            this.cleanProductsComparator()
        },
        handleResetForm() {
            this.state = ''
            this.city = ''
            this.cleanProductsComparator()
        }
    },
    mounted() {
        this.getStateWithCities('Ninguno', 'Ninguno');
        // envet datalayer
        this.dataLayerLoadedPageRecomendadorCAT();
        this.dataLayerLoadedRecomendacionesPorArticulo(this.state, this.city)
    }
}
</script>
