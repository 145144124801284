<template  lang="pug">
    v-container
        v-row(class="seleccionador")
            v-col(cols="12")
                h1( class="text-center text-h6 blue-500--text mb-4") Por menos de

            template(v-if="!isComparadorVisible")
                v-col(cols="12" md="3")
                    v-card(elevation="0" outlined)
                        v-card-text
                            div(class="text-left")
                                //- p( class="text-title font-weight-bold blue-500--text") Características del producto
                                p( class="text-caption") Aquí puedes filtrar un producto adecuado para el cliente con costo menor o igual al precio que seleccionaste. El ordenamiento es el mismo que en Coppel.com.
                            div(class="d-flex justify-space-between mb-6")
                                v-btn(@click="handleCleanForm" x-small color="primary") Nueva busqueda
                        
                            v-expansion-panels(class="mt-5" multiple v-model='panel')
                                v-expansion-panel()
                                    v-expansion-panel-header(class="") Área
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            :loading="loading"
                                            :disabled="disableInput"
                                            v-model="form.area"
                                            :items="area"
                                            label="Elige una opción"
                                            clearable
                                            @click="handleClickInput('Área')"
                                            @change="handleChangeInputArea"
                                        )
                                //- v-expansion-panel()
                                //-     v-expansion-panel-header(class="") Departamento
                                //-     v-expansion-panel-content
                                //-         v-autocomplete(
                                //-             :loading="loading"
                                //-             :disabled="disableInput"
                                //-             v-model="form.departamento"
                                //-             :items="departamento"
                                //-             label="Elige una opción"
                                //-             clearable
                                //-             @change="handleChangeInputDepartamento"
                                //-         )
                                v-expansion-panel()
                                    v-expansion-panel-header(class="") Primer nivel departamental
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            :loading="loading"
                                            :disabled="disableInput"
                                            v-model="form.primernivel"
                                            :items="primernivel"
                                            label="Elige una opción"
                                            clearable
                                            @click="handleClickInput('Primer nivel departamental')"
                                            @change="handleChangeInputPrimernivel"
                                        )
                                v-expansion-panel()
                                    v-expansion-panel-header(class="") Segundo nivel departamental
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            :loading="loading"
                                            :disabled="disableInput"
                                            v-model="form.segundonivel"
                                            :items="segundonivel"
                                            label="Elige una opción"
                                            clearable
                                            @click="handleClickInput('Segundo nivel departamental')"
                                            @change="handleChangeInputSegundonivel"
                                        )
                                v-expansion-panel()
                                    v-expansion-panel-header(class="") Tercer nivel departamental
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            :loading="loading"
                                            :disabled="disableInput"
                                            v-model="form.tercernivel"
                                            :items="tercernivel"
                                            label="Elige una opción"
                                            clearable
                                            @click="handleClickInput('Tercer nivel departamental')"
                                            @change="handleChangeInputTercernivel"
                                        )
                                v-expansion-panel()
                                    v-expansion-panel-header(class="") Cuarto nivel departamental
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            :loading="loading"
                                            :disabled="disableInput"
                                            v-model="form.cuartonivel"
                                            :items="cuartonivel"
                                            label="Elige una opción"
                                            clearable
                                            @click="handleClickInput('Cuarto nivel departamental')"
                                            @change="handleChangeInputCuartonivel"
                                        )


                v-col(cols="12" md="9")
                    v-row(class="align-center")
                        v-col( offset="8" sm="4" class="pt-0")
                            v-card(outlined elevation="0")
                                v-card-text(class="pt-0 pb-0 d-flex align-center")
                                    v-row()
                                        v-col
                                            v-select(
                                                label="Precio:"
                                                v-model="form.precioMaximo"
                                                :items="[100,200,300,400,500]"
                                                @click="handleClickPrecio"
                                                @change="handleChangePrecio"
                                            )

                    v-card(outlined elevation="0")
                        v-card-text 
                            div(class="d-flex justify-center" v-if="productsListLoading")
                                    v-progress-circular(indeterminate color="primary")
                            template( v-else-if="!productsListLoading && productsList.length > 0")
                                div(class="d-flex flex-wrap")
                                    CardItem( 
                                        v-for="item, index in itemsTransformedList" 
                                        :item="item" 
                                        :key="item.SKU" 
                                        @clickSKU="handleClickSKU"
                                        activateComparator
                                        enableShortenLink
                                        @clickAction="(data) => handleClickActionCardItem('Por menos de', data)")
                                //- div(class="mt-8") 
                                //-     v-pagination(v-model="pageNumber" :length="paginationLength" @input="handleInputPaginator" :total-visible="10")
                            p(class="text-center" v-else) Lo sentimos, tu búsqueda no arrojó resultados, <br> puedes modificar la disponibilidad por estado o ciudad o probar con otros filtros
                            template(v-if="isComparadorVisible")
            template(v-if="isComparadorVisible")     
                v-col(sm="12")
                    TablaComparativaDeProductos(
                        class="w-100"
                        :items="productsComparatorList"
                        :state="state"
                        :city="city"
                        @onRemoveItem="handleCompararItem"
                    )
        //- 
        v-snackbar(v-model="snackbar" :timeout="timeout") {{snackbarText}}
            template(v-slot:action="{ attrs }")
                v-btn(
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                ) Cerrar

        v-btn( color="primary" small dark fixed bottom right v-if="isComparadorVisible" @click="handleClickRegresarAComparador") <v-icon size="15" class="mr-1">mdi-keyboard-return</v-icon> Regresar
        v-btn( color="purple" small dark fixed bottom right v-else @click.prevent="handleClickComparador") <v-icon size="15" class="mr-1">mdi-select-compare</v-icon> comparador

        //- dialog acortador links
        v-dialog(
            v-model="dialogFlag"
            max-width="300"
        )
            v-card
                v-card-title(class="text-h6") Copia el link
                v-card-text {{ dialogText }}
                v-card-actions
                    v-spacer
                    v-btn(text @click="dialogFlag = false") Cerrar

</template>

<script>
import {mapState, mapActions, mapGetters } from 'vuex'
import CardItem from './CardItem';
import TablaComparativaDeProductos from './TablaComparativaDeProductos2';
import DataLayersMixin from '@/mixins/DataLayersMixin'
import DataLayersComparadorMixin from '@/mixins/DataLayersComparadorMixin'


export default {
    name: 'PorMenosDeTab',
    props: ['city', 'state'],
    components: {
        CardItem,
        TablaComparativaDeProductos
    },
    mixins: [DataLayersMixin, DataLayersComparadorMixin],
    data() {
        return {
            panel: [0,1,2,3,4,5],
            // isComparadorVisible: false,
            snackbarText: '',
            snackbar: false,
            timeout: 2000,
            // comparador
            isComparadorVisible: false,
            // dialog
            dialogFlag: false,
            // dialogTitle: '',
            dialogText: '',
            //
            previousPrice: 0, // variable que guarda el precio anterior al seleccionado
        }
    },
    watch: {
        city(value) {
            this.form.ciudad = value
            if(this.form.ciudad && this.form.estado) {
                // refrescar inputs
                this.fetchSeleccionadorInputs()
                // enviar formulario
                this.sendForm()
            } else {
                this.$store.commit('seleccionadorPorMenosDe/SET_PRODUCTS_LIST', [])
            }
        },
        state(value) {
            this.form.estado = value
            if(this.form.ciudad && this.form.estado) {
                // refrescar inputs
                this.fetchSeleccionadorInputs()
                // enviar formulario
                this.sendForm()
            } else {
                this.$store.commit('seleccionadorPorMenosDe/SET_PRODUCTS_LIST', [])
            }
        },
    },
    computed: {
        ...mapState({
            productsComparatorList: state => state.tablaComparativaProductos.productsComparatorList,
            form: state => state.seleccionadorPorMenosDe.form,
            area: state => state.seleccionadorPorMenosDe.area,
            departamento: state => state.seleccionadorPorMenosDe.departamento,
            primernivel: state => state.seleccionadorPorMenosDe.primernivel,
            segundonivel: state => state.seleccionadorPorMenosDe.segundonivel,
            tercernivel: state => state.seleccionadorPorMenosDe.tercernivel,
            cuartonivel: state => state.seleccionadorPorMenosDe.cuartonivel,
            inputsDataLoading: state => state.seleccionadorPorMenosDe.inputsDataLoading,
            productsList: state => state.seleccionadorPorMenosDe.productsList,
            productsListLoading: state => state.seleccionadorPorMenosDe.productsListLoading,
        }),
        ...mapGetters('session', [
            'userNoEmpleado',
            'userTipoVenta',
        ]),
        itemsTransformedList() {
            return this.productsList.map(this.transform_data) || [];
        },
        disableInput() {
            return this.productsListLoading || this.inputsDataLoading || this.city == '' || this.state == '' ||  this.city == undefined || this.state == undefined
        },
        loading() {
            return this.productsListLoading || this.inputsDataLoading
        },
    },
    methods: {
        ...mapActions('tablaComparativaProductos', [
            'addProductToComparator',
            'removeProductFromComparator',
            'cleanProductsComparator'
        ]),
        ...mapActions('seleccionadorPorMenosDe', [
            'fetchSeleccionadorInputs',
            'submitForm',
            'cleanForm',
            'resetSeleccionador',
            'shortenLink'
        ]),
        handleClickComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
            // datalayer
            this.dayaLayerClickComparador("Por menos de", this.state, this.city)
        },
        handleClickRegresarAComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
            //datalayer
            this.dataLayerClickRegresarAComparador(this.state, this.city)
        },
        appendUTM(link) {
            if (this.userTipoVenta == null || this.userTipoVenta == '' || this.userNoEmpleado == null || this.userNoEmpleado == ''  ) {
                return link
            }
            let utm_source = 'cat';
            let utm_medium = 'vt';
            if (this.userTipoVenta == 'ASISTIDA') {
                utm_source = 'ven-asist-wpp';
                utm_medium = 'referido';
            }
            return `${link}?utm_campaign=interfaz-cat-ad&utm_medium=${utm_medium}&utm_source=${utm_source}&utm_content=${this.userNoEmpleado}`
        },
        transform_data(item, index) {
            // fecha
            let fec_FechaFinal = item.fec_FechaFinal != 'NaT' && item.fec_FechaFinal != null ? item.fec_FechaFinal.split('T')[0] : null;
            if (fec_FechaFinal) {
                fec_FechaFinal = fec_FechaFinal.split('-')
                if(Array.isArray(fec_FechaFinal) && fec_FechaFinal.length == 3) {
                    fec_FechaFinal = fec_FechaFinal[2] +'/' + fec_FechaFinal[1] + '/' + fec_FechaFinal[0];
                }   
            }
            // leyenda precio
            var price_text = '';
            if(item.price_coppel && typeof item.price_coppel == 'object') {
                price_text = `Cómpralo con crédito Coppel desde $${this.price_format(item.price_coppel.pago_quincenal)} quincenal ($${this.price_format(item.price_coppel.precio_contado)} en ${this.price_format(item.price_coppel.qty_quincenas)} quincenas*)`
            } else {
                let price_coppel = item.price_coppel && item.price_coppel != '' && item.price_coppel !== null ? item.price_coppel.split('/') : [];
                if(price_coppel.length == 3) {
                    price_text = `Cómpralo con crédito Coppel desde $${this.price_format(price_coppel[0])} quincenal ($${this.price_format(price_coppel[1])} en ${this.price_format(price_coppel[2])} quincenas*)`
                }
            }
            
            let Precio_Promocion_format = this.price_format(item.Precio_Promocion);
            let Precio_Venta_format = this.price_format(item.Precio_Venta);
            let descuento = item.Precio_Promocion == 0 || item.Precio_Venta == 0 ? 0 : Math.floor((1 - item.Precio_Promocion / item.Precio_Venta) * 100);
            let sku = item.SKU.toString();
            let SKU_RAW = sku.substr(0,sku.length -1 )
            let MP_flag = item.MP_flag && item.MP_flag == 1 ? 1 : 0;
            // link
            item.URL_producto = this.appendUTM(item.URL_producto)
            // salida
            return {...item, fec_FechaFinal, price_text, Precio_Promocion_format, Precio_Venta_format, descuento, SKU_RAW, MP_flag, index};
        },
        price_format(numb) {
            var str = numb.toString().split(".");
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");
        },
        sendForm() {
            const payload = {
                // funcion ejecutada al enviar el recibir la respuesta del formulario enviado
                callback: () => { //
                    let filtersStr = this.getFiltersFormatStr()
                    this.dataLayerSendItemsPorMenosDe(this.form, this.productsList)
                    this.dataLayerLoadedFormPorMenosDe(this.form.estado, this.form.ciudad, this.productsList.length,filtersStr, this.form.precioMaximo)
                }
            }
            this.submitForm(payload);
        },
        updateFieldIsSelectedForComparison(productsList) {
            const skusComparatorList = this.productsComparatorList.map(item => item.SKU);
            return productsList.map(item => {
                let status = skusComparatorList.includes(item.SKU)
                return {...item, isSelectedForComparison: status};
            });
        },
        handleCompararItem(item) {
            if(item && item.isSelectedForComparison) {
                this.removeProductFromComparator(item)
            } else {
                this.addProductToComparator(item)
            }
            this.$store.commit("seleccionadorPorMenosDe/SET_PRODUCTS_LIST", this.updateFieldIsSelectedForComparison(this.productsList))
        },
        handleShortenLink(item) {
            const url = item.URL_producto
            const payload = {
                data: {
                    target: url
                },
                item: item,
                callback: (link) => {
                    // this.snackbarText = `Link acortado copiado en el porta papeles`
                    // this.snackbar = true;
                    // navigator.clipboard.writeText(link);

                    // dialog
                    this.dialogFlag = true;
                    this.dialogText = link
                }
            }
            this.dialogText = ''
            this.shortenLink(payload)
        },
        handleClickActionCardItem(model, data) {
            const {action, item} = data
            switch(action) {
                case 'CLICK_BTN_LINK':
                    this.dataLayerClickItemPorMenosDe(item, this.state, this.city)
                    break;
                case 'CLICK_BTN_DESCRIPCION':
                    this.dataLayerClickCaracDescPorMenosDe('Descripción', item, this.state, this.city)
                    break;
                case 'CLICK_BTN_CARACTERISTICAS':
                    this.dataLayerClickCaracDescPorMenosDe('Características', item, this.state, this.city)
                    break;
                case 'CLICK_BTN_SHORTEND_LINK':
                    this.handleShortenLink(item)
                    this.dataLayerClickCaracDescPorMenosDe('Copiar', item, this.state, this.city )

                    break;
                case 'CLICK_BTN_COMPARAR':
                    this.handleCompararItem(item)
                    this.dataLaterClickAddToCompare('Por menos de', this.state, this.city, item)
                    break;
                default:
            }
        },
        handleClickSKU({sku, btnName, item}) {
            this.snackbarText = `SKU ${sku} Copiado en el portapapeles`
            navigator.clipboard.writeText(sku);
            this.snackbar = true;
            // datalayer
            this.dataLayerClickCardSKU('Por menos de', btnName, item, this.state, this.city)
        },
        handleChangePrecio() {
            //enviar formulario
            this.fetchSeleccionadorInputs()
            this.sendForm()
            // datalayer
            let filtersStr = this.getFiltersFormatStr()
            this.dataLayerChangePricePorMenosDe(this.state, this.city, filtersStr, this.form.precioMaximo, this.previousPrice)
        },
        handleClickPrecio() {
            //enviar formulario
            this.fetchSeleccionadorInputs()
            this.sendForm()
            // datalayer
            let filtersStr = this.getFiltersFormatStr()
            this.dataLayerClickPricePorMenosDe(this.state, this.city, filtersStr, this.form.precioMaximo)
            this.previousPrice = this.form.precioMaximo // almacenar el precio antes del onChange
        },
        handleCleanForm() {
            // datalayer
            let filtersStr = this.getFiltersFormatStr()
            this.dataLayerClickNuevaBusquedaPorMenosDe(this.state, this.city, filtersStr, this.form.precioMaximo)
            this.cleanForm() ;
            // this.cleanCompareList()
            this.fetchSeleccionadorInputs();
            this.sendForm();
        },
        handleChangeInputArea() {
            this.form.departamento = ''
            this.form.primernivel = ''
            this.form.segundonivel = ''
            this.form.tercernivel = ''
            this.form.cuartonivel = ''
            this.fetchSeleccionadorInputs()
            //enviar formulario
            this.sendForm()
            // datalayer
            this.sendDataLayerChangeFilter('Área', this.form.area)
        },
        handleChangeInputDepartamento() {
            this.form.primernivel = ''
            this.form.segundonivel = ''
            this.form.tercernivel = ''
            this.form.cuartonivel = ''
            this.fetchSeleccionadorInputs()
            //enviar formulario
            this.sendForm()
        },
        handleChangeInputPrimernivel() {
            this.form.segundonivel = ''
            this.form.tercernivel = ''
            this.form.cuartonivel = ''
            this.fetchSeleccionadorInputs()
            //enviar formulario
            this.sendForm()
            // datalayer
            this.sendDataLayerChangeFilter('Primer nivel departamental', this.form.primernivel)
        },
        handleChangeInputSegundonivel() {
            this.form.tercernivel = ''
            this.form.cuartonivel = ''
            this.fetchSeleccionadorInputs()
            //enviar formulario
            this.sendForm()
            // datalayer
            this.sendDataLayerChangeFilter('Segundo nivel departamental', this.form.segundonivel)
        },
        handleChangeInputTercernivel() {
            this.form.cuartonivel = ''
            this.fetchSeleccionadorInputs()
            //enviar formulario
            this.sendForm()
            // datalayer
            this.sendDataLayerChangeFilter('Tercer nivel departamental', this.form.tercernivel)
        },
        handleChangeInputCuartonivel() {
            //enviar formulario
            this.sendForm()
            // datalayer
            this.sendDataLayerChangeFilter('Cuarto nivel departamental', this.form.cuartonivel)
            
        },
        // clikcks
        handleClickInput(filterName) {
            let filtersStr = this.getFiltersFormatStr()
            this.dataLayerClickFiltroPorMenosDe(filterName, this.state, this.city, filtersStr, this.form.precioMaximo)
        },
        // regresa filtros en formato clave:valor;...clave:valor
        getFiltersFormatStr() {
            let filtersStr = ''
            if (this.form.area && this.form.area != '') {
                filtersStr = 'Área:'+this.form.area
            }
            if (this.form.primernivel && this.form.primernivel != '') {
                if(filtersStr != '') {
                    filtersStr = filtersStr+';'
                }
                filtersStr = filtersStr + 'Primer nivel departamental:' + this.form.primernivel
            }
            if (this.form.segundonivel && this.form.segundonivel != '') {
                if(filtersStr != '') {
                    filtersStr = filtersStr+';'
                }
                filtersStr = filtersStr + 'Segundo nivel departamental:' + this.form.segundonivel
            }
            if (this.form.tercernivel && this.form.tercernivel != '') {
                if(filtersStr != '') {
                    filtersStr = filtersStr+';'
                }
                filtersStr = filtersStr + 'Tercer nivel departamental:' + this.form.tercernivel
            }
            if (this.form.cuartonivel && this.form.cuartonivel != '') {
                if(filtersStr != '') {
                    filtersStr = filtersStr+';'
                }
                filtersStr = filtersStr + 'Cuarto nivel departamental:' + this.form.cuartonivel
            }
            return filtersStr == '' ? 'NA' : filtersStr;
        },
        sendDataLayerChangeFilter(filterKey, filterValue) {
            let filtersStr = this.getFiltersFormatStr()
            let filter = filterKey + '|' + (filterValue || 'NA')
            this.dataLayerChangeFilterPorMenosDe(filter, this.state, this.city, filtersStr, this.form.precioMaximo)
        }
    },
    mounted() {
        this.form.estado = this.state
        this.form.ciudad = this.city
        this.cleanForm();
        // this.cleanCompareList();
        if(this.form.estado && this.form.ciudad && this.form.estado !== '' && this.form.ciudad !== ''){
            this.fetchSeleccionadorInputs();
            this.sendForm();
        } else {
            // trigger datalayers mounted

            setTimeout(()=>{
                // si no se envia el formulario enviar datalayer de llegada a la página para evitar enviar este datalayer dos veces
                let filtersStr = this.getFiltersFormatStr()
                this.dataLayerLoadedFormPorMenosDe(this.city, this.state, this.productsList.length, filtersStr, this.form.precioMaximo)
            }, 2000)
        }
    },
    beforeDestroy() {
        this.cleanProductsComparator();
        this.resetSeleccionador();
    }
}
</script>
