<template  lang="pug">
    v-row(class="seleccionador")
        v-col(cols="12")
            h1( class="text-center text-h6 blue-500--text") Seleccionador: Clima y Ventilación
        v-col(cols="12")
            div(class="d-flex justify-space-between")
                v-btn(color="default" outlined x-small class="mr-5" @click="$emit('onClickReturn')")
                    v-icon(dark)  mdi-chevron-left
                    | Regresar a seleccionadores
                //- template(v-if="isComparadorVisible")
                //-     v-btn(x-small @click="switchComparador" color="primary") Regresar a la lista de productos
                //- template(v-else)
                //-     v-tooltip(top)
                //-         template(v-slot:activator="{ on, attrs }")
                //-             v-btn(x-small @click.prevent="switchComparador" color="primary" v-bind="attrs" v-on="on") Ir a comparar productos seleccionados
                //-         span Solo es posible comparar hasta 3 artículos
        template(v-if="!isComparadorVisible")
            v-col(cols="12" md="3")
                v-card(elevation="0" outlined)
                    v-card-text
                        div(class="text-left")
                            p( class="text-title font-weight-bold blue-500--text") Características del producto
                        div(class="d-flex justify-space-between mb-6")
                            v-btn(@click="handleCleanForm" x-small color="primary") Nueva busqueda
                        
                        div 

                            v-tooltip( top)
                                template(v-slot:activator='{ on, attrs }')
                                    v-btn(small @click.stop="dialogCalculator = true" color="primary" v-bind='attrs' v-on='on')
                                        v-icon(dark) mdi-calculator
                                p( class="text-caption text-justify") 
                                    | Calculadora para área de habitación
                            
                        
                        p(class="mb-4 mt-5 text-body-2") Selecciona el tipo de climatización que busca el cliente
                        v-expansion-panels(v-model='panel' multiple flat)
                            v-expansion-panel  
                                v-expansion-panel-header(class="") Tipo de climatización
                                v-expansion-panel-content
                                    v-radio-group(v-model="tipoClimatizacion" class="mt-0 mb-0 pt-0 pb-0" @change="handleChangeTipoClimatizacion")
                                        v-radio(
                                            v-for="item in tipoClimatizacionList"
                                            :key="item.value"
                                            :label="item.text"
                                            :value="item.value"
                                            class="mb-3"
                                            :disabled="disableInput")
                        //- selecciono frío -- aiac y coolers
                        template(v-if="tipoClimatizacion == 'frío'")
                            //- AIAC 
                            p(class="mb-4 mt-5 text-body-2 font-weight-bold") Aires Acondicionados/Minisplits
                            v-expansion-panels(v-model='panelAiac' multiple)
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") Marca
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formAiac.marca"
                                            :items="aiacMarcas"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Marca')"
                                            @change="handleChangeAiacMarca"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") 
                                        div(class="d-flex justify-space-between align-center")
                                            span Metros cuadrados
                                            v-tooltip( top class="d-inline")
                                                template(v-slot:activator='{ on, attrs }')
                                                    v-btn(icon color='primary' v-bind='attrs' v-on='on')
                                                        v-icon mdi-help-circle
                                                p( class="text-caption text-justify") 
                                                    |  Metros cuadrados de la habitación donde se utilizará el equipo
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formAiac.metros_cuadrados"
                                            :items="aiacMetrosCuadrados"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Metros cuadrados')"
                                            @change="handleChangeAiacMetrosCuadrados"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") 
                                        div(class="d-flex justify-space-between align-center")
                                            span Inverter
                                            v-tooltip( top class="d-inline")
                                                template(v-slot:activator='{ on, attrs }')
                                                    v-btn(icon color='primary' v-bind='attrs' v-on='on')
                                                        v-icon mdi-help-circle
                                                p( class="text-caption text-justify") 
                                                    |  Regula la velocidad del motor del compresor una vez que la habitación está fresca
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formAiac.inverter"
                                            :items="aiacInverters"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Inverter')"
                                            @change="handleChangeAiacInverter"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") Voltaje de operación
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formAiac.voltaje_operacion"
                                            :items="aiacVoltajeOperacion"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Voltaje de operación')"
                                            @change="handleChangeAiacVoltajeOperacion"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") Conectividad
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formAiac.conectividad"
                                            :items="aiacConectividad"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Conectividad')"
                                            @change="handleChangeAiacConectividad"
                                        )      
                            //- Coolers
                            p(class="mb-4 mt-5 text-body-2 font-weight-bold") Coolers
                            v-expansion-panels(v-model='panelCoolers' multiple)
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") 
                                        div(class="d-flex justify-space-between align-center")
                                            span Tipo de producto
                                            v-tooltip( top class="d-inline")
                                                template(v-slot:activator='{ on, attrs }')
                                                    v-btn(icon color='primary' v-bind='attrs' v-on='on')
                                                        v-icon mdi-help-circle
                                                p( class="text-caption text-justify") 
                                                    | Si es para uso en casa u oficina, se recomienda portátil, si es para espacios más grandes, industrial.
                                    v-expansion-panel-content
                                            v-autocomplete(
                                                v-model="formCoolers.tipo_producto"
                                                :items="coolerTiposProducto"
                                                label="Elige una opción"
                                                clearable
                                                :loading="loading"
                                                :disabled="disableInput"
                                                @click="handleClickInput('Coolers')"
                                                @change="handleChangeCoolerTipoProduto"
                                            )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") Marca
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formCoolers.marca"
                                            :items="coolerMarcas"
                                            label="Elige una opcións"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Marca')"
                                            @change="handleChangeCoolerMarcas"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="")
                                        div(class="d-flex justify-space-between align-center")
                                            span Capacidad de agua
                                            v-tooltip( top class="d-inline")
                                                template(v-slot:activator='{ on, attrs }')
                                                    v-btn(icon color='primary' v-bind='attrs' v-on='on')
                                                        v-icon mdi-help-circle
                                                p( class="text-caption text-justify") 
                                                    |  En tanques de 4 - 8 l, puede enfriar hasta 15 m2
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formCoolers.capacidad_agua"
                                            :items="coolerCapacidadAgua"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Capacidad de agua')"
                                            @change="handleChangeCoolerCapacidadAgua"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") Potencia
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formCoolers.potencia"
                                            :items="coolerPotencia"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Potencia')"
                                            @change="handleChangeCoolerPotencia"
                                        )
                        //- selecciono climatización frío-caliente
                        template(v-if="tipoClimatizacion == 'frío-caliente'")

                            //- AIAC 
                            p(class="mb-4 mt-5 text-body-2 font-weight-bold") Aires Acondicionados/Minisplits
                            v-expansion-panels(v-model='panelAiac' multiple)
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") Marca
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formAiac.marca"
                                            :items="aiacMarcas"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Marca')"
                                            @change="handleChangeAiacMarca"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") 
                                        div(class="d-flex justify-space-between align-center")
                                            span Metros cuadrados
                                            v-tooltip( top class="d-inline")
                                                template(v-slot:activator='{ on, attrs }')
                                                    v-btn(icon color='primary' v-bind='attrs' v-on='on')
                                                        v-icon mdi-help-circle
                                                p( class="text-caption text-justify") 
                                                    |  Metros cuadrados de la habitación donde se utilizará el equipo
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formAiac.metros_cuadrados"
                                            :items="aiacMetrosCuadrados"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Metros cuadrados')"
                                            @change="handleChangeAiacMetrosCuadrados"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") 
                                        div(class="d-flex justify-space-between align-center")
                                            span Inverter
                                            v-tooltip( top class="d-inline")
                                                template(v-slot:activator='{ on, attrs }')
                                                    v-btn(icon color='primary' v-bind='attrs' v-on='on')
                                                        v-icon mdi-help-circle
                                                p( class="text-caption text-justify") 
                                                    |  Regula la velocidad del motor del compresor una vez que la habitación está fresca
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formAiac.inverter"
                                            :items="aiacInverters"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Inverter')"
                                            @change="handleChangeAiacInverter"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") Voltaje de operación
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formAiac.voltaje_operacion"
                                            :items="aiacVoltajeOperacion"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Voltaje de operación')"
                                            @change="handleChangeAiacVoltajeOperacion"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") Conectividad
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formAiac.conectividad"
                                            :items="aiacConectividad"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Conectividad')"
                                            @change="handleChangeAiacConectividad"
                                        )      
                            
                        //- selecciono climatización caliente
                        template(v-if="tipoClimatizacion == 'calor'")
                            p(class="mb-4 mt-5 text-body-2 font-weight-bold") Calefacción
                            v-expansion-panels(v-model='panelCalef' multiple)
                                v-expansion-panel  
                                    v-expansion-panel-header() 
                                        div(class="d-flex justify-space-between align-center")
                                            span Tipo de calefactor
                                            v-tooltip( top class="d-inline")
                                                template(v-slot:activator='{ on, attrs }')
                                                    v-btn(icon color='primary' v-bind='attrs' v-on='on')
                                                        v-icon mdi-help-circle
                                                p( class="text-caption text-justify") 
                                                    | El calefactor eléctrico es ideal para habitaciones pequeñas <br/>  
                                                    | y uso ocasional, los modelos de gas permiten garantizar <br/>
                                                    | un clima cálido en áreas más amplias y por tiempo prolongado
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formCalef.tipo_calefactor"
                                            :items="calefTiposCalefactor"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Calefacción')"
                                            @change="handleChangeCalefTipoCalefactor"
                                        )   
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") Marca
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formCalef.marca"
                                            :items="calefMarcas"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Marca')"
                                            @change="handleChangeCalefMarcas"
                                        )
                                v-expansion-panel  
                                    v-expansion-panel-header(class="") Medidas (largo x ancho x alto)
                                    v-expansion-panel-content
                                        v-autocomplete(
                                            v-model="formCalef.medidas_largo_x_ancho_x_alto"
                                            :items="calefMedidas"
                                            label="Elige una opción"
                                            clearable
                                            :loading="loading"
                                            :disabled="disableInput"
                                            @click="handleClickInput('Medidas (largo x ancho x alto)')"
                                            @change="handleChangeCalefMedidas"
                                        )   
                    
            v-col(cols="12" md="9")
                v-row(class="align-center")
                    v-col( offset="6" sm="6" class="pt-0")
                        v-card(outlined elevation="0")
                            v-card-text(class="pt-0 pb-0 d-flex align-center")
                                v-row()
                                    v-col(class="pt-0") 
                                        p(class="mt-0 mb-0 text-captions") Precio máximo ${{price_format($store.state.seleccionadorClimaVentilacion.formPrecioMaximo)}}
                                        p(class="mt-0 mb-0 text-caption") ${{price_format($store.state.seleccionadorClimaVentilacion.formPrecioMaximo)}}
                                        v-slider(
                                            min="0" 
                                            :max="precioMaximo" 
                                            hint="precio" 
                                            hide-details 
                                            v-model="$store.state.seleccionadorClimaVentilacion.formPrecioMaximo" 
                                            :loading="productsListLoading" 
                                            :disabled="productsListLoading"
                                            @mousedown="handleMousedownPrecio"
                                            @change="handleChangePrecio")
                                    v-col
                                        v-select(
                                            label="Ordenar por:"
                                            :disabled="productsList.length == 0"
                                            v-model="$store.state.seleccionadorClimaVentilacion.orden"
                                            :items="orderByItems"
                                            @click="handleClickOrderBy"
                                            @change="handleChangeOrderBy"
                                            clearable
                                        )

                v-card(outlined elevation="0")
                    v-card-text 
                        div(class="d-flex justify-center" v-if="productsListLoading")
                            v-progress-circular(indeterminate color="primary")
                        template( v-else-if="!productsListLoading && productsList.length > 0")
                            div(class="d-flex flex-wrap")
                                CardItem( 
                                    v-for="item, index in itemsTransformedList" 
                                    :item="item" 
                                    :key="item.SKU+index" 
                                    @clickSKU="handleClickSKU"
                                    activateComparator
                                    enableShortenLink
                                    @clickAction="(data) => handleClickActionCardItem('Seleccionador: Clima y Ventilación', data)"
                                    class="d-inline-block")
                            //- div(class="mt-8") 
                            //-     v-pagination(v-model="pageNumber" :length="paginationLength" @input="handleInputPaginator" :total-visible="10")
                        p(class="text-center" v-else) Lo sentimos, tu búsqueda no arrojó resultados, <br> puedes modificar la disponibilidad por estado o ciudad o probar con otros filtros
        v-col(sm="12")
            TablaComparativaDeProductos(
                class="w-100"
                v-if="isComparadorVisible" 
                :items="productsComparadorList"
                :state="state"
                :city="city"
                @onRemoveItem="removeProductFromCompareList"
                )
        //- 
        v-snackbar(v-model="snackbar" :timeout="timeout") {{ snackbarText }}
            template(v-slot:action="{ attrs }")
                v-btn(
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                ) Cerrar

        v-btn( color="primary" small dark fixed bottom right v-if="isComparadorVisible" @click="handleClickRegresarAComparador") <v-icon size="15" class="mr-1">mdi-keyboard-return</v-icon> Regresar
        v-btn( color="purple" small dark fixed bottom right v-else @click.prevent="handleClickComparador") <v-icon size="15" class="mr-1">mdi-select-compare</v-icon> comparador

        //- Calculator
        v-dialog(v-model="dialogCalculator" max-width="300")
            v-card
                v-card-title(class="text-h7") Calcular Área m <sup>2</sup>
                v-card-text 
                    ValidationObserver(ref='observerCalcularArea')                

                        form(action="#" @submit.prevent="calcularArea")
                            v-container
                                v-row 
                                    v-col(class="pt0-0 pb-0")
                                        validation-provider(v-slot="{ errors }" name="Base" rules="required")
                                            v-text-field(v-model="areaBase" label="Base (m)" type="text" clearable :error-messages="errors")
                                
                                    v-col 
                                        validation-provider(v-slot="{ errors }" name="Altura" rules="required")
                                            v-text-field(v-model="areaAltura" label="Altura (m)" type="text" clearable :error-messages="errors")
                                v-row 
                                    v-col
                                        | Área = {{areaResult}} mts.

                                v-row 
                                    v-col 
                                        v-btn(small color="primary" block type="submit") calcular

        //- dialog acortador links
        v-dialog(
            v-model="dialogFlag"
            max-width="300"
        )
            v-card
                v-card-title(class="text-h6") Copia el link
                v-card-text {{ dialogText }}
                v-card-actions
                    v-spacer
                    v-btn(text @click="dialogFlag = false") Cerrar

</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import CardItem from './CardItem';
import TablaComparativaDeProductos from './TablaComparativaDeProductos';
import DataLayersSeleccionadoresMixin from '@/mixins/DataLayersSeleccionadoresMixin'
import DataLayersComparadorMixin from '@/mixins/DataLayersComparadorMixin'

export default {
    name: 'SeleccionadorProductoClimaVentilacion',
    props: ['city', 'state'],
    mixins: [DataLayersSeleccionadoresMixin, DataLayersComparadorMixin],
    components: {
        CardItem,
        TablaComparativaDeProductos
    },
    data() {
        return {
            panel: [0],
            panelAiac: [0,1,2,3,4],
            panelCoolers: [0,1,2,3],
            panelCalef: [0,1,2,3],
            seleccionador: '',
            tipoClimatizacion: '',
            dialogCalculator: false,
            areaResult: 0.0,
            areaBase: 0.0,
            areaAltura: 0.0,
            tipoClimatizacionList: [
                {text:"frío",value:"frío"},
                {text:"frío-caliente",value:"frío-caliente"},
                {text:"caliente",value:"calor"},
            ],
            orderByItems: [
                {text:"menor precio", value:"asc"},
                {text:"mayor precio", value:"des"},
            ],
            isComparadorVisible: false,
            snackbarText: '',
            snackbar: false,
            timeout: 2000,

            // dialog
            dialogFlag: false,
            // dialogTitle: '',
            dialogText: '',
            //
            precioMaximoPrevio: 0,
            delayTimerPrice: null,
            ordenarPorPrev: ''
        }
    },
    watch: {
        city(value) {
            this.$store.state.seleccionadorClimaVentilacion.ciudad = value
            if(this.$store.state.seleccionadorClimaVentilacion.estado && this.$store.state.seleccionadorClimaVentilacion.ciudad) {
                // refrescar inputs
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
                this.fetchInputsSeleccionadorCalef()
                switch(this.seleccionador) {
                    case 'AIAC':
                        this.sendForm('AIAC')
                        break;
                    case 'COOLERS':
                        this.sendForm('COOLERS')
                        break;
                    case 'CALEF':
                        this.sendForm('CALEF')
                        break;
                    default:
                }
            }
        },
        state(value) {
            this.$store.state.seleccionadorClimaVentilacion.estado = value
            if(this.$store.state.seleccionadorClimaVentilacion.estado && this.$store.state.seleccionadorClimaVentilacion.ciudad) {
                // refrescar inputs
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
                this.fetchInputsSeleccionadorCalef()
                switch(this.seleccionador) {
                    case 'AIAC':
                        this.sendForm('AIAC')
                        break;
                    case 'COOLERS':
                        this.sendForm('COOLERS')
                        break;
                    case 'CALEF':
                        this.sendForm('CALEF')
                        break;
                    default:
                }
            }
        }
    },
    computed: {
        ...mapGetters('session', [
            'userNoEmpleado',
            'userTipoVenta',
        ]),
        ...mapState({
            // aiac
            formAiac: state => state.seleccionadorClimaVentilacion.formAiac,
            aiacMarcas: state => state.seleccionadorClimaVentilacion.aiacMarcas,
            aiacMetrosCuadrados: state => state.seleccionadorClimaVentilacion.aiacMetrosCuadrados,
            aiacInverters: state => state.seleccionadorClimaVentilacion.aiacInverters,
            aiacVoltajeOperacion: state => state.seleccionadorClimaVentilacion.aiacVoltajeOperacion,
            aiacConectividad: state => state.seleccionadorClimaVentilacion.aiacConectividad,
            aiacInputsDataLoading: state => state.seleccionadorClimaVentilacion.aiacInputsDataLoading,
            // calef
            formCalef: state => state.seleccionadorClimaVentilacion.formCalef,
            calefTiposCalefactor: state => state.seleccionadorClimaVentilacion.calefTiposCalefactor,
            calefMarcas: state => state.seleccionadorClimaVentilacion.calefMarcas,
            calefMedidas: state => state.seleccionadorClimaVentilacion.calefMedidas,
            calefInputsDataLoading: state => state.seleccionadorClimaVentilacion.calefInputsDataLoading,
            // coolers
            formCoolers: state => state.seleccionadorClimaVentilacion.formCoolers,
            coolerTiposProducto: state => state.seleccionadorClimaVentilacion.coolerTiposProducto,
            coolerMarcas: state => state.seleccionadorClimaVentilacion.coolerMarcas,
            coolerCapacidadAgua: state => state.seleccionadorClimaVentilacion.coolerCapacidadAgua,
            coolerPotencia: state => state.seleccionadorClimaVentilacion.coolerPotencia,
            coolerInputsDataLoading: state => state.seleccionadorClimaVentilacion.coolerInputsDataLoading,
            //
            precioMaximo: state => state.seleccionadorClimaVentilacion.precioMaximo,
            productsList: state => state.seleccionadorClimaVentilacion.productsList,
            productsListLoading: state => state.seleccionadorClimaVentilacion.productsListLoading,
            productsComparadorList: state => state.seleccionadorClimaVentilacion.productsComparadorList,
        }),
        disableInput() {
            return this.productsListLoading || 
            this.aiacInputsDataLoading || 
            this.calefInputsDataLoading || 
            this.coolerInputsDataLoading || 
            !this.city || 
            !this.state
        },
        loading() {
            return this.productsListLoading || 
            this.aiacInputsDataLoading || 
            this.calefInputsDataLoading || 
            this.coolerInputsDataLoading 
        },
        itemsTransformedList() {
            return this.productsList.map(this.transform_data) || [];
        },
    },
    methods: {
        ...mapActions('seleccionadorClimaVentilacion', [
            'fetchInputsSeleccionadorAiac',
            'fetchInputsSeleccionadorCalef',
            'fetchInputsSeleccionadorCoolers',
            'submitFormAiac',
            'submitFormCoolers',
            'submitFormCalef',
            'removeProductFromCompareList',
            'addIProductToCompareList',
            'cleanCompareList',
            'cleanForm',
            'resetSeleccionador',
            'shortenLink'
        ]),
        handleClickComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
            // datalayer
            this.dayaLayerClickComparador("Seleccionador Clima y Ventilación", this.state, this.city)
        },
        handleClickRegresarAComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
            //datalayer
            this.dataLayerClickRegresarAComparador(this.state, this.city)
        },
        appendUTM(link) {
            if (this.userTipoVenta == null || this.userTipoVenta == '' || this.userNoEmpleado == null || this.userNoEmpleado == ''  ) {
                return link
            }
            let utm_source = 'cat';
            let utm_medium = 'vt';
            if (this.userTipoVenta == 'ASISTIDA') {
                utm_source = 'ven-asist-wpp';
                utm_medium = 'referido';
            }
            return `${link}?utm_campaign=interfaz-cat-ad&utm_medium=${utm_medium}&utm_source=${utm_source}&utm_content=${this.userNoEmpleado}`
        },
        transform_data(item, index) {
            // fecha
            let fec_FechaFinal = item.fec_FechaFinal != 'NaT' && item.fec_FechaFinal != null ? item.fec_FechaFinal.split('T')[0] : null;
            if (fec_FechaFinal) {
                fec_FechaFinal = fec_FechaFinal.split('-')
                if(Array.isArray(fec_FechaFinal) && fec_FechaFinal.length == 3) {
                    fec_FechaFinal = fec_FechaFinal[2] +'/' + fec_FechaFinal[1] + '/' + fec_FechaFinal[0];
                }   
            }
            // leyenda precio
            var price_text = '';
            if(item.price_coppel && typeof item.price_coppel == 'object') {
                price_text = `Cómpralo con crédito Coppel desde $${this.price_format(item.price_coppel.pago_quincenal)} quincenal ($${this.price_format(item.price_coppel.precio_contado)} en ${this.price_format(item.price_coppel.qty_quincenas)} quincenas*)`
            } else {
                let price_coppel = item.price_coppel && item.price_coppel != '' && item.price_coppel !== null ? item.price_coppel.split('/') : [];
                if(price_coppel.length == 3) {
                    price_text = `Cómpralo con crédito Coppel desde $${this.price_format(price_coppel[0])} quincenal ($${this.price_format(price_coppel[1])} en ${this.price_format(price_coppel[2])} quincenas*)`
                }
            }
            let Precio_Promocion_format = this.price_format(item.Precio_Promocion);
            let Precio_Venta_format = this.price_format(item.Precio_Venta);
            let descuento = item.Precio_Promocion == 0 || item.Precio_Venta == 0 ? 0 : Math.floor((1 - item.Precio_Promocion / item.Precio_Venta) * 100);
            let sku = item.SKU.toString();
            let SKU_RAW = sku.substr(0,sku.length -1 )
            let MP_flag = item.MP_flag && item.MP_flag == 1 ? 1 : 0;
            // link
            item.URL_producto = this.appendUTM(item.URL_producto)
            // salida
            return {...item, fec_FechaFinal, price_text, Precio_Venta_format, Precio_Promocion_format, descuento, SKU_RAW, MP_flag, index};
        },
        price_format(numb) {
            var str = numb.toString().split(".");
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");
        },
        sendForm(form) {
            switch(form) {
                case 'AIAC':
                    this.submitFormAiac(()=>{
                        // send datalayer
                        this.dataLayerSeleccionadorItems('Clima y Ventilación', this.state, this.city, this.productsList)
                    });
                    break;
                case 'COOLERS':
                    this.submitFormCoolers(()=>{
                        // send datalayer
                        this.dataLayerSeleccionadorItems('Clima y Ventilación', this.state, this.city, this.productsList)
                    });
                    break;
                case 'CALEF':
                    this.submitFormCalef(()=>{
                        // send datalayer
                        this.dataLayerSeleccionadorItems('Clima y Ventilación', this.state, this.city, this.productsList)
                    });
                    break;
                default:
                    return
            }
            // sin resultados
            if (this.productsList.length == 0) {
                let orden = this.$store.state.seleccionadorClimaVentilacion.orden == 'Ninguno' ? '' : this.$store.state.seleccionadorClimaVentilacion.orden;
                if (orden == 'asc') orden = 'menor precio';
                if (orden == 'des') orden = 'mayor precio';
                let filtersToString = this.getFiltersToString();
                this.dataLayerSeleccionadorNoItems('Clima y Ventilación', this.state, this.city, filtersToString, this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo, orden)
            }
        },
        handleClickOrderBy() {
            // datalayer
            let orden = this.$store.state.seleccionadorClimaVentilacion.orden == 'Ninguno' ? '' : this.$store.state.seleccionadorClimaVentilacion.orden;
            if (orden == 'asc') orden = 'menor precio';
            if (orden == 'des') orden = 'mayor precio';
            let filtersToString = this.getFiltersToString();
            this.dataLayerClickOrdenarPor('Clima y Ventilación', this.state, this.city, filtersToString, this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo, orden)
            this.ordenarPorPrev = orden
        },
        handleChangeOrderBy() {
            if(this.$store.state.seleccionadorClimaVentilacion.orden == undefined) {
                this.$store.state.seleccionadorClimaVentilacion.orden = 'Ninguno'
            }
            switch(this.seleccionador) {
                case 'AIAC':
                    this.sendForm('AIAC')
                    break;
                case 'COOLERS':
                    this.sendForm('COOLERS')
                    break;
                case 'CALEF':
                    this.sendForm('CALEF')
                    break;
                default:
            }
            // datalayer
            let orden = this.$store.state.seleccionadorClimaVentilacion.orden;
            if (orden == 'Ninguno') orden = '';
            if (orden == 'asc') orden = 'menor precio';
            if (orden == 'des') orden = 'mayor precio';
            let filtersToString = this.getFiltersToString();
            // this.sendDataLayerChangeFilter('Ordenar Por', orden)
            this.dataLayerChangeOrdenarPor('Clima y Ventilación', this.state, this.city, orden, this.ordenarPorPrev, this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo, filtersToString);
        },
        handleCompararItem(item) {
            if(item && item.isSelectedForComparison) {
                this.removeProductFromCompareList(item)
            } else {
                this.addIProductToCompareList(item)
            }
        },
        handleShortenLink(item) {
            const url = item.URL_producto
            const payload = {
                data: {
                    target: url
                },
                item: item,
                callback: (link) => {
                    // this.snackbarText = `Link acortado copiado en el porta papeles`
                    // this.snackbar = true;
                    // navigator.clipboard.writeText(link);

                    // dialog
                    this.dialogFlag = true;
                    this.dialogText = link
                }
            }
            this.shortenLink(payload)
        },
        handleClickActionCardItem(model, data) {
            const {action, item} = data
            switch(action) {
                case 'CLICK_BTN_LINK':
                    this.dataLayerSeleccionadorClickProduct('Clima y Ventilación', item, this.state, this.city);
                    break;
                case 'CLICK_BTN_SHORTEND_LINK':
                    this.handleShortenLink(item)
                    this.dataLayerClickBtnsItemCard('Clima y Ventilación', 'Copiar enlace', item.SKU, item.Nombre) 
                    break;
                case 'CLICK_BTN_COMPARAR':
                    this.handleCompararItem(item)
                    this.dataLaterClickAddToCompare('Seleccionador Clima y Ventilación', this.state, this.city, item)
                    break;
                case 'CLICK_BTN_DESCRIPCION':
                    this.dataLayerClickBtnsItemCard('Clima y Ventilación', 'Descripción', item.SKU, item.Nombre)
                    break;
                case 'CLICK_BTN_CARACTERISTICAS':
                    this.dataLayerClickBtnsItemCard('Clima y Ventilación', 'Características', item.SKU, item.Nombre)
                    break;
                default:
            }
        },
        handleClickSKU({sku, btnName, item}) {
            this.snackbarText = `SKU ${sku} Copiado en el portapapeles`
            navigator.clipboard.writeText(sku);
            this.snackbar = true;
            // Datalayer
            this.dataLayerClickCardSKUSeleccionadores('Seleccionadores de productos', btnName, item, this.state, this.city)
        },
        switchComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
        },
        handleChangeTipoClimatizacion() {
            this.seleccionador = ''
            this.formAiac.marca = ''
            this.formAiac.metros_cuadrados = ''
            this.formAiac.inverter = ''
            this.formAiac.voltaje_operacion = ''
            this.formAiac.conectividad = ''        
            this.formCoolers.tipo_producto = ''
            this.formCoolers.marca = []
            this.formCoolers.capacidad_agua = ''
            this.formCoolers.potencia = ''
            this.formCalef.tipo_calefactor = ''
            this.formCalef.marca = ''
            this.formCalef.medidas_largo_x_ancho_x_alto = ''
            this.cleanCompareList();
            if(this.tipoClimatizacion == 'frío') {
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
                this.sendForm('AIAC')
            } else if (this.tipoClimatizacion == 'frío-caliente') {
                this.fetchInputsSeleccionadorAiac()
                this.sendForm('AIAC')
            } else if(this.tipoClimatizacion == 'calor') {
                this.fetchInputsSeleccionadorCalef()
                this.sendForm('CALEF')
            }
            // datalayer
            this.sendDataLayerChangeFilter('Tipo de climatización', this.tipoClimatizacion);
            let filtersStr = this.getFiltersToString()
            let order = this.$store.state.seleccionadorClimaVentilacion.orden == 'Ninguno' ? '' : this.$store.state.seleccionadorClimaVentilacion.orden;
            if (order == 'asc') order = 'menor precio';
            if (order == 'des') order = 'mayor precio';
            this.dataLayerSeleccionadorClickFilter('Clima y Ventilación', 'Tipo de climatización', this.state, this.city, this.productsList.length, filtersStr, this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo, order)
        },
        // AIAC
        handleChangeAiacMarca() {
            this.seleccionador = 'AIAC'
            // aiac
            this.formAiac.metros_cuadrados = ''
            this.formAiac.inverter = ''
            this.formAiac.voltaje_operacion = ''
            this.formAiac.conectividad = ''  
            // coolers           
            this.formCoolers.tipo_producto = ''
            this.formCoolers.marca = ''
            this.formCoolers.capacidad_agua = ''
            this.formCoolers.potencia =  ''
            //
            if(this.tipoClimatizacion == 'frío') {
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
            } else if (this.tipoClimatizacion == 'frío-caliente') {
                this.fetchInputsSeleccionadorAiac()
            } 
            this.sendForm('AIAC')
            // datalayer
            this.sendDataLayerChangeFilter('Marca', this.formAiac.marca);
        },
        handleChangeAiacMetrosCuadrados() {
            this.seleccionador = 'AIAC'
            // aiac
            this.formAiac.inverter = ''
            this.formAiac.voltaje_operacion = ''
            this.formAiac.conectividad = ''  
            // coolers           
            this.formCoolers.tipo_producto = ''
            this.formCoolers.marca = ''
            this.formCoolers.capacidad_agua = ''
            this.formCoolers.potencia = ''

            if(this.tipoClimatizacion == 'frío') {
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
            } else if (this.tipoClimatizacion == 'frío-caliente') {
                this.fetchInputsSeleccionadorAiac()
            } 
            this.sendForm('AIAC');
            // datalayer
            this.sendDataLayerChangeFilter('Metros cuadrados', this.formAiac.metros_cuadrados);
        },
        handleChangeAiacInverter() {
            this.seleccionador = 'AIAC'
            // aiac
            this.formAiac.voltaje_operacion = ''
            this.formAiac.conectividad = ''
            // coolers           
            this.formCoolers.tipo_producto = ''
            this.formCoolers.marca = ''
            this.formCoolers.capacidad_agua = ''
            this.formCoolers.potencia = ''

            if(this.tipoClimatizacion == 'frío') {
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
            } else if (this.tipoClimatizacion == 'frío-caliente') {
                this.fetchInputsSeleccionadorAiac()
            } 
            this.sendForm('AIAC');
            // datalayer
            this.sendDataLayerChangeFilter('Inverter', this.formAiac.inverter);
        },
        handleChangeAiacVoltajeOperacion() {
            this.seleccionador = 'AIAC'
            // aiac
            this.formAiac.conectividad = ''  
            // coolers           
            this.formCoolers.tipo_producto = ''
            this.formCoolers.marca = ''
            this.formCoolers.capacidad_agua = ''
            this.formCoolers.potencia = ''

            if(this.tipoClimatizacion == 'frío') {
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
            } else if (this.tipoClimatizacion == 'frío-caliente') {
                this.fetchInputsSeleccionadorAiac()
            } 
            this.sendForm('AIAC')
            // datalayer
            this.sendDataLayerChangeFilter('Voltaje de operación', this.formAiac.voltaje_operacion);
        },
        handleChangeAiacConectividad() {   
            this.seleccionador = 'AIAC'    
            // coolers           
            this.formCoolers.tipo_producto = ''
            this.formCoolers.marca = ''
            this.formCoolers.capacidad_agua = ''
            this.formCoolers.potencia = ''
            //
            this.sendForm('AIAC')
            // datalayer
            this.sendDataLayerChangeFilter('Conectividad', this.formAiac.conectividad);
        },
        // Coolers
        handleChangeCoolerTipoProduto() {
            this.seleccionador = 'COOLERS'
            // aiac
            this.formAiac.marca = ''
            this.formAiac.metros_cuadrados = ''
            this.formAiac.inverter = ''
            this.formAiac.voltaje_operacion = ''
            this.formAiac.conectividad = ''              
            // coolers    
            this.formCoolers.marca = ''
            this.formCoolers.capacidad_agua = ''
            this.formCoolers.potencia = ''
            //
            if(this.tipoClimatizacion == 'frío') {
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
            } 
            this.sendForm('COOLERS');
            // datalayer
            this.sendDataLayerChangeFilter('Tipo de producto', this.formCoolers.tipo_producto);
        },
        handleChangeCoolerMarcas() {
            this.seleccionador = 'COOLERS'
            // aiac
            this.formAiac.marca = ''
            this.formAiac.metros_cuadrados = ''
            this.formAiac.inverter = ''
            this.formAiac.voltaje_operacion = ''
            this.formAiac.conectividad = ''              
            // coolers    
            this.formCoolers.capacidad_agua = ''
            this.formCoolers.potencia = ''
            //
            if(this.tipoClimatizacion == 'frío') {
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
            } 
            this.sendForm('COOLERS');
            // datalayer
            this.sendDataLayerChangeFilter('Marca', this.formCoolers.marca);
        },
        handleChangeCoolerCapacidadAgua() {
            this.seleccionador = 'COOLERS'
            // aiac
            this.formAiac.marca = ''
            this.formAiac.metros_cuadrados = ''
            this.formAiac.inverter = ''
            this.formAiac.voltaje_operacion = ''
            this.formAiac.conectividad = ''              
            // coolers    
            this.formCoolers.potencia = ''
            //
            if(this.tipoClimatizacion == 'frío') {
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
            } 
            this.sendForm('COOLERS');
            // datalayer
            this.sendDataLayerChangeFilter('Capacidad de agua', this.formCoolers.capacidad_agua);
        },
        handleChangeCoolerPotencia() {
            this.seleccionador = 'COOLERS'
            // aiac
            this.formAiac.marca = ''
            this.formAiac.metros_cuadrados = ''
            this.formAiac.inverter = ''
            this.formAiac.voltaje_operacion = ''
            this.formAiac.conectividad = ''              
            // coolers
            //
            if(this.tipoClimatizacion == 'frío') {
                this.fetchInputsSeleccionadorAiac()
                this.fetchInputsSeleccionadorCoolers()
            } 
            this.sendForm('COOLERS');
            // datalayer
            this.sendDataLayerChangeFilter('Potencia', this.formCoolers.potencia);
        },
        // Calef
        handleChangeCalefTipoCalefactor() {
            this.seleccionador = 'CALEF'
            this.formCalef.marca = ''
            this.formCalef.medidas_largo_x_ancho_x_alto = []
            this.fetchInputsSeleccionadorCalef()
            this.sendForm('CALEF');
            // datalayer
            this.sendDataLayerChangeFilter('Calefacción', this.formCalef.tipo_calefactor);
        },
        handleChangeCalefMarcas() {
            this.seleccionador = 'CALEF'
            this.formCalef.medidas_largo_x_ancho_x_alto = []
            this.fetchInputsSeleccionadorCalef()
            this.sendForm('CALEF');
            // datalayer
            this.sendDataLayerChangeFilter('Marca', this.formCalef.marca);
        },
        handleChangeCalefMedidas() {
            this.seleccionador = 'CALEF'
            this.sendForm('CALEF')
            // datalayer
            this.sendDataLayerChangeFilter(' Medidas (largo x ancho x alto)', this.formCalef.medidas_largo_x_ancho_x_alto);
        },
        handleMousedownPrecio() {
            this.precioMaximoPrevio = this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo
        },
        handleChangePrecio() {
            // Cancelar el temporizador si ya existe
            clearTimeout(this.delayTimerPrice);
            // Configurar un nuevo temporizador para ejecutar la función después de un cierto tiempo
            this.delayTimerPrice = setTimeout(() => {
                if(this.seleccionador == 'AIAC') {
                    this.sendForm('AIAC')
                } else if (this.seleccionador == 'COOLERS') {
                    this.sendForm('COOLERS')
                } else if(this.seleccionador == 'CALEF') {
                    this.sendForm('CALEF')
                }
                // datalayer
                this.sendDataLayerChangePrice();
            }, 1000);
        },
        handleCleanForm() {
            // datalayer
            let filtersToString = this.getFiltersToString();
            let orden = this.$store.state.seleccionadorClimaVentilacion.orden == 'Ninguno' ? '' : this.$store.state.seleccionadorClimaVentilacion.orden;
            if (orden == 'asc') orden = 'menor precio';
            if (orden == 'des') orden = 'mayor precio';
            this.dataLayerSeleccionadorClickNuevaBusqueda('Clima y Ventilación', this.state, this.city, filtersToString, this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo, orden); // envíar datalater
            //
            this.cleanForm() ;
            this.cleanCompareList()
            this.fetchInputsSeleccionadorAiac()
            this.fetchInputsSeleccionadorCoolers()
            this.fetchInputsSeleccionadorCalef()

            // poner frio por defecto
            this.tipoClimatizacion = '';
            this.sendForm('AIAC');
        },
        calcularArea() {
            this.$refs.observerCalcularArea.validate().then( async res=> {

                let base = parseFloat(this.areaBase)
                let altura = parseFloat(this.areaAltura)

                if(res && typeof base == 'number' && typeof altura == 'number'){
                    this.areaResult = parseFloat(base) * parseFloat(altura)
                } else {
                    this.areaResult = 0
                }
            });
        },
        // datalayers methods

        /**
         *  Retorna string con filtros en formato clave:valor;...clave:valor
         */
         getFiltersToString() {
            var filtersStr = ''
            var fields = [];
            var form = {}
            switch(this.seleccionador) {
                case 'AIAC':
                    fields = [
                        {label: 'Marca', field: 'marca'},
                        {label: 'Metros cuadrados', field: 'metros_cuadrados'},
                        {label: 'Inverter', field: 'inverter'},
                        {label: 'Voltaje de operación', field: 'voltaje_operacion'},
                        {label: 'Conectividad', field: 'conectividad'},
                    ];
                    form = this.formAiac;
                    break;
                case 'COOLERS':
                    fields = [
                        {label: 'Tipo de producto', field: 'tipo_producto'},
                        {label: 'Marca', field: 'marca'},
                        {label: 'Capacidad de agua', field: 'capacidad_agua'},
                        {label: 'Potencia', field: 'potencia'},
                    ];
                    form = this.formCoolers;
                    break;
                case 'CALEF':
                    fields = [
                        {label: 'Tipod de calefactor', field: 'tipo_calefactor'},
                        {label: 'Marca', field: 'marca'},
                        {label: 'Medidas (largo x ancho x alto)', field: 'medidas_largo_x_ancho_x_alto'}
                    ];
                    form = this.formCalef;
                    break;
                default:
            }
            for (const item of fields) {
                if (form[item['field']] && form[item['field']] != '') {
                    if(filtersStr != '') {
                        filtersStr = filtersStr+';'
                    }
                    filtersStr = filtersStr + item['label'] + ':' + form[item['field']];
                }
            }
            return filtersStr == '' ? 'NA' : filtersStr;
        },
        handleClickInput(filterName) {
            let filtersStr = this.getFiltersToString()
            let order = this.$store.state.seleccionadorClimaVentilacion.orden == 'Ninguno' ? '' : this.$store.state.seleccionadorClimaVentilacion.orden;
            if (order == 'asc') order = 'menor precio';
            if (order == 'des') order = 'mayor precio';
            this.dataLayerSeleccionadorClickFilter('Clima y Ventilación', filterName, this.state, this.city, this.productsList.length, filtersStr, this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo, order)
        },
        sendDataLayerChangeFilter(filterKey, filterValue) {
            let filtersToString = this.getFiltersToString()
            let filter = filterKey + '|' + (filterValue || 'NA') 
            let orden = this.$store.state.seleccionadorClimaVentilacion.orden == 'Ninguno' ? '' : this.$store.state.seleccionadorClimaVentilacion.orden;
            if (orden == 'asc') orden = 'menor precio';
            if (orden == 'des') orden = 'mayor precio';
            this.dataLayerSeleccionadorChangeFilter('Clima y Ventilación', filter, this.state, this.city, filtersToString, this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo, orden)
        },
        sendDataLayerChangePrice() {
            // this.sendDataLayerChangeFilter('Precio máximo', $store.state.seleccionadorClimaVentilacion.formPrecioMaximo)>
            let filtersToString = this.getFiltersToString();
            let orden = this.$store.state.seleccionadorClimaVentilacion.orden == 'Ninguno' ? '' : this.$store.state.seleccionadorClimaVentilacion.orden
            if (orden == 'asc') orden = 'menor precio';
            if (orden == 'des') orden = 'mayor precio';
            this.dataLayerChangePrecioMaximo('Clima y Ventilación', this.state, this.city, this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo, this.precioMaximoPrevio, filtersToString, orden)
        }
    },
    mounted() {
        this.$store.state.seleccionadorClimaVentilacion.estado = this.state
        this.$store.state.seleccionadorClimaVentilacion.ciudad = this.city
        this.cleanForm();
        this.cleanCompareList();

        setTimeout(()=>{
            let filtersStr = this.getFiltersToString()
            let order = this.$store.state.seleccionadorClimaVentilacion.orden == 'Ninguno' ? '' : this.$store.state.seleccionadorClimaVentilacion.orden;
            if (order == 'asc') order = 'menor precio';
            if (order == 'des') order = 'mayor precio';
            if(this.productsList.length > 0) {
                this.dataLayerLoadedSeleccionador('Clima y Ventilación', this.state, this.city, this.productsList.length, filtersStr, this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo, order)
            } else {
                this.dataLayerSeleccionadorNoItems('Clima y Ventilación', this.state, this.city, filtersStr, this.$store.state.seleccionadorClimaVentilacion.formPrecioMaximo, order)
            }
        }, 2000);
    },
    beforeDestroy() {
        this.resetSeleccionador();
    }
}
</script>
