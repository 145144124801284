<template>

    <v-card
        class="ma-2 item-carrusel-2"
        width="220"
    >
        <v-row
        class="fill-height"
        justify="center"
        >
			<v-col class="py-0">
				<!-- <a :href="item.URL_producto" target="_blank" class="text-decoration-none"> -->
					<div class="seccion-imagen">
						<a :href="item.URL_producto" target="_blank" class="text-decoration-none" @click="handleClickLink">
							<v-img
								contain
								width="auto"
								height="137px"
								position="center center"
								class="text-right"
								:src="item.URL_imagen">
								<div class="d-flex" :class="enableLike ? 'justify-space-between' : 'justify-end'">
									<div v-if="enableLike">
										<v-btn
											dark 
											fab
											icon
											x-small
											color="secondary"
											@click.prevent="handleClickLike('LIKE')"
											class="mt-2 ml-2 mr-1"
										>
											<v-icon v-if="item.like == 1">mdi-thumb-up</v-icon>
											<v-icon v-else>mdi-thumb-up-outline</v-icon>
										</v-btn>
										<v-btn
											dark 
											fab
											icon
											x-small 
											color="secondary"
											@click.prevent="handleClickLike('DISLIKE')"
											class="mt-2"
										>
											<v-icon v-if="item.like == 0">mdi-thumb-down</v-icon>
											<v-icon v-else>mdi-thumb-down-outline</v-icon>
										</v-btn>
									</div>
									<div v-if="activateComparator">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													:color="item.isSelectedForComparison?'purple': 'secondary'"
													dark 
													fab
													:icon="!item.isSelectedForComparison"
													x-small 
													@click.prevent="clickCompararItem" v-bind="attrs" v-on="on"
													class="mt-2 mr-2"
												>
													<v-icon>mdi-select-compare</v-icon>
												</v-btn>
											</template>
											<span>Clic para agregar el producto al comparador</span>
										</v-tooltip>
									</div>

								</div>
							</v-img>
						</a>
						<v-divider></v-divider>
						<div class="sku-wrapper d-flex justify-space-between">
							<p class="sku-label" @click="clickSKU">código: {{item.SKU}}</p>
							<p class="sku-label" @click="clickSKU">sku: {{item.SKU_RAW}}</p>
						</div>
						<div class="RibbonAdDefault ribbonTop Rebaja" v-if="item.Precio_Promocion != 0">
							<span class="RibbonAdDefault Rebaja">Oferta</span>
						</div>
						<div class="ribbonBottom" v-if="item.flag_exclusivoecommerce == 1">
							<span>Exclusivo en línea</span>
						</div>
					</div>

					<!-- Defalut -->
					<div class="seccion-caracteristicas text-center">
						
						<p class="data-nombre mb-2">
							{{item.Nombre}}
							<!-- <a :href="item.URL_producto" target="_blank" class="text-decoration-none">{{item.Nombre}}</a> -->
						</p>
						
						<p class="mb-2">Precio contado</p>
						<div v-if="item.Precio_Promocion != 0" class="wrapper-prices">
							<p class="rebaja-precio-normal">${{item.Precio_Venta_format}}</p>
							<div class="d-inline-block">
								<p class="rebaja-precio-final">${{item.Precio_Promocion_format}}</p>
							</div>
							<div class="d-inline-block font-weight-bold wrapper-discount" v-if="item.Precio_Promocion_format && item.Precio_Promocion_format != 0">
								<!-- <v-chip x-small color="green" dark >-{{Math.floor(item.porc_descuento * 100)}}%</v-chip> -->
								<div class="discount-ribbon">
									<span>-{{item.descuento}}%</span>
								</div>


								<!-- <div class="discount-ribbon" v-if="item.porc_descuento">
									<span>-{{Math.floor((1 - item.porc_descuento) * 100)}}%</span>
								</div> -->
							</div>
						</div>
						<div v-else>
							<p class="default">${{item.Precio_Venta_format}}</p>
						</div>
						<p v-if="item.price_text != ''" class="price-text  mb-1">{{item.price_text}}</p>
						<p v-if="item.fec_FechaFinal" class="vigencia mb-1">Vigencia: {{item.fec_FechaFinal}}</p>
					</div>

					<div class="seccion-footer d-flex pl-3 pr-3 pb-2 " :class="[item.MP_flag == 1 || item.Similar_flag == 1 ? 'justify-space-between' : 'justify-center']">
						<div class="icons-bottom-wrapper d-flex">
							<!-- icon Marketplace -->
							<div v-if="item.MP_flag == 1" class="wrapper-ic_bag d-flex">
								<svg class="mr-1" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<!-- Generator: Sketch 58 (84663) - https://sketch.com -->
									<title>Marketplace</title>
									<desc>Created with Sketch.</desc>
									<g id="Icon-Marketplace" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<g id="Group-5" transform="translate(-1.000000, 0.000000)">
											<rect id="Rectangle" x="0" y="0" width="29" height="28"></rect>
											<g id="Icon-Marketplace" transform="translate(5.000000, 2.000000)" fill-rule="nonzero">
												<path d="M14.5294118,9.23371189 C14.5294118,9.03287507 14.5294118,8.86072923 14.4996519,8.65989241 L14.4996519,8.65989241 C14.4996519,8.65989241 14.4996519,8.65989241 14.4996519,8.63120143 C14.4996519,8.28690974 14.2020536,8 13.8151758,8 C13.4580578,8 13.1306996,8.28690974 13.1306996,8.63120143 C13.1306996,8.65989241 13.1306996,8.65989241 13.1306996,8.68858338 C13.1604595,8.86072923 13.1902193,9.03287507 13.1902193,9.20502092 C13.1902193,11.5863718 11.5236686,13.5086671 9.5,13.5086671 L9.5,13.5086671 C7.47633136,13.5086671 5.80978072,11.5863718 5.80978072,9.20502092 C5.80978072,9.03287507 5.83954055,8.86072923 5.86930038,8.68858338 C5.86930038,8.65989241 5.86930038,8.65989241 5.86930038,8.63120143 C5.86930038,8.28690974 5.57170205,8 5.18482423,8 C4.7979464,8 4.50034807,8.28690974 4.50034807,8.63120143 C4.50034807,8.63120143 4.50034807,8.63120143 4.50034807,8.65989241 L4.50034807,8.65989241 C4.47058824,8.86072923 4.47058824,9.03287507 4.47058824,9.23371189 C4.47058824,12.3323371 6.7025757,14.8571429 9.47024017,14.8571429 L9.47024017,14.8571429 C12.2676645,14.8571429 14.5294118,12.3323371 14.5294118,9.23371189 Z" id="XMLID_90_" fill="#1C94C1"></path>
												<path d="M4.06435127,4.57142857 L4.06435127,3.69790719 C4.06435127,2.79526843 4.68262411,2.09645132 5.45546516,2.09645132 L13.5445348,2.09645132 C14.3173759,2.09645132 14.9356487,2.82438581 14.9356487,3.69790719 L14.9356487,4.57142857 L16.7647059,4.57142857 L16.7647059,3.66878981 C16.7647059,1.63057325 15.3478306,0 13.5702962,0 L5.4297038,0 C3.67793075,0 2.23529412,1.63057325 2.23529412,3.66878981 L2.23529412,4.57142857 L4.06435127,4.57142857 Z" id="XMLID_89_" fill="#268DA4"></path>
												<path d="M14.8326118,3.42857143 L4.16738817,3.42857143 C1.86435786,3.42857143 0,5.35191638 0,7.74912892 L0,19.6794425 C0,22.0766551 1.86435786,24 4.16738817,24 L14.8326118,24 C17.1356421,24 19,22.0766551 19,19.6794425 L19,7.74912892 C19,5.37979094 17.1356421,3.42857143 14.8326118,3.42857143 Z M16.6147186,19.6236934 C16.6147186,20.6829268 15.7922078,21.5191638 14.8051948,21.5191638 L4.19480519,21.5191638 C3.18037518,21.5191638 2.38528139,20.6550523 2.38528139,19.6236934 L2.38528139,7.80487805 C2.38528139,6.7456446 3.20779221,5.90940767 4.19480519,5.90940767 L14.8051948,5.90940767 C15.8196248,5.90940767 16.6147186,6.77351916 16.6147186,7.80487805 L16.6147186,19.6236934 Z" id="XMLID_86_" fill="#35A0C1"></path>
											</g>
										</g>
									</g>
								</svg>
							</div>
							<v-icon v-if="item.Similar_flag == 1" small class="" color="green">mdi-approximately-equal-box</v-icon>
						</div>
						<div v-if="item.caracteristicas != null || item.descarticulo != null || enableShortenLink">
							<v-tooltip bottom v-if="item.caracteristicas">
								<template v-slot:activator="{ on, attrs }">
									<v-btn 
										x-small 
										color="blue-600" 
										class="mr-1" 
										dark 
										@click.stop.prevent="showInfoDialog('FEATURES')" 
										v-bind="attrs"
										v-on="on"> 
										<v-icon size="14">mdi-format-list-bulleted-triangle</v-icon>
									</v-btn>
								</template>
								<span>Características</span>
							</v-tooltip>
							<v-tooltip bottom v-if="item.descarticulo">
								<template v-slot:activator="{ on, attrs }">
									<v-btn 
										x-small 
										color="blue-600" 
										dark 
										@click.stop.prevent="showInfoDialog('DESCRIPTION')" 
										v-bind="attrs"
										v-on="on"> 
										<v-icon size="14">mdi-clipboard-text-outline</v-icon>
									</v-btn>
								</template>
								<span>Descripción</span>
							</v-tooltip>

							<v-btn 
								v-if="enableShortenLink"
								x-small 
								color="#6aa84fff"
								dark
								class="ml-1" 
								@click.stop.prevent="handleShortenLink" 
								> 
								<v-icon size="14">mdi-link-variant</v-icon>
							</v-btn>
							
						</div>
					</div>
				<!-- </a> -->
			</v-col>
        </v-row>

		<!-- description dialog -->
		<v-dialog
			v-model="dialog" max-width="490" >
			<v-card>
				<v-card-title class="text-h6">
					{{ titleDialog }}
				</v-card-title>
				<v-card-text v-html="textDialog"></v-card-text>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color=""
					text
					small
					@click="dialog = false"
				>
					cerrar
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- end description dialog -->
    </v-card>
     
</template>

<script>

import DataLayersMixin from '@/mixins/DataLayersMixin'

export default {
    name: 'ItemCarrusel2',
    props: {
        item: Object,
		activateComparator: Boolean,
		enableShortenLink: Boolean,
		enableLike: Boolean
    },
	mixins: [DataLayersMixin],
    data() {
        return {
			dialog: false,
			typeDialog: '',
			shortenLinkDialog: false,
			titleDialog: 'Dialog',
        }
    },
	computed: {
		featuresOutputFormat() { 
			let features = ''
			if (typeof this.item.caracteristicas == 'object') {
				Object.entries(this.item.caracteristicas).forEach(([key, value]) => {
					features += `<b>${key}:	</b> ${value} <br/>` 
            	});
			} else {
				features = this.item.caracteristicas
			}
			return features
		},
		textDialog() {
			if (this.typeDialog == 'ITEM_DESCRIPTION') {
				return this.item.descarticulo
			} else if (this.typeDialog == 'ITEM_FEATURES') {
				return this.featuresOutputFormat
			} else {
				return ''
			}
		},
	},
	methods: {
		clickSKU(e) {
			e.preventDefault()
			e.stopPropagation();
			let element = e.target;
			let btnName = element.textContent.split(' ')[0]
			btnName = btnName.substr(0, btnName.length-1) // quitar : al final de la cadena
			this.$emit('clickSKU', {
				sku: element.textContent.split(' ')[1], 
				btnName: btnName,
				item: this.item
			});
		},
		showInfoDialog(data) {
			this.dialog = true;
			// this.textDialog = ''
			if(data == 'DESCRIPTION') {
				this.titleDialog = 'Descripción'
				this.typeDialog = 'ITEM_DESCRIPTION'
				this.$emit('clickAction', {action: 'CLICK_BTN_DESCRIPCION', item: this.item});
				// this.textDialog = this.item.descarticulo
			}
			if(data == 'FEATURES') {
				this.titleDialog = 'Características'
				this.typeDialog = 'ITEM_FEATURES'
				this.$emit('clickAction', {action: 'CLICK_BTN_CARACTERISTICAS',  item: this.item});
				// this.textDialog = this.featuresOutputFormat
			}
		},
		clickCompararItem(e) {
			e.stopPropagation()
			// this.$emit('clickCompararItem', this.item)
			this.$emit('clickAction', {action: 'CLICK_BTN_COMPARAR', item: this.item});
		},
		handleShortenLink() {
			this.$emit('clickAction', {action: 'CLICK_BTN_SHORTEND_LINK', item: this.item});
		},
		handleClickLink() {
			this.$emit('clickAction', {action: 'CLICK_BTN_LINK', item: this.item});
		},
		handleClickLike(value) {
			this.$emit('clickAction', {action: 'CLICK_BTN_LIKE', item: this.item, value: value});
		}
	}
}
</script>

<style scoped>
	/* .icons-bottom-wrapper {
		position: absolute;
		bottom: 5px;
		left: 20px;
	} */
	.item-carrusel-2 .wrapper-ic_bag svg {
		width: 100%;
    	max-width: 16px;
	}
	.item-carrusel-2 .mb-text-10 {
		margin-bottom: 10px !important;
	}
	.item-carrusel-2 .seccion-imagen {
		position: relative;
	}
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop {
		left: 15px;
		bottom: -26px;
	}
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop {
		position: absolute;
		padding: 0;
		z-index: 1;
	}
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault {
		background: 0 0;
		width: auto;
		height: 20px;
		font-family: Roboto;
		line-height: 1.4;
		font-size: 11px;
		cursor: point
	}
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop.Rebaja:after, .item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop.Rebaja:before {
		background: #FFE8EB;
	}
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop:before {
		display: block;
		left: -4px;
		border-radius: 4px 0 0 7px;
		transform: skew(-20deg,0deg);
	}
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop:after, .item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop:before {
		content: "";
		height: 20px;
		position: absolute;
		width: 8px;
		top: 0;
	}
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop span.RibbonAdDefault {
		display: inline-block;
	}
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop .Rebaja, .item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop .Rebaja>span {
		color: #D11E0A;
	}
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop .Rebaja, .item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop.Rebaja:after, .item-carrusel-2 .seccion-imagen .RibbonAdDefault.ribbonTop.Rebaja:before {
		background: #FFE8EB;
	}
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault {
		background: 0 0;
		width: auto;
		height: 20px;
		position: static;
		padding: 2px 4px;
		font-family: Roboto;
		line-height: 17px;
		font-size: 9px;
		cursor: pointer;
	}
	.item-carrusel-2 .RibbonAdDefault.ribbonTop:after {
		display: block;
		right: -4px;
		border-radius: 0 7px 4px 0;
		transform: skew(-20deg,0deg);
	}
	/** */
	.item-carrusel-2 .seccion-imagen .RibbonAdDefault.Rebaja~.ribbonBottom {
    	left: 65px;
	}
	.item-carrusel-2 .seccion-imagen .ribbonBottom {
		position: absolute;
		left: 12px;
		bottom: -26px;
		padding: 2px 4px;
		height: 20px;
		font-size: 9px;
		font-family: Roboto;
		line-height: 17px;
		background: #EDEEF2;
		border-top-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	.item-carrusel-2 .seccion-imagen .ribbonBottom:before {
		display: block;
		left: -4px;
		border-radius: 4px 0 0 7px;
		transform: skew(-20deg,0deg);
	}
	.item-carrusel-2 .seccion-imagen .ribbonBottom:after {
		display: block;
		right: -4px;
		border-radius: 0 7px 4px 0;
		transform: skew(-20deg,0deg);
	}

	.item-carrusel-2 .seccion-imagen .ribbonBottom:after, .seccion-imagen .ribbonBottom:before{
		content: "";
		height: 20px;
		position: absolute;
		width: 8px;
		top: 0;
		background: #EDEEF2;
	}

	.item-carrusel-2 .seccion-imagen .ribbonBottom>span {
		white-space: nowrap;
		color: #68686A;
	}

	/** */
	.item-carrusel-2 .sku-wrapper {
		padding-left: 12px;
		padding-right: 12px;
    	padding-top: 8px;
	}
	.item-carrusel-2 .sku-label {		
		font-size: .6rem;
		color: #000;
		margin: 0;
		cursor: pointer;
	}
	.item-carrusel-2 .sku-label:hover {		
		color: #000;
	}
	.item-carrusel-2 .seccion-caracteristicas {
		padding: 34px 16px 1px;
		position: relative;
		min-height: 195px;
	}
	.item-carrusel-2 .seccion-caracteristicas p, .item-carrusel-2 .seccion-caracteristicas a {
		color: #000;
		font-size: .75rem;
	}
	.item-carrusel-2 .seccion-caracteristicas p.data-nombre {
		font-size: 0.8rem;
		line-height: 18px;
		overflow: hidden;
		height: 34px;
	}
	.item-carrusel-2 .seccion-caracteristicas>div {
		display: inline;
	}
	.item-carrusel-2 .seccion-caracteristicas>div .rebaja-precio-final {
		color: #f60045;
    	font-weight: 700;
		display: inline-block;
		margin-bottom: 0;
		font-size: 1rem;
	}
	.item-carrusel-2 .seccion-caracteristicas .wrapper-prices {
		position: relative;
		display: block;
	}
	.item-carrusel-2 .seccion-caracteristicas .wrapper-prices .wrapper-discount {
		position: absolute;	
		top: -10px;
	    right: 3px;
	}
	.item-carrusel-2 .discount-ribbon{
		position: relative;
		padding: 2px 4px;
		height: 20px;
		font-size: 9px;
		font-family: Roboto;
		line-height: 17px;
		background: #FFDD2F;
		display: inline-block;
	}
	.item-carrusel-2 .discount-ribbon:before, .item-carrusel-2 .discount-ribbon:after{
		content: "";
		height: 20px;
		position: absolute;
		width: 8px;
		top: 0;
		background: #FFDD2F;
	}
	.item-carrusel-2 .discount-ribbon:before{
		display: block;
		left: -4px;
		border-radius: 4px 0 0 7px;
		-webkit-transform: skew(-20deg,0deg);
    	transform: skew(-20deg,0deg)
	}
	.item-carrusel-2 .discount-ribbon:after{
		display: block;
		right: -4px;
		border-radius: 0 7px 4px 0;
		-webkit-transform: skew(-20deg,0deg);
		transform: skew(-20deg,0deg);
	}
	.item-carrusel-2 .discount-ribbon > span{
		color: #f60045;
	}



	.item-carrusel-2 .seccion-caracteristicas .rebaja-precio-normal {
		text-decoration: line-through;
		color: #75787a;
		display: inline-block;
		margin: 0;
		font-size: 1rem;
	}
	.item-carrusel-2 .seccion-caracteristicas>div .default {
		color: #f60045;
    	font-weight: 700;
		font-size: 1rem
	}
	.item-carrusel-2 .seccion-caracteristicas>div p {
		margin: 0 10px;
	}
	.item-carrusel-2 .seccion-caracteristicas .price-text {
		font-size: 0.6rem;
		line-height: 14px;
		margin-top: 7px;
	}
	.item-carrusel-2 .seccion-caracteristicas .vigencia {
		margin-bottom: 0;
		color: #b4b4b4;
		font-size: 0.7rem;
	}
	.item-carrusel-2 .seccion-footer {
		min-height: 30px;
	}

</style>