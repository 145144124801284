
// doc: https://docs.google.com/spreadsheets/d/1oYUZFBXLkR_4O5WQ-N6jCRdavzE3-AtWZ2H95DdAShk/edit#gid=1441547228

export default {
    methods: {

        /**
         * El siguiente dataLayer va al momento de mostrarse la sección de SELECCIONADORES DE PRODUCTOS
         * 
         * @param {string} state 
         * @param {string} city 
         */
        dataLayerLoadPageSeleccionadores(state, city) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos',
                nd3:'Llegada',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                event:'recomendadorCatGeneral'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar un Tipo de artículo
         * 
         * Datalayer enviado al momento de dar click en el botón de un seleccionador
         * 
         * @param {string} seleccionador - nombre del seleccionador
         * @param {string} estado - nombre del estado
         * @param {string} ciudad - nombre de la ciudad
         * 
         */
        dataLayerClickBtnSeleccionador(seleccionador, estado, ciudad) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos',
                nd3:'Selección - Tipo de Artículo - ' + seleccionador,
                nd4:'',
                estadoNombre: estado,
                ciudadNombre: ciudad,
                event:'recomendadorCatGeneral'
            });
        },

        /**
         * El siguiente dataLayer va al momento de mostrarse la sección de SELECCIONADORES DE PRODUCTOS POR TIPO DE ARTÍCULO
         * 
         * Datalayer enviado al cargar una página de seleccionador
         * 
         * @param {string} seleccionador - nombre del seleccionador
         * @param {string} estado -
         * @param {string} ciudad -
         * @param {number} resultadosTotal - 
         * @param {string} filtrosLista - Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N
         * @param {number} filtroPrecio -
         * @param {string} filtroOrdenarPor - 
         */
        dataLayerLoadedSeleccionador(seleccionador, estado, ciudad, resultadosTotal, filtrosLista, filtroPrecio, filtroOrdenarPor) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos - Tipo de Artículo - ' + seleccionador,
                nd3:'Llegada',
                nd4:'',
                estadoNombre: estado,
                ciudadNombre: ciudad,
                resultadosTotal: resultadosTotal, //'<<Cantidad total de resultados de todas las categorías>>',
                filtrosLista: filtrosLista, //'<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: filtroPrecio, 
                filtroOrdenarPor: filtroOrdenarPor, 
                event:'recoCatSeleccionadores'
            });
        },

        /**
         * Tagmanager lanzado al momento de mandar el formulario de un seleccionador 
         * 
         * @param {string} seleccionador - nonbre del seleccionador
         * @param {string} state 
         * @param {string} city 
         * @param {Array} items - items arrojados por el endpoint
         */
        dataLayerSeleccionadorItems(seleccionador, state, city, items) {
            let lotes  = []
            let loteSize = 12
            // crear lotes de 12 elementos
            for (let i = 0; i < items.length; i += loteSize ) {
                lotes.push(items.slice(i, i + loteSize))
            }
            let itemPosition = 0;
            for (let i = 0; i < lotes.length; i++) {
                //Impresiones de producto
                let impressions = lotes[i].map((item) => {
                    ++itemPosition
                    return {
                        'name': item.Nombre,
                        'id': item.SKU, 
                        'price': item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta, //solo numeros, sin comas ni signos, ni puntos decimales, número entero 
                        'brand': item.caracteristicas && typeof item.caracteristicas === "object" ? item.caracteristicas.Marca : '', //si no lo puedes obtener, que vaya en blanco
                        'category': '', // es el id de la categoría. si no lo puedes obtener, que vaya en blanco
                        'variant': '', //si no lo puedes obtener, que vaya en blanco
                        'position': itemPosition ,//se especifica el número del producto del carrusel
                        'list':'Seleccionadores de Productos - Tipo de producto - '+seleccionador+'|MO_PRO_BLO-'+(i+1)+'|TRK-'+(i+1)
                    }
                })
                // Mandar lotes de doce elemenotos
                dataLayer.push({
                    ecommerce: {
                        impressions: impressions
                    },
                    nd1:'EEC - Promos - Impresion',
                    nd2:'Seleccionadores de Productos - Tipo de producto - ' + seleccionador,
                    nd3:'MO_PRO_BLO-'+(i+1)+'|TRK-'+(i+1),
                    nd4:'1', //es un valor constante
                    estadoNombre: state,
                    ciudadNombre: city,
                    event:'promosImpresionPack'
                });

            }
            // si no hay resultados
            // if(lotes.length == 0) {
            //     dataLayer.push({
            //         ecommerce: {
            //             impressions: []
            //         },
            //         nd1:'EEC - Promos - Impresion',
            //         nd2:'Seleccionadores de Productos - Tipo de producto - ' + seleccionador,
            //         nd3:'MO_PRO_BLO-1|TRK-1',
            //         nd4:'1', //es un valor constante
            //         estadoNombre: state,
            //         ciudadNombre: city,
            //         event:'promosImpresionPack'
            //     });
            // }
            
        },

        /**
         * 
         * El siguiente dataLayer va siempre que en una página existan listas de productos y va al clic a cada producto dentro de la lista de productos. 
         * 
         * Datalayer enviado al momento de hacer click en un producto mostrador por un seleccinador
         * 
         * @param {String} seleccionador - Nombre del seleccionador
         * @param {String} item - Item que recibe el click en el seleccionador
         * @param {String} state - Nombre del estado
         * @param {String} city - Numero de la ciudad
         *
         */
        dataLayerSeleccionadorClickProduct(seleccionador, item, state, city) {
            var numBlock = item.index !== undefined ? item.index + 1 : 1
            numBlock = Math.ceil(numBlock / 12); // calcular en que bloque esta el item. cada 12 elementos es un bloque,  del 1 al 12 es bloque 1, del 13 al 24 bloque dos etc.
            dataLayer.push({
                ecommerce: {
                   click: {
                       actionField: { 'list':'Seleccionadores de Productos - Tipo de producto - '+seleccionador+'|MO_PRO_BLO-'+numBlock+'|TRK-'+numBlock}, 
                    },
                    products: [{
                        name: item.Nombre,
                        id: item.SKU,
                        price: item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta,  //cuando el producto cuente con descuento entonces es el precio con descuento
                        brand: item.caracteristicas && typeof item.caracteristicas === "object" ? item.caracteristicas.Marca : '',
                        category: '', //si no lo puedes obtener, que vaya en blanco
                        variant: '', //si no lo puedes obtener, que vaya en blanco
                        position:item.index !== undefined ? item.index + 1 : '' //Se especifica el número del producto del carrusel
                    }]
                },
                nd1:'EEC - Promos - Seleccion - Lista de productos',
                nd2:'Seleccionadores de Productos - Tipo de producto - ' + seleccionador,
                nd3:'MO_PRO_BLO-'+numBlock+'|TRK-'+numBlock,
                nd4: item.index !== undefined ? item.index + 1 : '', //'<<Posición del producto en la lista>>',
                promoTexto: item.Nombre,  // '<<Texto alternativo del producto>>'
                promoCreativo: item.URL_imagen.split("/").length > 1 ? item.URL_imagen.split("/").reverse()[0] : '', // nombre de la imágen // '<<Nombre del archivo de la imagen sin ruta>>'
                estadoNombre: state,
                ciudadNombre: city,
                sku: item.SKU,
                productoNombre: item.Nombre,
                precioNormal: item.Precio_Venta,
                precioDescuento: item.Precio_Promocion > 0 ? item.Precio_Promocion : item.Precio_Venta, //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'promosSeleccionLp'
            });
        },

        /**
         * 
         * El siguiente dataLayer va al momento de seleccionar una categoría de filtro
         * 
         * Datalayer que se envia cuando se da clic en un filtro
         * 
         * @param {String} seleccionador - Nombre del seleccionador
         * @param {String} filterName - Nombre del filtro
         * @param {String} state - Estado
         * @param {String} city - Ciudad
         * @param {Number} totalItems - total productos
         * @param {String} filtrosLista - Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N
         * @param {Number} filtroPrecio -
         * @param {String} filtroOrdenarPor - 
         */
        dataLayerSeleccionadorClickFilter(seleccionador, filterName, state, city, totalItems, filtrosLista, filtroPrecio, filtroOrdenarPor) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos - Tipo de producto - '+ seleccionador +' - Desplegar filtro',
                nd3: filterName, //'<<Nombre de la categoría de filtro seleccionado>>',
                nd4:'',
                estadoNombre: state,
                ciudadNombre:city,
                resultadosTotal: totalItems, //'<<Cantidad total de resultados de todas las categorías>>',
                filtrosLista: filtrosLista, //'<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: filtroPrecio, 
                filtroOrdenarPor: filtroOrdenarPor, 
                event:'recoCatSeleccionadores'
            });
        },

        /**
         * 
         * El siguiente dataLayer va al momento de seleccionar un filtro
         * 
         * Lanzado en con el evento change de los filtros
         * 
         * @param {string} seleccinador - Nombre del seleccionador
         * @param {string} filter - Filtro que lanza el evento onChange
         * @param {string} state - Estado
         * @param {string} city - Ciudad
         * @param {string} filtersList - Filtros de la busqueda en formato Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>' Al llegar a la página mandar como NA
         * @param {string} filterPrice - Filtro de precio
         * @param {string} filterOrderBy - Filtro de ordenamiento
         */
        dataLayerSeleccionadorChangeFilter(seleccinador, filter, state, city, filtersList, filterPrice, filterOrderBy) {
            if(filtersList == '') {
                filtersList = 'NA'
            }
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos - Tipo de producto - '+ seleccinador +' - Filtro',
                nd3: filter,// '<<Nombre de la categoría del filtro>>|<<Nombre del filtro seleccionado>>',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                filtrosLista: filtersList, // '<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: filterPrice, 
                filtroOrdenarPor: filterOrderBy,
                event:'recoCatSeleccionadores'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar el botón de nueva búsqueda
         * 
         * @param {string} recomendador - Nombre del recomendador
         * @param {string} state - Estado
         * @param {string} city - Ciudad
         * @param {string} filtersList - Lista de filtros en formato <<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
         * @param {string} filterPrice - filtrode precio
         * @param {string} filterOrderBy - filtrod e ordenamiento
         */
        dataLayerSeleccionadorClickNuevaBusqueda(recomendador, state, city, filtersList, filterPrice, filterOrderBy) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos - Tipo de producto - ' + recomendador,
                nd3:'Nueva búsqueda',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                filtrosLista:  filtersList, // '<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: filterPrice, 
                filtroOrdenarPor: filterOrderBy,
                event:'recoCatSeleccionadores'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar la opción de precio
         * 
         * @param {string} recomendador 
         * @param {string} state 
         * @param {string} city 
         * @param {integer} filterMaxPrice
         * @param {integer} filterPrevMaxPrice 
         * @param {string} filtersList 
         * @param {string} filterOrderBy 
         */
        dataLayerChangePrecioMaximo(recomendador, state, city, filterMaxPrice, filterPrevMaxPrice, filtersList, filterOrderBy) {
            if(filtersList == '') {
                filtersList = 'NA'
            }
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos - Tipo de producto - ' + recomendador,
                nd3:'Selección de precio',
                nd4: filterMaxPrice, //'<<Nuevo monto de precio seleccionado por el usuario>>',
                estadoNombre: state,
                ciudadNombre: city,
                filtrosLista: filtersList, //'<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: filterPrevMaxPrice, // '<<Precio seleccionado por el usuario anteriormente>>', 
                filtroOrdenarPor: filterOrderBy, //'<<Ordenar por seleccionado>',
                event:'recoCatSeleccionadores'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar la opción de Ordenar por
         * 
         * @param {string} recomendador - Nombre del recomendador
         * @param {string} state 
         * @param {string} city 
         * @param {string} filtersList - Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como N
         * @param {integer} filterMaxPrice - Precio máximo anterior
         * @param {string} filterOrderBy - Filtro ordenar por
         */
        dataLayerClickOrdenarPor(recomendador, state, city, filtersList, filterMaxPrice, filterOrderBy) {
            if(filtersList == '') {
                filtersList = 'NA'
            }
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos - Tipo de producto - ' + recomendador,
                nd3:'Ordenar por',
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                filtrosLista: filtersList, //'<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: filterMaxPrice, // '<<Precio mostrado>>', 
                filtroOrdenarPor: filterOrderBy, //'<<Ordenar por seleccionado por el usuario>',
                event:'recoCatSeleccionadores'
            });
        },

        /**
         * El siguiente dataLayer va al momento de seleccionar la opción de Ordenar por
         * 
         * @param {string} recomendador - Nombre del recomendador
         * @param {string} state 
         * @param {string} city 
         * @param {string} filterOrderBy - Filtro ordenar por
         * @param {string} filterPrevOrderBy - Ordenar por seleccionado anteriormente por el usuario
         * @param {integer} filterMaxPrice Precio seleccionado por el usuario
         * @param {string} filtersList - Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA  
         */
        dataLayerChangeOrdenarPor(recomendador, state, city, filterOrderBy, filterPrevOrderBy, filterMaxPrice, filtersList) {
            if(filtersList == '') {
                filtersList = 'NA'
            }
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos - Tipo de producto - ' + recomendador,
                nd3:'Ordenar por - ' + filterOrderBy,
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                filtrosLista: filtersList, // '<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio:  filterMaxPrice, // <<Precio seleccionado por el usuario 
                filtroOrdenarPor: filterPrevOrderBy, //'<<Ordenar por seleccionado anteriormente por el usuario>>',
                event:'recoCatSeleccionadores'
            });
        },

        /**
         * El siguiente dataLayer va al momento de dar clic en el botón de Características|Descripción dentro de un producto|Copiar enlace
         * 
         * @param {string} recomendador - Nombre del recomendador
         * @param {string} btnCliked - Nombre del botón de la tarjeta que recibe el click (Características|Descripción|Copiar enlace)
         * @param {string} productSKU - SKU del producto
         * @param {string} productName - Nombre del producto
         */
        dataLayerClickBtnsItemCard(recomendador, btnCliked, productSKU, productName) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos - Tipo de producto - ' + recomendador,
                nd3: btnCliked, //'<<Características|Descripción|Copiar enlace>>',
                nd4:'',
                sku: productSKU, //'<<SKU del producto>>', 
                productoNombre: productName, // '<<Nombre del producto>>'
                event:'recoCatSeleccionadores'
            });
        },

        /**
         * El siguiente dataLayer va al momento de mostrarse el mensaje de sin resultados
         * 
         * @param {string} recomendador - Nombre del recomendador
         * @param {string} state 
         * @param {string} city 
         * @param {string} filtersList - Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;
         * @param {integer} filterMaxPrice - Filtro precio máximo
         * @param {string} filterOrderBy - filto ordenar por
         */
        dataLayerSeleccionadorNoItems(recomendador, state, city, filtersList, filterMaxPrice, filterOrderBy) {
            state = state == '' ? 'NA' : state
            city = city == '' ? 'NA' : city
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2:'Seleccionadores de Productos - Tipo de producto - ' + recomendador,
                nd3:'Sin resultados',
                nd4:'',
                estadoNombre: state, // '<<Nombre del estado>>', // Si no se tiene definido mandar como NA
                ciudadNombre: city, //'<<Numero de la ciudad>>', //Si no se tiene definido mandar como NA
                filtrosLista: filtersList, //'<<Nombre de la categoría del filtro>>:<<Nombre del filtro>>; . . . ;<<Nombre de la categoría del filtro N>>:<<Nombre del filtro N>>', //al llegar a la página mandar como NA
                filtroPrecio: filterMaxPrice, //'<<precio seleccionado>>' // Si no se tiene definido mandar como NA
                filtroOrdenarPor: filterOrderBy, //'<<Ordenar por seleccionado anteriormente por el usuario>>'
                event:'recoCatSeleccionadores'
            });
        },

        /**
         *  El siguiente dataLayer va al momento de dar clic en el botón de Copiar Código o Copiar SKU
         * 
         * @param {String} btnName - <<Copiar código|Cópiar SKU>>
         * @param {Object} product - Nombre del estado
         * @param {String} state - Numero del estado
         * @param {String} city - Numero de la ciudad
         */
        dataLayerClickCardSKUSeleccionadores(section, btnName, product, state, city) {
            dataLayer.push({
                nd1:'Recomendador CAT',
                nd2: section,
                nd3: 'Copiar ' + btnName,
                nd4:'',
                estadoNombre: state,
                ciudadNombre: city,
                sku: product.SKU, 
                productoNombre: product.Nombre,
                precioNormal: product.Precio_Venta,
                precioDescuento:product.Precio_Promocion > 0 ? product.Precio_Promocion : product.Precio_Venta, //cuando el producto no cuente con descuento mandar el precio normal del producto
                event:'recomendacionesCatGeneral'
            });
        },
	}
}