<template  lang="pug">
    div
        template(v-if="!isComparadorVisible")
            ValidationObserver(ref='observer')
                form(action="#" @submit.prevent="handleSubmitForm")
                    v-container(class='pt-0 pb-0')
                        v-row(align="end" justify="center")
                            v-col(sm="3")
                                validation-provider(v-slot="{ errors }" name="sku" rules="required")
                                    v-text-field(
                                        :error-messages="errors"
                                        required
                                        label='Búsqueda por SKU'
                                        v-model='sku'
                                        clearable )
                            v-col(sm="1")
                                    v-btn(color="primary" small :loading="loading" :disabled="loading" type="submit" class="mb-6")
                                        v-icon(size="20") mdi-magnify

            v-container 
                v-row(class="mt-0 mb-5") 
                    v-col(sm="10" offset="1")
                        //- no aplicar condicion para marketplace
                        //- muebles aplica flag dispo, ropa no checa disponibilidad
                        div(v-if="buscadorProductsList.length == 1 && ((buscadorProductsList[0].dispo == 1 && skuEndpoints.toString().endsWith('3')) || skuEndpoints.toString().endsWith('2')  || buscadorProductsList[0].MP_flag == 1)")
                            p(class="text-center success--text") Este es el producto que estás buscando.
                        div(v-if="buscadorProductsList.length > 1")
                            p(class="text-center") Este SKU está duplicado, elige el correcto.
                        //- no aplicar condicion para marketplace
                        div(v-if="buscadorProductsList.length == 1 && buscadorProductsList[0].dispo == 0 && skuEndpoints.toString().endsWith('3') && buscadorProductsList[0].MP_flag != 1")
                            p(class="text-center red--text") Este producto no está disponible en el Estado y Ciudad que elegiste, <br> te recomendamos consultar productos similares o modificar Estado y Ciudad.
                        v-divider
                v-row()

                    //- 
                    v-col(cols="12" sm="12" lg="12" class="mb-5" v-if="buscadorProductsList.length")
                        div(class="mb-4")
                            //- h2(class="text-subtitle-1 blue-600--text font-weight-bold d-inline-block mb-2") 
                            //-     | pivotes

                            div(class="pa-2 text-center")
                                div(class="d-inline-block" v-for="item in buscadorProductsList" )
                                    div(class="text-center" v-if="buscadorProductsList.length > 1")
                                        v-btn(
                                            x-small 
                                            icon 
                                            outlined
                                            fab
                                            :class="[item.SKU == sku ? 'blue-600' : '']"  
                                            :dark="item.SKU == sku" 
                                            @click="handleFetchDataByPivote(item.SKU)"
                                            :loading="loading" 
                                        )
                                            v-icon( size="16") mdi-check
                                    CardItem( 
                                        :item="item" 
                                        :key="item.SKU" 
                                        activateComparator
                                        enableShortenLink
                                        @clickAction="(data) => handleClickActionCardItem('BUSCADOR', data)"
                                        @clickSKU="handleClickSKU")

                    //- Similares - sin disponibilidad
                    v-col(cols="12" sm="12" lg="12" class="mb-5" v-if="buscadorProductsList.length == 1 && buscadorProductsList[0].dispo == 0 && skuEndpoints.toString().endsWith('3')")
                        div(class="mb-4")
                            h2(class="text-h6 blue-600--text  d-inline-block mb-4") 
                                | Productos Similares

                            v-card(class="pa-2 overflow-y-auto d-flex flex-wrap" style="background-color: #FCF3CF;" elevation="0" max-height="480" v-if="similaresProductsList.length")
                                CardItem( 
                                    v-for="item, index in similaresProductsList" 
                                    :item="item" 
                                    :key="item.SKU + index" 
                                    @clickSKU="handleClickSKU"
                                    enableShortenLink
                                    enableLike
                                    activateComparator
                                    @clickAction="(data) => handleClickActionCardItem('PRODUCTOS_SIMILARES', data)")

                            v-card(elevation="0" outlined v-else)
                                v-card-text 
                                    p(class="mb-0") Sin productos para mostrar

                    //- Principales agregados
                    v-col(cols="12" sm="12" lg="12" class="mb-5")
                        div(class="mb-4")
                            h2(class="text-h6 blue-600--text d-inline-block mb-4") 
                                | Principales agregados

                            v-card(class="pa-2 overflow-y-auto d-flex flex-wrap" style="background-color: #d3ebf8ff;" elevation="0" max-height="480" v-if="combosProductsList.length")
                                CardItem( 
                                    v-for="item, index in combosProductsList" 
                                    :item="item" 
                                    :key="item.SKU + index" 
                                    @clickSKU="handleClickSKU"
                                    activateComparator
                                    enableShortenLink
                                    enableLike
                                    @clickAction="(data) => handleClickActionCardItem('COMBOS', data)")

                            v-card(elevation="0" outlined v-else)
                                v-card-text 
                                    p(class="mb-0") Sin productos para mostrar
                                    

                    //- Otros productos complementarios para tu venta
                    v-col(cols="12" sm="12" lg="12" class="mb-5")
                        div(class="mb-4")
                            h2(class="text-h6 blue-600--text d-inline-block mb-4") 
                                | Otros productos complementarios para tu venta

                            v-card(class="pa-2 overflow-y-auto d-flex flex-wrap" style="background-color: #E9F7EF;" elevation="0" max-height="480" v-if="crossSellProductsList.length")
                                CardItem( 
                                    v-for="item, index in crossSellProductsList" 
                                    :item="item" 
                                    :key="item.SKU + index" 
                                    @clickSKU="handleClickSKU"
                                    activateComparator
                                    enableShortenLink
                                    enableLike
                                    @clickAction="(data) => handleClickActionCardItem('CROSS_SELL', data)")

                            v-card(elevation="0" outlined v-else)
                                v-card-text 
                                    p(class="mb-0") Sin productos para mostrar

                    
                    //- Complementa tu venta para envío gratuito
                    v-col(cols="12" sm="12" lg="12" class="mb-5" v-if="recomendacionesEnvioGratisProductsList.length")
                        div(class="mb-4")
                            h2(class="text-h6 blue-600--text d-inline-block mb-4") 
                                | Complementa la compra del cliente para envío gratuito


                            v-card(class="pa-2 overflow-y-auto d-flex flex-wrap" style="background-color: #FDEBD0;" elevation="0" max-height="480" v-if="recomendacionesEnvioGratisProductsList.length")
                                CardItem( 
                                    v-for="item, index in recomendacionesEnvioGratisProductsList" 
                                    :item="item" 
                                    :key="item.SKU + index" 
                                    @clickSKU="handleClickSKU"
                                    activateComparator
                                    enableShortenLink
                                    enableLike
                                    @clickAction="(data) => handleClickActionCardItem('ENVIO_GRATIS', data)" )

                            v-card(elevation="0" outlined v-else)
                                v-card-text 
                                    p(class="mb-0") Sin productos para mostrar

                                

                    //- Similares - con disponibilidad
                    v-col(cols="12" sm="12" lg="12" class="mb-5" v-if="buscadorProductsList.length == 1 && !(buscadorProductsList[0].dispo == 0 && skuEndpoints.toString().endsWith('3'))")
                        div(class="mb-4")
                            h2(class="text-h6 blue-600--text  d-inline-block mb-4") 
                                | Productos Similares

                            v-card(class="pa-2 overflow-y-auto d-flex flex-wrap" style="background-color: #FCF3CF;" elevation="0" max-height="480" v-if="similaresProductsList.length")
                                CardItem( 
                                    v-for="item, index in similaresProductsList" 
                                    :item="item" 
                                    :key="item.SKU + index" 
                                    @clickSKU="handleClickSKU"
                                    activateComparator
                                    enableShortenLink
                                    enableLike 
                                    @clickAction="(data) => handleClickActionCardItem('PRODUCTOS_SIMILARES', data)"
                                    )

                            v-card(elevation="0" outlined v-else)
                                v-card-text 
                                    p(class="mb-0") Sin productos para mostrar


            v-snackbar(v-model="snackbar" :timeout="timeout") {{snackbarText}}
                template(v-slot:action="{ attrs }")
                    v-btn(
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    ) Cerrar
        template(v-if="isComparadorVisible")
            v-container     
                v-row 
                    v-col(sm="12")
                        TablaComparativaDeProductos(
                            class="w-100"
                            :items="productsComparatorList"
                            :state="state"
                            :city="city"
                            @onRemoveItem="handleCompararItem"
                        )

        v-btn( color="primary" small dark fixed bottom right v-if="isComparadorVisible" @click="handleClickRegresarAComparador") <v-icon size="15" class="mr-1">mdi-keyboard-return</v-icon> Regresar
        v-btn( color="purple" small dark fixed bottom right v-else @click.prevent="handleClickComparador") <v-icon size="15" class="mr-1">mdi-select-compare</v-icon> comparador

        //- dialog acortador links
        v-dialog(
            v-model="dialogFlag"
            max-width="300"
        )
            v-card
                v-card-title(class="text-h6") Copia el link
                v-card-text {{ dialogText }}
                v-card-actions
                    v-spacer
                    v-btn(text @click="dialogFlag = false") Cerrar
                        



</template>

<script>

import axios from 'axios';
import CardItem from './CardItem';
import {mapState, mapActions, mapGetters} from 'vuex'
import TablaComparativaDeProductos from './TablaComparativaDeProductos2';
import DataLayersComparadorMixin from '@/mixins/DataLayersComparadorMixin'
// import AcortadorLinksService from '@/services/AcortadorLinksService'
import ShortenUrlsService from '@/services/ShortenUrlsService'
import DataLayersMixin from '@/mixins/DataLayersMixin'
import RecomendadorCATService from '@/services/RecomendadorCATService'
const API_KEY = 'AIzaSyCswkVbidmj-t7G9jP0_RNA5tz8FL8LaSM';
const shortenUrlsService = new ShortenUrlsService()
const recomendadorCATService = new RecomendadorCATService()

export default {
    name: 'RecomendacionesPorArticuloTab',
    props: {
        state: String,
        city: String,
    },
    components: {
        CardItem,
        TablaComparativaDeProductos
    },
    mixins: [DataLayersMixin, DataLayersComparadorMixin],
    data() {
        return {
            sku: '', // sku ingresado en el formulario, usado en el endpoint de buscador
            skuEndpoints: '', // sku de apoyo para mandar en los endpoints

            // similares
            similaresData: null,
            loadingSimilares: false,

            // buscador
            buscadorData: null,
            loadingBuscador: false,

            // crosssell
            crossSellData: null,
            loadingCrossSell: false,

            // combos
            combosData: null,
            loadingCombos: false,

            // envio gratis
            recomendacionesEnvioGratisData: null,
            LoadingRecomendacionesEnvioGratis: false,

            // comparador
            isComparadorVisible: false,

            snackbar: false,
            timeout: 2000,  
            snackbarText: '',

            // dialog
            dialogFlag: false,
            // dialogTitle: '',
            dialogText: ''
        }
    },
    watch: {
        state(value) {
            this.similaresData = null;
            this.crossSellData = null;
            this.buscadorData = null;
            this.combosData = null;
            this.recomendacionesEnvioGratisData = null;
        },
        city(value) {
            this.similaresData = null;
            this.crossSellData = null;
            this.buscadorData = null;
            this.combosData = null;
            this.recomendacionesEnvioGratisData = null;
        }
    },
    computed: {
        ...mapState({
            productsComparatorList: state => state.tablaComparativaProductos.productsComparatorList,
        }),
        ...mapGetters('session', [
            'userNoEmpleado',
            'userTipoVenta',
        ]),
        loading() {
            return this.loadingBuscador || this.loadingSimilares ||  this.loadingCrossSell ||  this.loadingCombos;
        },
        similaresProductsList() {
            return this.similaresData && 
                    this.similaresData.recomendaciones ? 
                    this.similaresData.recomendaciones.map(this.transform_data) : 
                    [];
        },
        crossSellProductsList() {
            return this.crossSellData && 
                    this.crossSellData.recomendaciones ? 
                    this.crossSellData.recomendaciones.map(this.transform_data) : 
                    [];
        },
        recomendacionesEnvioGratisProductsList() {
            return this.recomendacionesEnvioGratisData && 
                    this.recomendacionesEnvioGratisData.recomendaciones ? 
                    this.recomendacionesEnvioGratisData.recomendaciones.map(this.transform_data) : 
                    [];
        },
        combosProductsList() {
            return this.combosData && 
                    this.combosData.Combos ? 
                    this.combosData.Combos.map(this.transform_data) : 
                    [];
        },
        buscadorProductsList() {
            return this.buscadorData && 
                    this.buscadorData.detalles ? 
                    this.buscadorData.detalles.map(this.transform_data) : 
                    [];
        },
    },
    methods: {
        ...mapActions('tablaComparativaProductos', [
            'addProductToComparator',
            'removeProductFromComparator',
            'cleanProductsComparator'
        ]),
        handleClickComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
            // datalayer
            this.dayaLayerClickComparador("Recomendaciones por artículo", this.state, this.city)
        },
        handleClickRegresarAComparador() {
            this.isComparadorVisible = !this.isComparadorVisible
            //datalayer
            this.dataLayerClickRegresarAComparador(this.state, this.city)
        },
        updateFieldIsSelectedForComparison(productsList) {
            const skusComparatorList = this.productsComparatorList.map(item => item.SKU);
            return productsList.map(item => {
                let status = skusComparatorList.includes(item.SKU)
                return {...item, isSelectedForComparison: status};
            });
        },
        handleCompararItem(item) {
            if(item && item.isSelectedForComparison) {
                this.removeProductFromComparator(item)
            } else {
                this.addProductToComparator(item)
            }
            if (this.combosData) {
                this.combosData.Combos = this.updateFieldIsSelectedForComparison(this.combosData.Combos ||  [])
            }
            if (this.similaresData) {
                this.similaresData.recomendaciones = this.updateFieldIsSelectedForComparison(this.similaresData.recomendaciones ||  [])
            }
            if (this.crossSellData) {
                this.crossSellData.recomendaciones = this.updateFieldIsSelectedForComparison(this.crossSellData.recomendaciones ||  [])
            }
            if (this.recomendacionesEnvioGratisData) {
                this.recomendacionesEnvioGratisData.recomendaciones = this.updateFieldIsSelectedForComparison(this.recomendacionesEnvioGratisData.recomendaciones ||  [])
            }
            if (this.buscadorData) {
                this.buscadorData.detalles = this.updateFieldIsSelectedForComparison(this.buscadorData.detalles ||  [])
            }            
        },
        handleLikeItem(model, action, item) {
            let modelLabel = '';
            let helperSwitchLike = function(i) {
                if(i.SKU == item.SKU) {
                    let like = null;
                    if ( (item.like == null || item.like == 0) && action == 'LIKE') {
                        like = 1;
                    } else if ( ( item.like == null || item.like == 1 ) && action == 'DISLIKE') {
                        like = 0;
                    }
                    return {...i, like: like}
                } else {
                    return i
                }
            }
            if (this.combosData && model == 'COMBOS') {
                modelLabel = 'combos';
                this.combosData.Combos = this.combosData.Combos.map(helperSwitchLike);
            }
            if (this.similaresData && model == 'PRODUCTOS_SIMILARES') {
                modelLabel = 'similares';
                this.similaresData.recomendaciones = this.similaresData.recomendaciones.map(helperSwitchLike)
            }
            if (this.crossSellData && model == 'CROSS_SELL') {
                modelLabel = 'crossSell';
                this.crossSellData.recomendaciones = this.crossSellData.recomendaciones.map(helperSwitchLike)
            }
            if (this.recomendacionesEnvioGratisData && model == 'ENVIO_GRATIS') {
                modelLabel = 'envioGratuito';
                this.recomendacionesEnvioGratisData.recomendaciones = this.recomendacionesEnvioGratisData.recomendaciones.map(helperSwitchLike)
            }
            let itemUpdated = helperSwitchLike(item)
            // registrar evento like en la base datos
            let data = {
                like: itemUpdated.like,
                model: modelLabel,
                state: this.state,
                city: this.city,
                pivot: this.sku,
                recommendation: itemUpdated.SKU,

            }
            recomendadorCATService
                .registerLikeItemRecomendadorPorArticulo(data)
                .then(response => {
                    // console.log(response.data)
                })
                .catch(error => {
                    console.log('ups an error')
                })
        },
        handleClickSKU({sku, btnName, item}) {
            this.snackbarText = `SKU ${sku} Copiado en el portapapeles`
            navigator.clipboard.writeText(sku);
            this.snackbar = true;
            // datalayer
            this.dataLayerClickCardSKU('Recomendaciones por artículo', btnName, item, this.state, this.city)
        },
        handleShortenLink(model, item) {
            const url = item.URL_producto
            const data = {
                data: {
                    target: url
                },
                item: item,
                model: model,
                callback: (link) => {
                    // snackbar
                    // this.snackbarText = `Link acortado copiado en el porta papeles`
                    // this.snackbar = true;
                    // navigator.clipboard.writeText(link);

                    // dialog
                    this.dialogFlag = true;
                    this.dialogText = link
                    // this.dialogTitle = 'Copea tu link acortado'
                }
            }
            this.dialogText = ''
            this.shortenLink(data)
        },
        handleClickActionCardItem(model, data) {
            const {action, item} = data
            switch(action) {
                case 'CLICK_BTN_LINK':
                    let section = ''
                    let order = 0; // lugar en el que aparece el carrusel 
                    if(model == 'COMBOS') {
                        section = 'Principales agregados'
                        order = 1;
                    } else if (model == 'PRODUCTOS_SIMILARES') {
                        section = 'Productos similares'
                        order = 4;
                    } else if (model == 'CROSS_SELL') {
                        section = 'Otros productos complementarios para tu venta'
                        order = 2;
                    } else if (model == 'ENVIO_GRATIS') {
                        section = 'Complementa tu venta para envío gratuito'
                        order = 3;
                    }
                    else {
                        section = 'Busqueda'
                    }
                    this.dataLayerItemRecPorArticuloByModel(section, order, item, this.state, this.city)
                    break;
                case 'CLICK_BTN_DESCRIPCION':
                    this.dataLayerBtnCaracDescRecoPorArticulo('Descripción', item, this.state, this.city)
                    break;
                case 'CLICK_BTN_CARACTERISTICAS':
                    this.dataLayerBtnCaracDescRecoPorArticulo('Características', item, this.state, this.city)
                    break;
                case 'CLICK_BTN_SHORTEND_LINK':
                    this.handleShortenLink(model, item)
                    this.dataLayerBtnCaracDescRecoPorArticulo('Copiar', item, this.state, this.city)
                    break;
                case 'CLICK_BTN_COMPARAR':
                    this.handleCompararItem(item)
                    this.dataLaterClickAddToCompare("Recomendaciones por artículo", this.state, this.city, item)
                    break;
                case 'CLICK_BTN_LIKE':
                    this.handleLikeItem(model, data.value, item)
                    break;
            }

        },
        appendUTM(link) {
            if (this.userTipoVenta == null || this.userTipoVenta == '' || this.userNoEmpleado == null || this.userNoEmpleado == ''  ) {
                return link
            }
            let utm_source = 'cat';
            let utm_medium = 'vt';
            if (this.userTipoVenta == 'ASISTIDA') {
                utm_source = 'ven-asist-wpp';
                utm_medium = 'referido';
            }
            return `${link}?utm_campaign=interfaz-cat-ad&utm_medium=${utm_medium}&utm_source=${utm_source}&utm_content=${this.userNoEmpleado}`
        },
        transform_data(item, index) {
            // fecha
            let fec_FechaFinal = null;
            if (item.fec_FechaFinal != undefined && item.fec_FechaFinal != 'NaT' && item.fec_FechaFinal != null) {
                let fec_FechaFinalArr = item.fec_FechaFinal.indexOf('T') > -1 ? item.fec_FechaFinal.split('T')[0].split('-') : item.fec_FechaFinal.split(' ')[0].split('-');
                if(Array.isArray(fec_FechaFinalArr) && fec_FechaFinalArr.length == 3) {
                    fec_FechaFinal = fec_FechaFinalArr[2] +'/' + fec_FechaFinalArr[1] + '/' + fec_FechaFinalArr[0];
                }
            }
            // leyenda precio
            var price_text = '';
            if(item.price_coppel && typeof item.price_coppel == 'object') {
                price_text = `Cómpralo con crédito Coppel desde $${this.price_format(item.price_coppel.pago_quincenal || '')} quincenal ($${this.price_format(item.price_coppel.precio_contado || '')} en ${this.price_format(item.price_coppel.qty_quincenas || '')} quincenas*)`
            } else {
                let price_coppel = item.price_coppel && item.price_coppel != '' && item.price_coppel !== null ? item.price_coppel.split('/') : [];
                if(price_coppel.length == 3) {
                    price_text = `Cómpralo con crédito Coppel desde $${this.price_format(price_coppel[0])} quincenal ($${this.price_format(price_coppel[1])} en ${this.price_format(price_coppel[2])} quincenas*)`
                }
            }
            
            let Precio_Promocion_format = this.price_format(item.Precio_Promocion);
            let Precio_Venta_format = this.price_format(item.Precio_Venta);
            let descuento = item.Precio_Promocion == 0 || item.Precio_Venta == 0 ? 0 : Math.floor((1 - item.Precio_Promocion / item.Precio_Venta) * 100);
            let sku = item.SKU.toString();
            let SKU_RAW = sku.substr(0,sku.length -1 )
            let MP_flag = item.MP_flag && item.MP_flag == 1 ? 1 : 0;
            // link
            item.URL_producto = this.appendUTM(item.URL_producto)
            // salida
            return {...item, fec_FechaFinal, price_text, Precio_Promocion_format, Precio_Venta_format, descuento,  SKU_RAW, MP_flag, index};
        },
        normalizeFields(items) {
            return items.map(item => {
                return {
                    ...item, 
                    SKU: item.SKU ? item.SKU : item.sku,
                    URL_imagen: item.URL_imagen ? item.URL_imagen : item.image_path,
                    URL_producto: item.URL_producto ? item.URL_producto : item.url_producto,
                    Precio_Promocion: item.Precio_Promocion ? item.Precio_Promocion : item.precio_promocion,
                    Precio_Venta: item.Precio_Venta ? item.Precio_Venta : item.precio_venta,
                    price_coppel: item.price_coppel ? item.price_coppel : '',
                }
            })
        },
        price_format(numb) {
            var str = numb.toString().split(".");
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");
        },
        // Principales agregados
        fetchCombosData() {
            const key = 'AIzaSyAyzz2KSlZAOE2TAbWaPLpCjBxqMsfUcZ0';
            let state = this.state;
            if(state == 'CDMX') {
                state = 'DISTRITO FEDERAL';
            }
            let url = `https://bundle.coppelomnicanal.com/v1/cat/${this.skuEndpoints}/${this.city}/${state}?key=${key}`
            this.loadingCombos = true;
            axios
                .get(url)
                .then(data => {
                    let combosData = data.data
                    if(combosData) {
                        combosData.Combos = combosData.Combos.map((item) => ({...item, isSelectedForComparison: false, like: null}))
                        // combosData.Combos = this.normalizeFields(combosData.Combos)
                        this.combosData = combosData
                        this.dataLayerItemsRecPorArticuloByModel('Principales agregados', 1, data.data.Combos)
                    }
                })
                .finally(() => {
                    this.loadingCombos = false;
                });
        },
        // Productos Similares
        fetchSimilaresData() {
            // let url = `https://cat-services.coppelomnicanal.com/v5/get/recommendationCat/${this.skuEndpoints}/${this.state}/${this.city}/similares?key=${API_KEY}`
            let url = `https://cat-services.coppelomnicanal.com/v6/similares/${this.skuEndpoints}/${this.state}/${this.city}?key=${API_KEY}`
            this.loadingSimilares = true;
            axios
                .get(url)
                .then(data => {
                    let similaresData = data.data
                    if (similaresData) {
                        similaresData.recomendaciones = similaresData.recomendaciones.map((item) => ({...item, isSelectedForComparison: false, like: null}))
                        this.similaresData = similaresData
                        this.dataLayerItemsRecPorArticuloByModel('Productos similares', 4, data.data.recomendaciones)
                    }
                })
                .finally(() => {
                    this.loadingSimilares = false;
                });
        },
        // Otros productos complementarios para tu venta
        fetchCrossSellData() {
            // let url = `https://cat-services.coppelomnicanal.com/v5/get/recommendationCat/${this.skuEndpoints}/${this.state}/${this.city}/cross?key=${API_KEY}`
            let url = `https://cat-services.coppelomnicanal.com/v6/cross/${this.skuEndpoints}/${this.state}/${this.city}?key=${API_KEY}`
            this.loadingSimilares = true;
            axios
                .get(url)
                .then(data => {
                    let crossSellData = data.data
                    if(crossSellData) {
                        crossSellData.recomendaciones = crossSellData.recomendaciones.map((item) => ({...item, isSelectedForComparison: false, like: null}))
                        this.crossSellData = crossSellData
                        this.dataLayerItemsRecPorArticuloByModel('Otros productos complementarios para tu venta', 2, data.data.recomendaciones)
                    }
                })
                .finally(() => {
                    this.loadingCrossSell = false;
                });
        },
        // Complementa tu venta para envío gratuito
        fetchRecomendacionesEnvioGratisData() {
            // let url = `https://cat-services.coppelomnicanal.com/v5/get/recommendationCat/${this.skuEndpoints}/${this.state}/${this.city}/env_gratis?key=${API_KEY}`
            let url = `https://cat-services.coppelomnicanal.com/v6/envioGratis/${this.skuEndpoints}/${this.state}/${this.city}?key=${API_KEY}`
            this.loadingRecomendacionesEnvioGratis = true;
            axios
                .get(url)
                .then(data => {
                    let recomendacionesEnvioGratisData = data.data
                    if(recomendacionesEnvioGratisData.recomendaciones) {
                        recomendacionesEnvioGratisData.recomendaciones = recomendacionesEnvioGratisData.recomendaciones.map((item) => ({...item, isSelectedForComparison: false, like: null}))
                        this.recomendacionesEnvioGratisData = recomendacionesEnvioGratisData
                        this.dataLayerItemsRecPorArticuloByModel('Complementa tu venta para envío gratuito', 3, data.data.recomendaciones);
                    }
                })
                .finally(() => {
                    this.loadingRecomendacionesEnvioGratis = false;
                });
        },
        fetchBuscadorData() {
            // const key = 'AIzaSyCswkVbidmj-t7G9jP0_RNA5tz8FL8LaSM';
            let f_search = 1; // sku de 6 digitos
            if(this.sku.toString().length == 7) { // para sku de 7 digitos
                f_search = 0;
            }
            this.skuEndpoints = this.sku
            // let url = `https://cat-services.coppelomnicanal.com/v5/get/buscadorCat/${this.skuEndpoints}/${this.state}/${this.city}/buscador/${f_search}?key=${API_KEY}`
            let url = `https://cat-services.coppelomnicanal.com/v6/buscador/${this.skuEndpoints}/${this.state}/${this.city}/${f_search}?key=${API_KEY}`
            this.loadingBuscador = true;
            axios
                .get(url)
                .then(data => {
                    let buscadorData = data.data
                    if (buscadorData) {
                        buscadorData.detalles = buscadorData.detalles.map((item) => ({...item, isSelectedForComparison: false, like: null}))
                        this.buscadorData = buscadorData
                        this.dataLayerItemsRecPorArticuloByModel('Busqueda', 0, data.data.detalles); // enviar datalayer
                    }
                    // evento datalayer
                })
                .finally(() => {
                    this.loadingBuscador = false;

                    // si solo hay un resultado en buscador 
                    if(this.buscadorProductsList.length == 1) {
                        // Si el sku es de 6 digitos, ajustarlo para que sea de 7
                        if (this.sku.toString().length == 6 ) {
                            this.skuEndpoints = this.buscadorProductsList[0].SKU
                        }
                        // mandar sku de 7 digitos a datalayer, para saber si es sku de ropa, muebles o mkp.
                        this.dataLayerBtnBusquedaRecPorArticulo(this.state, this.city, this.skuEndpoints, this.buscadorProductsList[0].Nombre)
                        this.fetchEndpointsData();
                    }
                });
        },
        handleSubmitForm() {
            // reset
            this.similaresData = null;
            this.crossSellData = null;
            this.buscadorData = null;
            this.combosData = null;
            this.recomendacionesEnvioGratisData = null;
            // submit
            this.$refs.observer.validate()
                .then( async res=> {
                    if(res && this.state && this.city){
                        this.fetchBuscadorData();
                    } else {
                        this.$emit('validateProps', {city: this.city, state: this.state});
                    }
                });
        },
        handleFetchDataByPivote(sku) {
            // this.sku = sku
            // this.handleSubmitForm()

            // 
            this.skuEndpoints = sku
            this.fetchEndpointsData()
            this.buscadorData.detalles = this.buscadorData.detalles.filter(item => item.SKU == sku)
        },
        fetchEndpointsData() {
            this.fetchSimilaresData();
            this.fetchCrossSellData();
            this.fetchCombosData();
            this.fetchRecomendacionesEnvioGratisData()
        },
        //  recortador de links
        shortenLink(payload) {
            const model = payload.model
            let items = []
            let index = -1
            if(model == 'PRODUCTOS_SIMILARES') {
                items = this.similaresData && this.similaresData.recomendaciones ? this.similaresData.recomendaciones : [] 
            } else if(model == 'COMBOS') {
                items = this.combosData && this.combosData.Combos ? this.combosData.Combos : []
            } else if (model == 'CROSS_SELL') {
                items = this.crossSellData && this.crossSellData.recomendaciones ? this.crossSellData.recomendaciones : [] 
            } else if (model == 'ENVIO_GRATIS') {
                items = this.recomendacionesEnvioGratisData && this.recomendacionesEnvioGratisData.recomendaciones ? this.recomendacionesEnvioGratisData.recomendaciones : [] 
            }
            else {
                // BUSCADOR
                items = this.buscadorData && this.buscadorData.detalles ? this.buscadorData.detalles : []
            }
            index = items.findIndex(i => payload.item.SKU == i.SKU)
            if (index !== undefined && index > -1 && items[index].shortenedLink) {
                payload.callback(items[index].shortenedLink);
                return
            }
            shortenUrlsService
                .create(payload.data)
                .then((response) => {
                    const data = response.data
                    if (items[index]) {
                        items[index].shortenedLink = data.link
                        if(model == 'PRODUCTOS_SIMILARES') {
                            this.similaresData.recomendaciones = items
                        } else if(model == 'COMBOS') {
                            this.combosData.Combos = items
                        } else if (model == 'CROSS_SELL') {
                            this.crossSellData.recomendaciones = items
                        } else if (model == 'ENVIO_GRATIS') {
                            this.recomendacionesEnvioGratisData.recomendaciones = items
                        }
                        else {
                            // BUSCADOR
                            this.buscadorData.detalles = items
                        }
                    }
                })
                .finally(()=> {
                    if (typeof payload.callback === 'function') {
                        payload.callback(items[index].shortenedLink);
                    }
                })
        }
    },
    mounted() {
        this.cleanProductsComparator();
    }
}
</script>
