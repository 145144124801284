import BackendAuthService from "./BackendAuthService";

export default class RecomendadorCATService extends BackendAuthService {

	constructor() {
		super();
	}

	/**
	 * Submit ajax get
	 * @param {string} url
	 * @param {object} params
 	*/
	doGet(url, params) {
		let config = {
			method: 'GET',
		}
		if (params) {
			config['params'] = params
		}
		return this.httpCall(url, config);
	}

	/**
	 * Submit ajax post
	 * @param {string} url
	 * @param {FormData|Object} data
 	*/
	doPost(url, data) {
		let config = {
			method: 'POST',
		}
		if (data) {
			config['data'] = data
		}
		return this.httpCall(url, config);
	}

	// Recomendaciones por arículo

	/**
	 * Registra un like o dislike para un item de recomendacion de Recomendacion por articulo
	 * 
	 * @param {Object} data - Los datos para la solicitud POST.
	 * @returns {Promise} Una promesa que se resolverá con la respuesta de la solicitud. 
	 */
	registerLikeItemRecomendadorPorArticulo(data) {
		let url = 'api/v1/recomendadorcat/recomendaciones-por-articulo-likes/'
		return this.doPost(url, data)
	}

}

