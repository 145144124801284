<template  lang="pug">
    v-container
        SeleccionadorProductosComputo(v-if="seleccionador=='SELECCIONADOR_COMPUTO'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosLlantas(v-else-if="seleccionador=='SELECCIONADOR_LLANTAS'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosBaterias(v-else-if="seleccionador=='SELECCIONADOR_BATERIAS'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosClimaVentilacion(v-else-if="seleccionador=='SELECCIONADOR_CLIMA_VENTILACION'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosCalentadores(v-else-if="seleccionador=='SELECCIONADOR_CALENTADORES'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosMotocicletas(v-else-if="seleccionador=='SELECCIONADOR_MOTOCICLETAS'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosLavadoras(v-else-if="seleccionador=='SELECCIONADOR_LAVADORAS'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosRefrigeradores(v-else-if="seleccionador=='SELECCIONADOR_REFRIGERADORES'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosColchones(v-else-if="seleccionador=='SELECCIONADOR_COLCHONES'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosPantallas(v-else-if="seleccionador=='SELECCIONADOR_PANTALLAS'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosSalas(v-else-if="seleccionador=='SELECCIONADOR_SALAS'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosCelulares(v-else-if="seleccionador=='SELECCIONADOR_CELULARES'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosEstufas(v-else-if="seleccionador=='SELECCIONADOR_ESTUFAS'" :city="city" :state="state" @onClickReturn="handleClickReturn")
        SeleccionadorProductosCocinas(v-else-if="seleccionador=='SELECCIONADOR_COCINAS'" :city="city" :state="state" @onClickReturn="handleClickReturn")

        template(v-else)
            v-row(class="mb-5")
                v-col(cols="12")
                    h1( class="text-center text-h6 blue-500--text") Seleccionadores de Productos
            
            v-row()
                v-col(cols="12" md="8" offset="2")
                    p(class="mb-0") Selecciona el tipo de artículo que deseas consultar: 
                v-col(cols="12" md="8" offset="2")
                    v-btn-toggle(v-model="seleccionador" tile color="primary" group class="d-flex flex-wrap")
                        v-btn(value="SELECCIONADOR_LLANTAS" small @click="()=> dataLayerClickBtnSeleccionador('Llantas', state, city)" ) Llantas
                        v-btn(value="SELECCIONADOR_BATERIAS" small @click="()=> dataLayerClickBtnSeleccionador('Baterías', state, city)" ) Baterías
                        v-btn(value="SELECCIONADOR_COMPUTO" small @click="()=> dataLayerClickBtnSeleccionador('Laptops', state, city)" ) Laptops
                        v-btn(value="SELECCIONADOR_CLIMA_VENTILACION" small @click="()=> dataLayerClickBtnSeleccionador('Clima y Ventilación', state, city)" ) Clima y Ventilación
                        v-btn(value="SELECCIONADOR_CALENTADORES" small @click="()=> dataLayerClickBtnSeleccionador('Calentadores / Boiler', state, city)" ) Calentadores / Boiler
                        v-btn(value="SELECCIONADOR_MOTOCICLETAS" small @click="()=> dataLayerClickBtnSeleccionador('Motocicletas', state, city)" ) Motocicletas
                        v-btn(value="SELECCIONADOR_CELULARES" small @click="()=> dataLayerClickBtnSeleccionador('Celulares', state, city)" ) Celulares
                        v-btn(value="SELECCIONADOR_LAVADORAS" small @click="()=> dataLayerClickBtnSeleccionador('Lavadoras', state, city)" ) Lavadoras
                        v-btn(value="SELECCIONADOR_COLCHONES" small @click="()=> dataLayerClickBtnSeleccionador('Colchones', state, city)" ) Colchones
                        v-btn(value="SELECCIONADOR_REFRIGERADORES" small @click="()=> dataLayerClickBtnSeleccionador('Refrigeradores', state, city)" ) Refrigeradores
                        v-btn(value="SELECCIONADOR_ESTUFAS" small @click="()=> dataLayerClickBtnSeleccionador('Estufas/Empotre', state, city)" ) Estufas/Empotre
                        v-btn(value="SELECCIONADOR_PANTALLAS" small @click="()=> dataLayerClickBtnSeleccionador('Pantallas', state, city)" ) Pantallas
                        v-btn(value="SELECCIONADOR_SALAS" small  @click="()=> dataLayerClickBtnSeleccionador('Salas', state, city)") Salas
                        v-btn(value="SELECCIONADOR_COCINAS" small @click="()=> dataLayerClickBtnSeleccionador('Cocinas', state, city)") Cocinas
 


</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import SeleccionadorProductosComputo from './SeleccionadorProductosComputo'
import SeleccionadorProductosLlantas from './SeleccionadorProductosLlantas'
import SeleccionadorProductosBaterias from './SeleccionadorProductosBaterias'
import SeleccionadorProductosClimaVentilacion from './SeleccionadorProductosClimaVentilacion'
import SeleccionadorProductosCalentadores from './SeleccionadorProductosCalentadores'
import SeleccionadorProductosMotocicletas from './SeleccionadorProductosMotocicletas'
import SeleccionadorProductosLavadoras from './SeleccionadorProductosLavadoras'
import SeleccionadorProductosRefrigeradores from './SeleccionadorProductosRefrigeradores'
import SeleccionadorProductosColchones from './SeleccionadorProductosColchones'
import SeleccionadorProductosPantallas from './SeleccionadorProductosPantallas'
import SeleccionadorProductosSalas from './SeleccionadorProductosSalas'
import SeleccionadorProductosCelulares from './SeleccionadorProductosCelulares'
import SeleccionadorProductosEstufas from './SeleccionadorProductosEstufas'
import SeleccionadorProductosCocinas from './SeleccionadorProductosCocinas'
import DataLayersSeleccionadoresMixin from '@/mixins/DataLayersSeleccionadoresMixin'

export default {
    name: 'SeleccionadorProductosTab',
    props: ['city', 'state'],
    mixins: [DataLayersSeleccionadoresMixin],
    components: {
        SeleccionadorProductosComputo,
        SeleccionadorProductosLlantas,
        SeleccionadorProductosBaterias,
        SeleccionadorProductosClimaVentilacion,
        SeleccionadorProductosCalentadores,
        SeleccionadorProductosMotocicletas,
        SeleccionadorProductosLavadoras,
        SeleccionadorProductosRefrigeradores,
        SeleccionadorProductosColchones,
        SeleccionadorProductosPantallas,
        SeleccionadorProductosSalas,
        SeleccionadorProductosCelulares,
        SeleccionadorProductosEstufas,
        SeleccionadorProductosCocinas
    },
    data() {
        return {
            seleccionador: '',
        }
    },
    computed: {

        ...mapGetters({
            email: 'email'
        }),
        canSeeSection() {
            return [
                'alberto.valencia@coppel.com',
                'rebeca.renedo@coppel.com',
                'marco.salgado@coppel.com',
                'luis.matus@coppel.com',
                'paola.leon@coppel.com',
                'antonio.alvarado@coppel.com'
            ].includes(this.email)
        }
    },
    methods: {
        handleClickReturn() {
            this.seleccionador = ''
        },
    },
    mounted() {
        this.dataLayerLoadPageSeleccionadores(this.state, this.city)
    }
}
</script>


<style scoped>
</style>